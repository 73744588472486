import { createTheme } from '@mui/material';

import { colors } from './colors';
import { STYLE_1, STYLE_2, typographyV2 } from './typography';

export const theme = createTheme({
	palette: {
		...colors,
	},

	typography: {
		fontFamily: typographyV2.fontFamily,
	},

	components: {
		MuiDialogActions: {
			styleOverrides: {
				root: `justify-content: center`,
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: `${STYLE_1};`,
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: `${STYLE_1};`,
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: `background-color: ${colors.common.white}; ${STYLE_1};`,
				notchedOutline: `border-color: ${colors.grey[300]}`,
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: `border-color: ${colors.border.main}`,
			},
		},
		MuiButton: {
			styleOverrides: {
				root: `
					${STYLE_2};
					text-transform: none;
				`,
				containedPrimary: `
					background-color: #9BA0B3;
					border-color: ${colors.border.main};
					color: ${colors.common.white};

					&:hover {
						border-color: ${colors.border.hover};
						background-color: ${colors.greyblue[125]};
						&::after {
							opacity: 1;
						}
					}
				`,
				outlinedPrimary: `
					background-color: ${colors.greyblue[225]};
					border-color: ${colors.border.main};
					color: ${colors.common.black};

					&::after {
						display: block;
						content: '';

						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;

						opacity: 0;
						background-color: ${colors.border.main};

						transition: opacity 250ms cubic-bezier(0.4,0,0.2,1) 0ms;
					}

					&:hover {
						border-color: ${colors.border.hover};
						background-color: ${colors.greyblue[225]};

						&::after {
							opacity: 1;
						}
					}
				`,
				outlinedSecondary: `
					background-color: transparent;
					border-color: ${colors.border.main};
					color: ${colors.common.black};
					font-weight: 400;

					&.Mui-disabled {
						border-color: ${colors.greyblue[245]};
					}

					&::after {
						display: block;
						content: '';

						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;

						opacity: 0;

						transition: opacity 250ms cubic-bezier(0.4,0,0.2,1) 0ms;
					}

					&:hover {
						border-color: ${colors.border.hover};
						background-color: transparent;

						&::after {
							opacity: 1;
						}
					}
				`,
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: `
					overflow-x: hidden;
					padding: 2rem 3rem;
				`,
			},
		},
		MuiAlert: {
			styleOverrides: {
				standardError: `
					.MuiAlert-icon {
						color: ${colors.error.main};
					}
				`,
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: `
				${STYLE_2}
				padding: 2rem 3rem 0 3rem;
				`,
			},
		},
		MuiCollapse: {
			styleOverrides: {
				root: `
					min-height: auto !important;
				`,
			},
		},
	},
});
