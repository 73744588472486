import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const OrderTaskTypeEnum = mkenum({
	LOADING: 'LOADING',
	UNLOADING: 'UNLOADING',
});
export type OrderTaskType = TypeFromPEnum<typeof OrderTaskTypeEnum>;

export const OrderTaskStatusEnum = mkenum({
	ARCHIVED: 'ARCHIVED',
	CANCELLED: 'CANCELLED',
	COMPLETED: 'COMPLETED',
	IN_PROGRESS: 'IN_PROGRESS',
	PENDING: 'PENDING',
	PROBLEM: 'PROBLEM',
});
export type OrderTaskStatus = TypeFromPEnum<typeof OrderTaskStatusEnum>;

export const ContactOrderTaskStatusEnum = mkenum({
	CANCELLED: 'CANCELLED',
	COMPLETED: 'COMPLETED',
	PROBLEM: 'PROBLEM',
	IN_PROGRESS: 'IN_PROGRESS',
	PENDING: 'PENDING',
});

export type ContactOrderTaskStatus = TypeFromPEnum<typeof ContactOrderTaskStatusEnum>;

// when switching to one of these statuses, stop sending all Task reminders for that task (including warnings)
export const TaskReminderStopStatuses = [
	OrderTaskStatusEnum.ARCHIVED,
	OrderTaskStatusEnum.COMPLETED,
];
