import { useEffect, useMemo, useState } from 'react';
import {
	generatePath,
	matchPath,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
	ErrorBoundary,
	ROUTES,
	TodoCheckbox,
	useContact,
	useContacts,
	useConversationsV2,
	useDebounce,
	usePlugins,
	Can,
	useOrderActions,
	TabNavigationToggleButton,
	colors,
} from '@heylog-app/frontend-lib/app';
import { Actions, OrderConnectionStatusEnum } from '@heylog-app/shared/types';

import { StyledToggleButtonGroup } from './contacts.styles';
import { useContactContext } from './contacts';

import type { FC, SyntheticEvent } from 'react';

export const ContactsDetailPage: FC = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const params = useParams();
	const { contactId, workspaceId = '', conversationId = ''} = params;
	const { FrontendV2Plugin } = usePlugins(workspaceId);
	const debouncedSearchTerm = useDebounce<string | undefined>('', 250);
	const { pathname } = location;

	const { contact } = useContact(contactId);

	const { conversations } = useConversationsV2();
	// Example of paths
	const paths = [
		ROUTES.CONTACTS.CHAT3,
		ROUTES.CONTACTS.INFORMATION,
		ROUTES.CONTACTS.MANAGEMENT,
		// Add other routes as needed
	];

	// Find the initial active tab based on the current pathname
	const initialActiveTab =
		paths.find((path) => matchPath(path, pathname)) || ROUTES.CONTACTS.INFORMATION;

	const [activeTab, setActiveTab] = useState(initialActiveTab);

	const currentAssignment = useMemo(
		() =>
			contact?.orderConnections?.find((connection) =>
				connection.status !== OrderConnectionStatusEnum.ARCHIVED ? true : false,
			),
		[contact],
	);

	const { order: currentOrder } = useOrderActions(
		currentAssignment?.orderId ? currentAssignment.orderId + '' : undefined,
	);

	const handleTabClick = (event: SyntheticEvent, path: string) => {
		setActiveTab(path);
	};

	const conversationV2 =
		FrontendV2Plugin &&
		conversations?.data?.find(
			(conversation) =>
				!!conversation.details &&
				typeof conversation.details === 'object' &&
				'conversationV1Id' in conversation.details &&
				(conversation.details as { conversationV1Id: unknown }).conversationV1Id ===
				parseInt(conversationId, 10),
		);

	const navigate = useNavigate();
	const { contacts } = useContacts({ q: debouncedSearchTerm });
	const hasContacts = contacts?.length && contacts?.length > 0 ? true : false;

	useEffect(() => {
		if (matchPath(ROUTES.CONTACTS.DETAIL1, pathname) && contact) {
			navigate(
				generatePath(ROUTES.CONTACTS.CHAT3, {
					...params,
					conversationId: contact?.conversations[0]?.id.toString() || '',
				}),
			);
		}
	}, [params, contact, navigate, pathname]);

	return (
		<>
			<StyledToggleButtonGroup
				color="primary"
				value={activeTab}
				exclusive
				onChange={handleTabClick}
				aria-label="Tab navigation"
			>
				<Stack
					sx={{
						fontWeight: 'bold',
						marginTop: '25px',
						marginLeft: '10px',
					}}
				>
					{contact && contact.firstName + ' ' + contact.lastName}
				</Stack>
				{location.pathname ===
					generatePath(ROUTES.CONTACTS.MANAGEMENT, {
						...params,
						conversationId: contact?.conversations[0]?.id.toString() || '',
					}) ? (
					<Stack
						sx={{
							marginTop: '20px',
						}}
						marginLeft="auto"
						flexDirection="row"
					>
						<Stack
							sx={{
								padding: '6px 8px',
							}}
						>
							{t('contacts.order')}:
						</Stack>
						<Stack
							sx={{
								padding: '6px 8px',
								border: `1px solid ${colors.grey[300]}`,
								borderRadius: '4px',
								bgcolor: colors.grey[300],
							}}
						>
							{
								currentOrder?.labels.find(
									(label) => label.type === 'ORDER_EXTERNAL_REFERENCE',
								)?.value
							}{' '}
							- {currentOrder?.customer}
						</Stack>
					</Stack>
				) : (
					''
				)}
				<Stack
					sx={{
						marginTop: '10px',
						flexDirection: 'row',
					}}
					marginLeft="auto"
				>
					<TabNavigationToggleButton
						dataTest="contact-information-menu"
						label="contacts.tabs.chat"
						url={generatePath(ROUTES.CONTACTS.CHAT3, {
							...params,
							conversationId: contact?.conversations[0]?.id.toString() || '',
						})}
					/>
					<TabNavigationToggleButton
						dataTest="contact-information-menu"
						label="contacts.tabs.information"
						url={generatePath(ROUTES.CONTACTS.INFORMATION, params)}
					/>
					{hasContacts && contactId && (
						<Can I={Actions.MANAGE} a="Workspace">
							<TabNavigationToggleButton
								dataTest="contact-details-menu"
								label="contacts.tabs.management"
								url={generatePath(ROUTES.CONTACTS.MANAGEMENT, {
									...params,
									conversationId: contact?.conversations[0]?.id.toString() || '',
								})}
							/>
						</Can>
					)}
					{FrontendV2Plugin && conversationV2 && (
						<Box marginLeft="auto">
							<TodoCheckbox
								conversation={conversationV2}
								workspaceId={parseInt(workspaceId, 10)}
							/>
						</Box>
					)}
				</Stack>
			</StyledToggleButtonGroup>

			<ErrorBoundary>
				<Outlet context={useContactContext()} />
			</ErrorBoundary>
		</>
	);
};
