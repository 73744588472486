import { Box, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import {
	ErrorMessage,
	ROUTES,
	useApiClientContext,
	useAuthContext,
	useErrorMessage,
	useLocalStorage,
} from '@heylog-app/frontend-lib/app';
import { SignupUserRoleEnum } from '@heylog-app/shared/types';

import { signupUserAPI } from '../../../util/signup';
import {
	SignupFormWrapper,
	StyledSubTitle,
	StyledTitle,
} from './personal-data-form.styles';

import type { SignupUserRoleType } from '@heylog-app/shared/types';
import type { FC } from 'react';

type FormData = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	repeatPassword: string;
};

export const PersonalDataForm: FC = () => {
	const { apiClient } = useApiClientContext();
	const { t } = useTranslation();
	const { token, setAppToken } = useAuthContext();
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isSubmitting },
	} = useForm<FormData>();

	const [conversationGuestInvite] = useLocalStorage('conversationGuestInvite', {
		workspaceId: '',
		conversationId: '',
		invitationId: '',
	});

	const [workspaceUserInvite] = useLocalStorage('workspaceUserInvite', {
		workspaceId: '',
		invitationId: '',
		role: null,
	});

	const { errorMessage, setError } = useErrorMessage();

	if (token) return <Navigate to={ROUTES.SUCCESSFUL_REGISTRATION} />;

	let role: SignupUserRoleType;

	if (conversationGuestInvite.conversationId && conversationGuestInvite.invitationId) {
		role = SignupUserRoleEnum.CONVERSATION_GUEST;
	} else if (
		workspaceUserInvite.workspaceId &&
		workspaceUserInvite.invitationId &&
		workspaceUserInvite.role
	) {
		role = workspaceUserInvite.role;
	}

	const onSubmit = async (data: FormData) => {
		try {
			const res = await signupUserAPI(apiClient, {
				data: {
					...data,
					email: data.email?.toLowerCase(),
					role,
				},
			});
			setAppToken(res.data);
		} catch (error) {
			setError(error as Error);
		}
	};

	//TODO: add field validation (password length etc)
	// https://app.asana.com/0/1202161641843055/1202558528615818/f
	return (
		<SignupFormWrapper>
			<form onSubmit={handleSubmit(onSubmit)}>
				<StyledTitle>{t('signup.personalData.title')}</StyledTitle>
				<StyledSubTitle>{t('signup.personalData.text')}</StyledSubTitle>

				<TextField
					label={t('signup.personalData.form.labels.firstName')}
					{...register('firstName', { required: true })}
					data-test="signup-firstName-input"
					fullWidth
					margin="dense"
					error={!!errors.firstName}
					helperText={errors.firstName && t('forms.errors.required')}
				/>
				<TextField
					label={t('signup.personalData.form.labels.lastName')}
					{...register('lastName', { required: true })}
					data-test="signup-lastName-input"
					fullWidth
					margin="dense"
					error={!!errors.lastName}
					helperText={errors.lastName && t('forms.errors.required')}
				/>
				<TextField
					type="email"
					label={t('signup.personalData.form.labels.email')}
					{...register('email', { required: true })}
					data-test="signup-email-input"
					fullWidth
					margin="dense"
					error={!!errors.email}
					helperText={errors.email && t('forms.errors.required')}
				/>
				<TextField
					type="password"
					label={t('signup.personalData.form.labels.password')}
					{...register('password', { required: true })}
					data-test="signup-password-input"
					fullWidth
					margin="dense"
					error={!!errors.password}
					helperText={errors.password && t('forms.errors.required')}
				/>
				<TextField
					type="password"
					label={t('signup.personalData.form.labels.repeatPassword')}
					{...register('repeatPassword', {
						required: true,
						validate: (value) =>
							value !== watch('password') ? 'signup.form.errors.passwordMatch' : true,
					})}
					data-test="signup-repeat-password-input"
					fullWidth
					margin="dense"
					error={!!errors.repeatPassword}
					helperText={
						errors.repeatPassword &&
						(errors.repeatPassword?.message
							? t(errors.repeatPassword.message)
							: t('forms.errors.required'))
					}
				/>

				{errorMessage && <ErrorMessage errorMessage={errorMessage} />}

				<Box mt={4}>
					<Button
						type="submit"
						variant="contained"
						fullWidth
						data-test="signup-first-page-submit-button"
					>
						{isSubmitting
							? t('actionLabels.loading')
							: t('signup.personalData.form.labels.nextStep')}
					</Button>
				</Box>
			</form>
		</SignupFormWrapper>
	);
};
