import { useTranslation } from 'react-i18next';

import { StyledTextarea } from './message-input.styles';
import { TemplateInput } from '../template-input';

import type {
	WATplParamTextInterface,
	WhatsAppMessageTemplateResInterface,
} from '@heylog-app/shared/types';
import type { FC, KeyboardEventHandler, SyntheticEvent } from 'react';

type MessageTextareaType = {
	selectedTemplate?: WhatsAppMessageTemplateResInterface;
	templateParameters?: WATplParamTextInterface[];
	setTemplateParameters?: React.Dispatch<
		React.SetStateAction<WATplParamTextInterface[] | undefined>
	>;
	message: string;
	onChange: (event: SyntheticEvent<HTMLTextAreaElement>) => void;
	onPressEnter: KeyboardEventHandler<Element>;
	isSending: boolean;
	setEmojiPickerField: (
		target: EventTarget & (HTMLTextAreaElement | HTMLInputElement),
	) => void;
	focusedFieldIndex: number | null;
	setFocusedFieldIndex: (num: number) => void;
	templateCursorPosition: number | undefined;
	shouldFocusOnRender?: boolean;
};

export const MessageTextarea: FC<MessageTextareaType> = ({
	selectedTemplate,
	templateParameters,
	setTemplateParameters,
	message,
	onChange,
	onPressEnter,
	isSending,
	setEmojiPickerField,
	focusedFieldIndex,
	setFocusedFieldIndex,
	templateCursorPosition,
	shouldFocusOnRender = true,
}) => {
	const { t } = useTranslation();

	return selectedTemplate ? (
		<TemplateInput
			dataTest="template-input"
			template={selectedTemplate}
			templateParameters={templateParameters}
			setTemplateParameters={setTemplateParameters}
			onPressEnter={onPressEnter}
			setEmojiPickerField={setEmojiPickerField}
			focusedFieldIndex={focusedFieldIndex}
			setFocusedFieldIndex={setFocusedFieldIndex}
			templateCursorPosition={templateCursorPosition}
		/>
	) : (
		<StyledTextarea
			value={message}
			placeholder={t('chats.messagePlaceholder')}
			onFocus={(e) => setEmojiPickerField(e.target)}
			onChange={onChange}
			onKeyPress={onPressEnter}
			disabled={isSending}
			data-test="message-input-field"
			ref={(textArea: HTMLTextAreaElement) => {
				if (textArea) {
					shouldFocusOnRender && textArea.focus();
				}
			}}
		/>
	);
};
