import { useAbility } from '@casl/react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import {useCallback, useState } from 'react';

import { Actions, type FleetCheckResInterface } from '@heylog-app/shared/types';

import { useApiClientContext } from './use-api-client-context.hook';
import { AbilityContext } from '../providers';
import { getFetcher } from '../util';
import { useContacts } from './use-contacts.hooks';

import type { GridRowsProp } from '@mui/x-data-grid-pro';
import type {
	FullContactResInterface,
	FleetCheckItemResInterface,
	FleetCheckRequestResInterface,
} from '@heylog-app/shared/types';

export const useFleetChecks = (organizationId: number, workspaceId: string) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const { data, error, isLoading } = useSWR<Array<FleetCheckResInterface>>(
		ability.can(Actions.MANAGE, 'Workspace') && organizationId > 0
			? `/organizations/${organizationId}/workspaces/${workspaceId}/fleet-checks`
			: null,
		getFetcher(apiClient),
	);

	return {
		fleetChecks: data,
		isLoading,
		isError: error,
	};
};

export const useFleetCheckRequests = (organizationId: number, workspaceId: string) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const { data, error, isLoading, mutate } = useSWR<Array<FleetCheckRequestResInterface>>(
		ability.can(Actions.MANAGE, 'Workspace') && organizationId > 0
			? `/organizations/${organizationId}/workspaces/${workspaceId}/fleet-check-requests`
			: null,
		getFetcher(apiClient),
	);

	// filter out the checks that are already completed
	const filteredData = data ? data.filter((check) => check.status !== 'COMPLETED') : [];

	return {
		fleetCheckRequests: filteredData,
		isLoading,
		isError: error,
		mutateFleetCechkRequests: mutate,
	};
};

export const useRequestsWithFleetChecks = (
	organizationId: number,
	workspaceId: string,
) => {
	const { t } = useTranslation();

	const { fleetCheckRequests, mutateFleetCechkRequests } = useFleetCheckRequests(
		organizationId,
		workspaceId,
	);
	const { fleetChecks } = useFleetChecks(organizationId, workspaceId);
	const { contacts } = useContacts({});
	const fleetChecksMap = [...(fleetChecks || []), ...(fleetCheckRequests || [])];

	const rowsToShow: GridRowsProp = fleetChecksMap.map((fleetCheckItem, index) => {
		// casting the types here because we are working with an array that contains 2 different types
		const contactId =
			(fleetCheckItem as { contactId?: number }).contactId ||
			(fleetCheckItem as { contact?: FullContactResInterface }).contact?.id;

		const licensePlate = (fleetCheckItem as { licensePlate?: string }).licensePlate;

		const id =
			(fleetCheckItem as { id?: number }).id || (fleetCheckItem as { id?: number }).id;

		const contact = (contacts || []).find((contact) => contact.id === contactId);

		const status = (fleetCheckItem as { status?: string }).status || 'COMPLETED';
		const surveyItems =
			(fleetCheckItem as { items?: FleetCheckItemResInterface[] }).items || [];

		return {
			id: `${fleetCheckItem.type}-${id}-${index}`,
			form: t(`vchk.${fleetCheckItem.type}`),
			contactId: contactId || 0,
			conversationId: contact?.conversations?.[0]?.id || 0,
			driver: `${contact?.firstName} ${contact?.lastName}`,
			licensePlate: licensePlate,
			statusEnum: status,
			status: t(`vchk.${status}`),
			surveyItems: surveyItems,
			createdAt: fleetCheckItem.createdAt,
		};
	});

	return {
		fleetCheckRequests,
		fleetChecks,
		mutateFleetCechkRequests,
		rowsToShow,
	};
};

export const useFleetCheckDetails = (
	checkId: string,
	organizationId: number,
	workspaceId: string,
) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const cleanId = checkId.split('-')[1];

	const { data, isLoading } = useSWR<FleetCheckResInterface>(
		ability.can(Actions.MANAGE, 'Workspace') &&
			organizationId > 0 &&
			cleanId !== 'undefined'
			? `/organizations/${organizationId}/workspaces/${workspaceId}/fleet-checks/${cleanId}`
			: null,
		getFetcher(apiClient),
	);

	return {
		data,
		isLoading,
	};
};

export const useFleetCheckResultDownload = (
	checkId: string,
	organizationId: number,
	workspaceId: string,
) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const cleanId = checkId.split('-')[1];
	const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const fetchReportData = useCallback(() => {
		setIsLoading(true);
		if (
			ability.can(Actions.MANAGE, 'Workspace') &&
			organizationId > 0 &&
			cleanId !== 'undefined' &&
			cleanId
		) {
			apiClient
			.get(
				`/organizations/${organizationId}/workspaces/${workspaceId}/fleet-checks/${cleanId}/report`,
			)
			.then((response: any) => {
				const dat = response.data
				const a = document.createElement('a');
				a.href = dat;
				a.download = `report-${checkId}.pdf`;
				a.style.display = 'none';
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			})
			.catch((error) => {
				console.error('Error downloading report:', error);
			})
			.finally(() => {
				setIsLoading(false);
			});
		}
	}, [ability, apiClient, cleanId, organizationId, workspaceId]);

	const handleDownloadClick = () => {
		fetchReportData();
	};
	return {
		handleDownloadClick,
		isLoading,
	};
};
