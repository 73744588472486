import { Box, Dialog, DialogTitle, colors, Grid, MenuItem, Stack } from '@mui/material';
import Icon from '@mdi/react';
import React, { type FC, useEffect, useContext, useCallback, useState } from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	mdiAccountPlusOutline,
	mdiEmailOutline,
	mdiMenuDown,
	mdiPlus,
	mdiMessageTextOutline,
	mdiPlusCircleOutline,
	mdiTruckDeliveryOutline,
} from '@mdi/js';
import { formatISO } from 'date-fns';

import {
	AddContactForm,
	AddTaskDialog,
	ButtonV2,
	createOrderTask,
	deleteOrderTask,
	EmailDialogActionEnum,
	FlashSnackbar,
	IconHeylog,
	IconMessage,
	IconSMS2,
	IconWhatsApp2,
	MessageContext,
	MessageDialogTypesEnum,
	ModalClose,
	ROUTES,
	useApiClientContext,
	useDialog,
	useEnvContext,
	useMenu,
	useMessagesData,
	useOrderActions,
	useSnackbar,
	useTableColumnsSettings,
	VerticalStepper,
} from '@heylog-app/frontend-lib/app';
import { MessagesDataGrid, MessagesDataGrid2 } from '@heylog-app/frontend-lib/modules/messages';
import { Comments } from '@heylog-app/frontend-lib/modules/user-to-user';
import { ConversationTypeEnum, OrderTaskStatusEnum } from '@heylog-app/shared/types';

import { StackLabelTypo, StyledHeaderLabelTitle, StyledHeaderTruckLabelTitle, StyledHeaderTruckLabelValue, StyledListItemText, StyledMenuButton, StyledSpan, StyledTruckDiv, StyledTruckDivParent, TypoConversationDiv } from '../shipment-details.styles';
import { ShipmentMap } from './shipment-map';

import type {
	OrderTaskResInterface,
	CreateOrderTaskReqInterface,
} from '@heylog-app/shared/types';
import type { OrderProps } from '../interfaces/orderPropsInterface';

export const ShipmentInfo: FC<OrderProps> = ({ order, liveLocationData }) => {
	const env = useEnvContext();
	const messagesApiRef = useGridApiRef();
	const { onOpenMessageDialog } = useContext(MessageContext);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const menuControl = useMenu();
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const {
		showDialog: showNewContactDialog,
		openDialog: openNewContactDialog,
		closeDialog: closeNewContactDialog,
	} = useDialog();

	const {
		showDialog: showNewTaskDialog,
		openDialog: openNewTaskDialog,
		closeDialog: closeNewTaskDialog,
	} = useDialog();

	const { columns, rows } = useMessagesData([
		ConversationTypeEnum.EMAIL,
		ConversationTypeEnum.VIBER,
		ConversationTypeEnum.WHATSAPP,
	]);

	const {
		adjustedColumns,
		handleColumnResize,
		handleColumnOrderChange,
		handleColumnVisibilityModelChange,
	} = useTableColumnsSettings({
		columns,
		apiRef: messagesApiRef,
		localStorageName: 'shipment-info-messages-data-table',
	});

	const [tasks, settasks] = useState<OrderTaskResInterface[]>([]);
	const [currentTasks, setCurrentTasks] = useState<number[]>([]);
	const [completedTasks, setCompletedtasks] = useState<number[]>([]);
	const [center, setCenter] = useState<{ lng: number; lat: number }>({
		lat: 48.193221428615416,
		lng: 16.408809023744837,
	});
	const [warningTasks, setWarningTasks] = useState<number[]>([]);
	const [problemTasks, setProblemTasks] = useState<number[]>([]);
	const [statusChanged, setStatusChanged] = useState<{ status: string; taskId: string }>({
		status: '',
		taskId: '',
	});

	const handleNewContactSuccess = useCallback(() => {
		openSnackbar('success', t('contacts.form.smsSent'));
		closeNewContactDialog();
	}, [closeNewContactDialog, t, openSnackbar]);

	const { apiClient } = useApiClientContext();
	const { workspaceId = '', shipmentId = '' } = useParams();

	const { mutateOrder } = useOrderActions(shipmentId);

	const submitCreateTaskForm = (formData: CreateOrderTaskReqInterface) => {
		const { etaFrom, etaTo, ...data } = formData;
		createOrderTask(apiClient, {
			data: {
				...data,
				...(etaFrom && { etaFrom: etaFrom && formatISO(new Date(etaFrom)) }),
				...(etaTo && { etaTo: etaTo && formatISO(new Date(etaTo)) }),
			},
			workspaceId,
			orderId: shipmentId,
		})
			.then(() => {
				mutateOrder();
			})
			.catch((e) => {
				console.error(e);
			});
	};

	useEffect(() => {
		let orderTasks: OrderTaskResInterface[] = [];
		if (order?.tasks && order?.tasksSequence) {
			if (statusChanged) {
				orderTasks = order.tasks.map((task) =>
					task.id.toString() === statusChanged.taskId
						? { ...task, status: statusChanged.status }
						: task,
				) as OrderTaskResInterface[];
				order.tasks = orderTasks;
			}

			const sequenceMap = order.tasksSequence.reduce(
				(acc: { [key: number]: number }, id: number, index: number) => {
					acc[id] = index;
					return acc;
				},
				{},
			);

			const sortedArray = orderTasks.sort((a, b) => {
				return (
					(sequenceMap[a.id] ?? Number.MAX_SAFE_INTEGER) -
					(sequenceMap[b.id] ?? Number.MAX_SAFE_INTEGER)
				);
			});

			const inProgress = sortedArray.find(
				(task) =>
					task.status === OrderTaskStatusEnum.IN_PROGRESS &&
					task.latitude !== null &&
					task.longitude !== null,
			);

			const pending = sortedArray.find(
				(task) =>
					task.status === OrderTaskStatusEnum.PENDING &&
					task.latitude !== null &&
					task.longitude !== null,
			);

			const completedTasks = sortedArray.filter(
				(task) =>
					task.status === OrderTaskStatusEnum.COMPLETED &&
					task.latitude !== null &&
					task.longitude !== null,
			);

			const completed = completedTasks[completedTasks.length - 1];

			if (inProgress) {
				setCenter({
					lat: inProgress.latitude as number,
					lng: inProgress.longitude as number,
				});
			} else if (pending) {
				setCenter({
					lat: pending.latitude as number,
					lng: pending.longitude as number,
				});
			} else if (completed) {
				setCenter({
					lat: completed.latitude as number,
					lng: completed.longitude as number,
				});
			}

			settasks(sortedArray);
		}

		// if (!marker) {
		// 	return;
		// }

		// do something with marker instance here
	}, [/*marker,*/ order, setCenter, statusChanged]);

	useEffect(() => {
		if (tasks.length > 0) {
			setCurrentTasks(
				tasks
					.map((task, index) =>
						task.status === OrderTaskStatusEnum.IN_PROGRESS ? index : -1,
					)
					.filter((index) => index !== -1),
			);
			setCompletedtasks(
				tasks
					.map((task, index) =>
						task.status === OrderTaskStatusEnum.COMPLETED ? index : -1,
					)
					.filter((index) => index !== -1),
			);
			setWarningTasks(
				tasks
					.map((task, index) =>
						task.status === OrderTaskStatusEnum.CANCELLED ? index : -1,
					)
					.filter((index) => index !== -1),
			);
			setProblemTasks(
				tasks
					.map((task, index) =>
						task.status === OrderTaskStatusEnum.PROBLEM ? index : -1,
					)
					.filter((index) => index !== -1),
			);
		}
	}, [tasks]);

	const user = {
		role: 'internal',
	};

	const handleNewMessageClick = (cb: () => void) => () => {
		navigate(
			generatePath(ROUTES.ORDERS.CHATS, {
				workspaceId: order?.workspaceId?.toString(),
				orderId: order?.id.toString(),
			}),
		);
		cb();
	};

	const handleNewEmailClick = (cb: () => void) => () => {
		if (onOpenMessageDialog)
			onOpenMessageDialog({
				type: MessageDialogTypesEnum.EMAIL,
				action: EmailDialogActionEnum.NEW,
				payload: {
					orderId: order?.id,
				},
			});
		cb();
	};

	const deleteTask = (taskId?: number) => {
		deleteOrderTask(apiClient, {
			workspaceId,
			orderId: shipmentId,
			taskId: taskId ? taskId.toString() : '',
		})
			.then(() => {
				mutateOrder();
			})
			.catch((e) => {
				console.error(e);
			});
	};

	return (
		<APIProvider apiKey={env.GPLACES_KEY}>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
			<Stack height={"100%"} direction={'row'} gap={2}>

				<Grid item xs={8} sx={{ height: '100%', flex: 1, overflow: 'hidden' }}>

					<Box gap={1} sx={{ width: '100%', marginBottom: '10px' }}>
						<Grid
							sx={{ height: '100%' }}
							container
							gap={4}
							wrap="nowrap"
							columns={{ xs: 12 }}
						>
							<Grid item xs={8} sx={{ height: '100%', flex: 1, overflowX: 'hidden' }}>
								<StyledTruckDivParent>
									<StyledTruckDiv>
										<StyledHeaderTruckLabelTitle>
											{`${t('shipment.licensePlateTruck')}`}
										</StyledHeaderTruckLabelTitle>
										<StyledHeaderTruckLabelValue>
											{`${order?.licensePlateTruck}`}
										</StyledHeaderTruckLabelValue>
									</StyledTruckDiv>
									<StyledTruckDiv>
										<StyledHeaderTruckLabelTitle>
											{`${t('shipment.licensePlateTrailer')}`}
										</StyledHeaderTruckLabelTitle>
										<StyledHeaderTruckLabelValue>
											{`${order?.licensePlateTrailer}`}
										</StyledHeaderTruckLabelValue>
									</StyledTruckDiv>
								</StyledTruckDivParent>

							</Grid>

							<Grid item xs={4} sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: 'flex-end' }}>
								{order?.labels.length ? (
									<>
										<Stack
											direction="row"
											flexWrap="wrap"
											gap={2}
											alignItems={"center"}
										>
											<StyledHeaderLabelTitle>
												{t('shipment.labels') + ': '}
											</StyledHeaderLabelTitle>
											<StyledSpan>
												<Stack
													gap={1}
													direction="row"
													flexWrap="wrap"
													alignItems="center"
													justifyContent="space-between"
													sx={{
														maxWidth: '100%',
													}}
												>
													<>
														{order?.labels.map((label) => (
															<>
																<Stack
																	key={label.id}
																	sx={{
																		backgroundColor: colors.grey[400],
																		borderRadius: "12px",
																		display: "flex",
																		height: '24px',
																		padding: "4px 5px",
																		alignItems: "center",
																		gap: "10px"
																	}}
																>
																	<StackLabelTypo>
																		{label.value}
																	</StackLabelTypo>
																</Stack>

															</>
														))}
													</>

												</Stack>
											</StyledSpan>
										</Stack>
									</>
								) : <></>}
							</Grid>
						</Grid>
					</Box>
					<Box gap={1} sx={{ height: '100%', width: '100%', overflow: "hidden" }}>
						<Grid
							sx={{ height: '100%' }}
							container
							gap={4}
							wrap="nowrap"
							columns={{ xs: 12 }}
						>
							<Grid item xs={12} sx={{ height: '100%', flex: 1, overflowX: 'hidden' }}>
								<Stack direction="row" gap={2} sx={{ height: '100%' }}>
									{/*<OrderDetailsDataTable order={order} />*/}
									<Stack sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
										<ButtonV2
											onClick={openNewTaskDialog}
											$variant="dark"
											startIcon={<Icon path={mdiTruckDeliveryOutline} size={1} />}
										>
											{t('orders.tasks.create')}
										</ButtonV2>
										<VerticalStepper
											completedSteps={completedTasks}
											contacts={order && order.contacts}
											currentSteps={currentTasks}
											customer={order?.customer ? order.customer : ''}
											deleteTask={deleteTask}
											haveSwitcher={false}
											isLinear={true}
											setStatusChanged={setStatusChanged}
											steps={tasks}
											shipmentId={shipmentId}
											workspaceId={workspaceId}
											warningSteps={warningTasks}
											problemSteps={problemTasks}
										/>
									</Stack>
									<Stack
										gap={4}
										direction="column"
										sx={{ width: '100%', minHeight: '200px' }}
									>
										<ShipmentMap
											center={center}
											completedTasks={completedTasks}
											currentTasks={currentTasks}
											deleteTask={deleteTask}
											liveLocationData={liveLocationData}
											order={order}
											setStatusChanged={setStatusChanged}
											tasks={tasks}
											warningTasks={warningTasks}
											problemTasks={problemTasks}

										/>
									</Stack>
								</Stack>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={4} sx={{ flex: 1 }}>
					<Stack
						gap={2}
						direction="row"
						flexWrap="wrap"
						alignItems="center"
						justifyContent="space-between"
						sx={{
							borderBottom: '0px',
							borderRadius: '4px',
							padding: '8px 16px',
						}}
					>
						<TypoConversationDiv>

							{t('shipment.converstation')}
						</TypoConversationDiv>

						<StyledMenuButton
							renderChildren={(onClose) => (
								<div >
									<StyledListItemText>
										<MenuItem onClick={() => { }}>
											<Stack direction="row" alignItems="center" gap={1}>
												<IconHeylog />
												{t('shipment.heylog')}
											</Stack>
										</MenuItem>
									</StyledListItemText>
									<StyledListItemText>
										<MenuItem onClick={openNewContactDialog}>
											<Stack direction="row" alignItems="center" gap={1}>

												<IconWhatsApp2 />
												{t('shipment.whatsapp')}
											</Stack>
										</MenuItem>
									</StyledListItemText>
									<StyledListItemText>
										<MenuItem onClick={handleNewMessageClick(onClose)}>
											<Stack direction="row" alignItems="center" gap={1}>
												<IconMessage />
												{t('shipment.sms')}
											</Stack>
										</MenuItem>
									</StyledListItemText>
									<StyledListItemText>
										<MenuItem onClick={handleNewEmailClick(onClose)}>
											<Stack direction="row" alignItems="center" gap={1}>
												<IconSMS2 />
												{t('shipment.email')}
											</Stack>
										</MenuItem>
									</StyledListItemText>
								</div>
							)}
							id="message-menu"
							control={menuControl}
							slotProps={{
								paper: {
									style: {
										transform: 'translateX(-24%) translateY(0%)',
										boxShadow: "0px 0px 2px 0px rgba(34, 53, 72, 0.20),  0px 2px 4px 0px rgba(34, 53, 72, 0.20)",
										border: "1px solid #122CC5",
										borderRadius: "4px"
									},
								},
							}}
							button={
								<div>
									<ButtonV2
										$variant="light2"
										startIcon={<Icon path={mdiPlus} size={1} />}
										sx={{
											border: menuControl.isMenuOpen ? '1px solid #122CC5' : '1px solid transparent',
											transition: 'border-color 0.2s ease-in-out',
										  }}
										endIcon={
											<Box
												sx={{
													borderLeft: `1px solid rgba(255, 255, 255, 0.2)`,
													marginLeft: '12px',
													paddingLeft: '8px',
													marginRight: '-12px',
													display: 'none',
												}}
											>
												<Icon path={mdiMenuDown} size={1} />
											</Box>
										}
									>
										{t('shipment.plus')}
									</ButtonV2>
								</div>
							}
						/>
					</Stack>
					<Stack
						sx={{
							height: '45%',
							width: "100%"
						}}
					>
						<MessagesDataGrid2
							apiRef={messagesApiRef}
							rows={rows}
							hideFooter
							columnVisibilityModel={{
								priority: false,
								subject: false,
								internalReference: false,
								labels: false,
								orderDateFromTo: false,
							}}
							disableColumnMenu
							columns={adjustedColumns}
							handleColumnOrderChange={handleColumnOrderChange}
							handleColumnResize={handleColumnResize}
							handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
						/>
					</Stack>
					{user.role !== 'customer' && (
						<Stack sx={{ width: '100%', marginTop: '5%' }}>
							<Comments />
						</Stack>
					)}
				</Grid>
			</Stack>

			<Dialog
				open={showNewContactDialog}
				onClose={closeNewContactDialog}
				fullWidth
				maxWidth="md"
			>
				<ModalClose closeModalFn={closeNewContactDialog} />
				<DialogTitle>{t('contacts.form.createContactTitle')}</DialogTitle>
				<AddContactForm
					onSuccess={handleNewContactSuccess}
					onCancel={closeNewContactDialog}
				/>
			</Dialog>
			<AddTaskDialog
				dataTest="task-details-dialog"
				isOpen={showNewTaskDialog}
				onClose={closeNewTaskDialog}
				setStatusChanged={setStatusChanged}
				onSubmit={submitCreateTaskForm}
			/>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</APIProvider>
	);
};
