import { mutate } from 'swr';

import { MessageTypeEnum } from '@heylog-app/shared/types';

import { mutateHeylogMessagesKey, mutateMediaMessagesKey, mutateMessagesKey } from '../util';
import { useApiClientContext } from './use-api-client-context.hook';

import type { MessageReqInterface, MessageResInterface } from '@heylog-app/shared/types';

export const useMutateMessages = (workspaceId: string, conversationId: string) => {
	const { apiClient } = useApiClientContext();

	const messagesKey = mutateMessagesKey(workspaceId, conversationId);
	const mediaMessagesKey = mutateMediaMessagesKey(workspaceId, conversationId);

	const sendMessage = async (data: MessageReqInterface) => {
		let res: { data: MessageResInterface };

		if (
			data.messagePayload.messageType !== MessageTypeEnum.TEXT &&
			data.messagePayload.messageType !== MessageTypeEnum.WHATSAPP_TEMPLATE
		) {
			const formData = new FormData();
			formData.append('file', data.messagePayload.file);

			// Do not use with audio, document, or sticker media.
			// https://developers.facebook.com/docs/whatsapp/cloud-api/reference/messages#media-object
			if (
				data.messagePayload.messageType !== MessageTypeEnum.AUDIO &&
				data.messagePayload.messageType !== MessageTypeEnum.DOCUMENT
			) {
				formData.append('caption', data.messagePayload.caption || '');
			}

			try {
				res = await apiClient.post(mediaMessagesKey, formData);
			} catch (error) {
				console.error('Failed to send media message:', error);
				throw error;
			}
		} else {
			try {
				res = await apiClient.post(messagesKey, data);
			} catch (error) {
				console.error('Failed to send message:', error);
				throw error;
			}
		}

		if (!res.data.id) {
			console.warn('No id in response data:', res.data);
			throw new Error('No id in response data');
		}

		// at the end return res.data and existing messages from cache
		await mutate(messagesKey, (existingMessages: MessageResInterface[] = []) => {
			return [...existingMessages, res.data];
		});
	};

	return {
		sendMessage,
	};
};

export const useMutateHeylogMessages = (workspaceId: string, conversationId: string) => {
	const { apiClient } = useApiClientContext();

	const messagesKey = mutateHeylogMessagesKey(workspaceId, conversationId);

	const sendMessage = async (data: any) => {
		let res: { data: any };


		try {
			res = await apiClient.post(messagesKey, data);
		} catch (error) {
			console.error('Failed to send message:', error);
			throw error;
		}

		if (!res.data.id) {
			console.warn('No id in response data:', res.data);
			throw new Error('No id in response data');
		}

		// at the end return res.data and existing messages from cache
		await mutate(messagesKey, (existingMessages: MessageResInterface[] = []) => {
			return [...existingMessages, res.data];
		});
	};

	return {
		sendMessage,
	};
};
