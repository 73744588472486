export { IconWhatsApp2 } from './icon-whatsapp2';
export { IconSMS2 } from './icon-sms2';
export { IconHeylog } from './icon-heylog';
export { IconMessage } from './icon-message';
export { IconNoConver } from "./icon-no-conversation";
export { IconSend } from "./icon-send";
export { SaveIcon } from "./save"
export { IconSendFile } from './icon-add-file'
export { IconChevron } from './icon-chevron';
export { IconDots } from './icon-dots';
export { HeylogLogoBlue } from './heylog-logo-blue';
export { IconAccountClock } from './icon-account-clock';
export { IconWhatsApp } from './icon-whatsapp';
export { IconFacebookMessenger } from './icon-messenger';
export { IconViber } from './icon-viber';
export { IconSMS } from './icon-sms';
export { HeylogIconBlue } from './heylog-icon-blue';
export { IconTelegram } from './icon-telegram';
export { HeylogLogoBlack } from './heylog-logo-black';
export { FormSvg } from './form';
export { SearchSvg } from './search';
export { CodingSvg } from './coding';
export { ExpiredSvg } from './expired';
export { NoWorkspaceSvg } from './no-workspace';
export { RegistrationComplete } from './registration-complete';
export { InviteNewContactsSvg } from './invite-new-contacts';
export { NoConversationSvg } from './no-conversation';
export { CreateNewOrderSvg } from './create-new-order';
export { IconSelectAContact } from './icon-select-a-contact';
export { IconFire } from './icon-fire';
export { IconAttention } from './icon-attention';
export { IconUntrack } from './icon-untrack';