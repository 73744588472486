import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import { Actions } from '@heylog-app/shared/types';

import { Can } from '../can';
import { SidebarContent, SidebarList } from '../sidebar';
import { OrderSelect } from '../order';
import { GuestUser } from '../ui/guest-user/guest-user';
import { GuestInvitation } from '../guest-invintation/guest-invitation';
import {
	StyledGuestList,
	StyledSidebarButton,
	StyledSidebarDescription,
} from './conversation-management-tab.styles';
import {
	ROUTES,
	archiveGuestAPI,
	archiveGuestInvitationAPI,
	createOrderConnectionApi,
	createOrderDisconnectionApi,
} from '../../util';
import {
	useApiClientContext,
	useContacts,
	useDialog,
	useGuestInvitations,
	useGuests,
	useOrderActions,
	useSnackbar,
} from '../../hooks';
import { InviteGuestUserDialog } from '../invite-guest-user-dialog';
import { ConfirmArchiveDialog } from '../confirm-archive-dialog';
import { FlashSnackbar } from '../snackbars';

import type { FC } from 'react';
import type {
	ConversationInvitationResInterface,
	OrderConnectionResInterface,
	OrderResInterface,
} from '@heylog-app/shared/types';
import { Label } from '@mui/icons-material';

interface ConversationManagementTabPropsInterface {
	orderForConversation: OrderResInterface | undefined;
	currentAssignment: OrderConnectionResInterface | undefined;
	workspaceId: number;
	contactId: number;
	conversationId: number;
}

export const ConversationManagementTab: FC<ConversationManagementTabPropsInterface> = ({
	orderForConversation,
	currentAssignment,
	workspaceId,
	contactId,
	conversationId,
}) => {
	const [guestUserToDelete, setGuestUserToDelete] = useState('');
	const [pendingInvitationsToDelete, setpendingInvitationsToDelete] = useState('');
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { updateContacts } = useContacts({});
	const { apiClient } = useApiClientContext();
	const { guestInvitations, fetchGuestInvitations } = useGuestInvitations(
		workspaceId ? workspaceId.toString() : '-1',
		conversationId ? conversationId.toString() : '-1',
	);
	const { guests, fetchGuests } = useGuests(
		workspaceId ? workspaceId.toString() : '-1',
		conversationId ? conversationId.toString() : '-1',
	);

	const {
		showDialog: showConfirmArchiveGuestUserDialog,
		openDialog: openConfirmArchiveGuestUserDialog,
		closeDialog: closeConfirmArchiveGuestUserDialog,
	} = useDialog();

	const {
		showDialog: showInviteDialog,
		openDialog: openInviteDialog,
		closeDialog: closeInviteDialog,
	} = useDialog();

	const {
		showDialog: showConfirmArchivePendingInvitationDialog,
		openDialog: openConfirmArchivePendingInvitationDialog,
		closeDialog: closeConfirmArchivePendingInvitationDialog,
	} = useDialog();

	const pendingUserInvitations = guestInvitations?.filter(
		(guestInvitation) => guestInvitation.status === 'PENDING',
	);

	const { mutateOrder } = useOrderActions(orderForConversation?.id?.toString());

	// Deduplicate pending user invitations and remove the ones that are already workspace users
	let uniquePendingGuestInvitations: ConversationInvitationResInterface[];
	if (pendingUserInvitations?.length && guests) {
		const acceptedGuestEmailAddresses = guests?.map((guest) => guest.email);

		uniquePendingGuestInvitations = pendingUserInvitations
			.filter((user, index) => {
				const userSameEmail = pendingUserInvitations.find(
					(invitation) =>
						invitation.email === user.email && invitation.createdAt > user.createdAt,
				);

				const userSameEmailIndex = pendingUserInvitations.findIndex(
					(invitation) => invitation === userSameEmail,
				);

				return !userSameEmail || userSameEmailIndex === index;
			})
			.filter(
				(guestInvitation) => !acceptedGuestEmailAddresses.includes(guestInvitation.email),
			);
	}

	const onSelectOrder = useCallback(
		async (order: OrderResInterface) => {
			if (workspaceId && contactId) {
				await createOrderConnectionApi(apiClient, {
					workspaceId: workspaceId.toString(),
					data: { orderId: order.id, contactId },
				});
				updateContacts();
			}
		},
		[workspaceId, contactId, apiClient, updateContacts],
	);

	const handleCloseOrder = async () => {
		if (workspaceId && contactId && orderForConversation) {
			await createOrderDisconnectionApi(apiClient, {
				workspaceId: workspaceId.toString(),
				data: { orderId: orderForConversation.id, contactId },
			});
			updateContacts();
			mutateOrder();
		}
	};

	const handleRemovePendingInviteClick = (email: string) => {
		setpendingInvitationsToDelete(email);
		openConfirmArchivePendingInvitationDialog();
	};

	const handleArchiveGuestUser = async () => {
		if (!workspaceId || !conversationId || !guestUserToDelete) return;

		await archiveGuestAPI(apiClient, {
			workspaceId: workspaceId.toString(),
			conversationId: conversationId.toString(),
			guestToRemoveId: guestUserToDelete,
		})
			.then(() => {
				openSnackbar('success', t('settings.archiveGuestUserDialog.success'));
				fetchGuests();
				closeConfirmArchiveGuestUserDialog();
			})
			.catch((e) => console.log(e))
			.finally(() => setGuestUserToDelete(''));
	};

	const handleInviteSuccess = () => {
		fetchGuestInvitations();
		fetchGuests();
	};

	const handleArchivePendingInvitations = async () => {
		if (!workspaceId || !conversationId || !pendingInvitationsToDelete) return;

		await archiveGuestInvitationAPI(apiClient, {
			data: {
				email: pendingInvitationsToDelete,
			},
			workspaceId: workspaceId.toString(),
			conversationId: conversationId.toString(),
		})
			.then(() => {
				openSnackbar('success', t('settings.archiveInvitationDialog.success'));
				fetchGuestInvitations();
				closeConfirmArchivePendingInvitationDialog();
			})
			.catch((e) => console.log(e))
			.finally(() => setpendingInvitationsToDelete(''));
	};

	const handleRemoveGuest = (guestId: number) => {
		setGuestUserToDelete(guestId.toString());
		openConfirmArchiveGuestUserDialog();
	};

	const handleNavigateToOrder = useCallback(() => {
		navigate(
			generatePath(ROUTES.SHIPMENTS.DETAIL, {
				shipmentId: orderForConversation?.id.toString(),
				workspaceId: orderForConversation?.workspaceId?.toString(),
			}),
		);
	}, [orderForConversation, navigate]);

	return (
		<>
			<Can I={Actions.MANAGE} a="Workspace">
				{() => (
					<Stack display="flex">
						<SidebarContent title="contacts.toolSidebar.assignedOrder">
							{contactId &&
							orderForConversation?.contacts?.find(
								(contact) => contact.id === contactId,
							) ? (
                <>
                <Box
                  width="100%" // Ensures the Box takes the full width of its parent
                >
                  <Typography
                    variant="body1"
                    onClick={handleNavigateToOrder}
                    style={{
                      cursor: 'pointer',
                      color: '#1976d2',
                      textAlign: 'center', // Centers text horizontally
                      display: 'block',    // Ensures textAlign works as expected
                    }}
                    aria-label={`View orders for ${orderForConversation?.customer || 'Customer'}`}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') handleNavigateToOrder();
                    }}
                  >
                    {orderForConversation?.customer || 'No Customer Available'}
                  </Typography>
                </Box>
              <StyledSidebarButton
                data-test="invite-guest-button"
                variant="contained"
                onClick={handleCloseOrder}
              >
                Remove
              </StyledSidebarButton>
            </>
							) : (
								t('contacts.toolSidebar.noOrderAssigned')
							)}
						</SidebarContent>

						<SidebarContent>
							{!orderForConversation ? (
								<OrderSelect
									confirmationMessage={t('contacts.toolSidebar.assignOrderConfirmation')}
									shouldConfirm={currentAssignment ? true : false}
									onSelect={onSelectOrder}
								/>
							) : (
								''
							)}
						</SidebarContent>

						<Divider />

						<SidebarList>
							<SidebarContent title={t('contacts.toolSidebar.inviteGuestTitle')}>
								<StyledSidebarDescription>
									{t('contacts.toolSidebar.inviteGuestDescription')}
								</StyledSidebarDescription>

								<StyledGuestList>
									{guests?.map((guest) => (
										<GuestUser
											key={guest.id}
											onClick={() => handleRemoveGuest(guest.id)}
											firstName={guest.firstName}
											lastName={guest.lastName}
											email={guest.email}
											guest
										/>
									))}
									{uniquePendingGuestInvitations?.map((guestInvitation) => (
										<GuestInvitation
											key={guestInvitation.id}
											email={guestInvitation.email}
											onClick={() =>
												handleRemovePendingInviteClick(guestInvitation.email)
											}
											invitationDate={guestInvitation.createdAt}
										/>
									))}
								</StyledGuestList>

								<StyledSidebarButton
									data-test="invite-guest-button"
									variant="contained"
									onClick={openInviteDialog}
								>
									{t('contacts.toolSidebar.inviteGuestButton')}
								</StyledSidebarButton>
							</SidebarContent>
						</SidebarList>
					</Stack>
				)}
			</Can>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			<InviteGuestUserDialog
				onClose={closeInviteDialog}
				isOpen={showInviteDialog}
				handleInviteSuccess={handleInviteSuccess}
				workspaceId={workspaceId.toString()}
				conversationId={conversationId.toString()}
			/>

			<ConfirmArchiveDialog
				dataTest="archive-pending-guest-invitation-dialog"
				isOpen={showConfirmArchivePendingInvitationDialog}
				onClose={closeConfirmArchivePendingInvitationDialog}
				dialogTitle={t('settings.archiveInvitationDialog.title')}
				dialogContent={t('settings.archiveInvitationDialog.body')}
				onArchive={handleArchivePendingInvitations}
			/>

			<ConfirmArchiveDialog
				dataTest="archive-guest-user-dialog"
				isOpen={showConfirmArchiveGuestUserDialog}
				onClose={closeConfirmArchiveGuestUserDialog}
				dialogTitle={t('settings.archiveGuestUserDialog.title')}
				dialogContent={t('settings.archiveGuestUserDialog.body')}
				onArchive={handleArchiveGuestUser}
			/>
		</>
	);
};
