import { SidebarLayout } from '@heylog-app/frontend-lib/app';

import type { FC } from 'react';
import { StyledBackground } from './contactsV2.styles';
import { ContactsDataTable } from './components/contactsV2Table';

export const ContactsPageV2: FC = () => {
    return (
		<SidebarLayout>
			<StyledBackground>
				<ContactsDataTable />
			</StyledBackground>
		</SidebarLayout>
	);
};
