import { Stack } from '@mui/material';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import React, { useContext } from 'react';
import { mdiArrowLeft, mdiPencil } from '@mdi/js';

import {
	ButtonV2,
	colors,
	IconFire,
	OrderStatusMenu,
	ROUTES,
	useOrderActions,
	IconAttention,
	OrderContext,
	OrderDialogActionEnum,
} from '@heylog-app/frontend-lib/app';

import {
	StyledDetailsTitle,
	StyledGoBackLink,
	StyledHeaderCustomerLabelTitle,
	StyledHeaderLabelTitle,
	StyledParagraph,
	StyledReference,
	StyledSpan,
	StyledSpanCustomer,
} from '../shipment-details.styles';

import type { OrderResInterface } from '@heylog-app/shared/types';
import type { FC } from 'react';

interface ShipmentHeaderPropsInterface {
	internalReference: string | undefined;
	priority: boolean | undefined;
	attention: boolean | undefined;
	shipmentId: string;
	order?: OrderResInterface;
}

export const ShipmentHeader: FC<ShipmentHeaderPropsInterface> = ({
	internalReference,
	priority,
	attention,
	shipmentId,
}) => {
	const { order, toggleUrgentOrder, toggleAttentionOrder } = useOrderActions(shipmentId);
	const { t } = useTranslation();
	const params = useParams();

	const handleMarkAsPriority = () => {
		toggleUrgentOrder();
	};

	const handleMarkerAsAttentioned = () => {
		toggleAttentionOrder();
	};

	const { onOpenOrderDialog } = useContext(OrderContext);

	const handleUpdateOrderClick = () => {
		if (onOpenOrderDialog)
			onOpenOrderDialog({
				action: OrderDialogActionEnum.OPEN,
				payload: { order },
			});
	};

	return (
		<Stack gap={1}>
			<StyledGoBackLink to={generatePath(ROUTES.DASHBOARD.OVERVIEW, params)}>
				<Icon path={mdiArrowLeft} size={1} />
				{t('shipment.backNew')}
			</StyledGoBackLink>
			<Stack gap={2} direction="row" flexWrap="wrap" justifyContent="space-between">
				<Stack gap={2} direction="row" flexWrap="wrap" alignItems="center">
				{order?.refNumber && (
						<>
							<StyledHeaderCustomerLabelTitle>
								{t('shipment.orderReference') + ': '}
							</StyledHeaderCustomerLabelTitle>
							<StyledReference style={{}}>
								{order?.refNumber}
							</StyledReference>
						</>
					)}
				</Stack>

				<Stack gap={2} direction="row" alignItems="center" justifyContent="flex-end">
					<ButtonV2
						$variant={priority ? 'warning' : 'light2'}
						onClick={handleMarkAsPriority}
						startIcon={<IconFire />}
						$typography="T3"
						$padding="8px 18px"
						$height="48px"
					>
						{t('shipment.markAsUrgent')}
					</ButtonV2>
					<ButtonV2
						onClick={handleUpdateOrderClick}
						$variant="light2"
						startIcon={<Icon path={mdiPencil} size={1} />}
						$typography="T3"
						$padding="8px 18px"
						$height="48px"

					>
						{t('orders.form.editOrderTitle')}
					</ButtonV2>

					<ButtonV2
						onClick={handleMarkerAsAttentioned}
						$variant={attention ? 'error' : 'light2'}
						startIcon={attention ? <IconAttention /> : undefined}
						$typography="T3"
						$padding="8px 18px"
					>
						{t('shipment.attention')}
					</ButtonV2>
					<OrderStatusMenu fullWidth={false} isV2 orderId={shipmentId} />
				</Stack>
			</Stack>

			<StyledParagraph>
				<Stack
					direction="row"
					alignItems="center"
					spacing={2} // Adjust the spacing between the text and the labels
				>
					{order?.customer && (
						<>
							<StyledHeaderCustomerLabelTitle>
								{t('shipment.customer') + ': '}
							</StyledHeaderCustomerLabelTitle>
							<StyledSpanCustomer>{order?.customer}</StyledSpanCustomer>
						</>
					)}{' '}
					{/* {order?.refNumber && (
						<>
							<StyledHeaderCustomerLabelTitle>
								{t('shipment.orderReference') + ': '}
							</StyledHeaderCustomerLabelTitle>
							<StyledSpanCustomer>{order?.refNumber}</StyledSpanCustomer>
						</>
					)} */}

				</Stack>
			</StyledParagraph>
		</Stack>
	);
};
