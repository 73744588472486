import styled from 'styled-components';

import { T2_medium, colors } from '@heylog-app/frontend-lib/app';

export const StyledTitle = styled.div`
	${T2_medium};
	color: ${colors.grey[900]};
`;

export const TypoNoConversation = styled.div`
color: ${colors.grey[400]};
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
letter-spacing: -0.2px;
`
export const StyledDivNoConver = styled.div`
	display: flex;
	flex-direction: column;
	    align-items: center;
`