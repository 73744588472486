import dayjs from 'dayjs';

export enum Whatsapp24HoursUsage {
	SENDING = 'SENDING',
	TRIGGER_PUBSUB = 'TRIGGER_PUBSUB',
}
export function isWithinWhatsapp24Hours({
	date,
	usage,
}: {
	date: Date;
	usage: Whatsapp24HoursUsage;
}): boolean {
	const messageDate = dayjs(date);
	switch (usage) {
		case Whatsapp24HoursUsage.SENDING:
			return messageDate.isAfter(dayjs().subtract(24, 'hours').add(10, 'minutes'));
		// to test message buffering: return messageDate.isAfter(dayjs().subtract(5, 'minutes').add(10, 'seconds'));
		case Whatsapp24HoursUsage.TRIGGER_PUBSUB:
			return messageDate.isAfter(dayjs().subtract(24, 'hours').add(20, 'minutes'));
		// to test message buffering: return messageDate.isAfter(dayjs().subtract(5, 'minutes').add(20, 'seconds'));
	}
}

export function isToday(date: Date | null): boolean {
	// console.log('isToday --util', date);
	if (!date) {
		return false;
	}
	return dayjs(date).isSame(dayjs(), 'day');
}

export function isThisWeek(date: Date | null): boolean {
	if (!date) {
		return false;
	}
	return dayjs(date).isSame(dayjs(), 'week');
}

export function IsNextSevenDays(date: Date | null): boolean {
	if (!date) {
		return false;
	}
	const now = dayjs();
	const targetDate = dayjs(date);
	return targetDate.isAfter(now, 'day') && targetDate.isBefore(now.add(8, 'days'), 'day');
}
