import { SidebarLayout } from '@heylog-app/frontend-lib/app';

import { StyledBackground } from './shipment.styles';
import { ShipmentDetails } from './components/shipment-details';

import { useEffect, type FC } from 'react';

export const ShipmentPage: FC = () => {
	

	return (
		<SidebarLayout overflowAuto={true}>
			<StyledBackground>
				<ShipmentDetails />
			</StyledBackground>
		</SidebarLayout>
	);
};
