import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Dialog, DialogTitle, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	generatePath,
	Outlet,
	useNavigate,
	useOutletContext,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import { Icon } from '@mdi/react';
import { mdiAccountPlusOutline, mdiArrowLeft } from '@mdi/js';

import {
	AddContactForm,
	ContactSkeleton,
	ModalClose,
	ROUTES,
	Sidebar,
	SidebarHeader,
	SidebarLayout,
	SidebarLayoutContent,
	SidebarList,
	SidebarSearch,
	useContacts,
	useDebounce,
	useDialog,
	useUnread,
	useUser,
	ContactListItem,
	useOrderActions,
	FlashSnackbar,
	useSnackbar,
	Can,
	usePlugins,
	ButtonV2,
	colors,
	useConversationGroup,
	ContactListItemV2,
} from '@heylog-app/frontend-lib/app';
import {
	OrderConnectionStatusEnum,
	ContactStatusEnum,
	Actions,
	ConversationTypeEnum,
} from '@heylog-app/shared/types';

import {
	StyledToggleButton,
	StyledToggleButtonGroup,
	StyledChip,
	StyledDetailsTitle,
} from './contacts.styles';
import { CreateContactWelcome } from './components/welcome-blocks/create-contact-welcome';
import { SelectContactWelcome } from './components/welcome-blocks/select-contact-welcome';
import { NoContactsGuest } from './components/welcome-blocks/no-contacts-guest';

import type { FC, ChangeEvent } from 'react';
import type {
	FullContactResInterface,
	OrderResInterface,
	ContactStatusType,
} from '@heylog-app/shared/types';
import { StyledGoBackLink } from 'libs/frontend-lib/modules/shipments/src/pages/shipment/components/shipment-details.styles';

type ContactOrderContextType = {
	currentConvo: any;
};

export const useContactContextV2 = (): ContactOrderContextType => {
	return useOutletContext<ContactOrderContextType>();
};

export const ContactsPageV3: FC = () => {
	const { t } = useTranslation();
	const params = useParams();
	const { workspaceId = '', contactId, conversationId, activeTab } = params;


	const navigate = useNavigate();
	const { unreadMap, isLoading: unreadLoading } = useUnread();
	const { user } = useUser();

	const { converGroupData, isLoadingConverGroup } = useConversationGroup()
	console.log('converGroupData', converGroupData)

	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const currentContact = useMemo(() => {
		if (!converGroupData) return null;
		return converGroupData?.find((contact: any) => Number(contact.id) === Number(conversationId));
	}, [converGroupData, conversationId]);


	const onSelectContact = useCallback(
		(contact: any) => {
			navigate(
				generatePath(ROUTES.CONTACTS.CHAT4, {
					...params,
					contactId: contact?.id,
					conversationId: contact?.id,
					activeTab: ConversationTypeEnum.HEYLOG_USER,
				}),
			);
		},
		[workspaceId, conversationId, navigate, params],
	);

	return (
		<>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			<Box display="flex" flexDirection="column" height="100%">
				<SidebarLayout>
					<Box
						width="95%"
						sx={{
							marginTop: '30px',
							marginLeft: '30px',
							paddingBottom: '20px',
						}}
					>
						<StyledGoBackLink to={generatePath(ROUTES.MESSAGES.OVERVIEW, params)}>
							<Icon path={mdiArrowLeft} size={1} />
							{t('messages.backNew')}
						</StyledGoBackLink>
						<Stack flexDirection="row" justifyContent="space-between">
							<StyledDetailsTitle>{t('contacts.title2', { type: activeTab === ConversationTypeEnum.HEYLOG_USER ? "Heylog " : activeTab === ConversationTypeEnum.WHATSAPP ? "Whatsapp " : " " })}</StyledDetailsTitle>
						</Stack>
					</Box>
					<Sidebar
						variant="conversations"
						width="30%"
						marginLeft="25px"
						border="1px solid rgba(0, 0, 0, 0.12)"
					>

						<SidebarList>
							{converGroupData?.length && !unreadLoading ? (
								converGroupData?.map((contact: any) => (
									<ContactListItemV2
										data-test="contact-list-item"
										onSelectContact={onSelectContact}
										key={contact.id}
										contact={contact}
										unreadCount={unreadMap?.[contact.id] ?? 0}
									/>
								))
							) : unreadLoading ? (
								<ContactSkeleton animated={isLoadingConverGroup} />
							) : null}
						</SidebarList>
					</Sidebar>
					<Box
						sx={{
							borderTop: `1px solid ${colors.border.main}`,
							width: '66%',
							height: '72vh',
							overflowY: 'hidden',
						}}
					>
						<SidebarLayoutContent variant="primary">
							<Outlet
								context={{
									currentConvo: currentContact ? currentContact : null,
								}}
							/>
						</SidebarLayoutContent>
					</Box>
				</SidebarLayout>
			</Box>


			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
