import { StyledMessageGroup, StyledMessageGroupWrapper } from './message-group.styles';

import type { FC, ReactNode } from 'react';
import type { MessageResInterface } from '@heylog-app/shared/types';

type MessageGroupProps = {
	children: ReactNode;
	message: MessageResInterface;
	inactive?: boolean;
};

export const MessageGroupV2: FC<MessageGroupProps> = ({
	message,
	children,
	inactive = false,
}) => {
	return (
		<StyledMessageGroupWrapper
			data-test="message-group"
			$direction={message.direction}
			$inactive={inactive}
		>
			<StyledMessageGroup $direction={message.direction}>{children}</StyledMessageGroup>
		</StyledMessageGroupWrapper>
	);
};
