import { Container, Grid, Stack, ThemeProvider, createTheme } from '@mui/material';
import i18next from 'i18next';
import { useMemo, useState } from 'react';
import * as locales from '@mui/material/locale';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import Icon from '@mdi/react';
import { mdiTune } from '@mdi/js';

import {
	SidebarLayout,
	useAttachmentsData,
	theme,
	useTableColumnsSettings,
	ButtonV2,
	TableFilterSidebar,
	TabsGroupV2,
} from '@heylog-app/frontend-lib/app';

import { StyledBackground } from './documents-page.styles';
import { DocumentsDataTable } from './components';
import { DocumentsDataTableViewTypes } from './components/documents-data-table';

import type { FC } from 'react';

const tableTab = {
	title: 'attachments.listLayout',
	value: DocumentsDataTableViewTypes.TABLE,
};

const gridTab = {
	title: 'attachments.gridLayout',
	value: DocumentsDataTableViewTypes.GRID,
};

const tabs = [tableTab, gridTab];

export const DocumentsPage: FC = () => {
	const [isOpenFilters, setIsOpenFilters] = useState(false);
	const apiRef = useGridApiRef();
	const { columns, rows } = useAttachmentsData();
	const [tabValue, setTabValue] = useState<DocumentsDataTableViewTypes>(tableTab.value);
	const currentLanguage = i18next.language;
	const tableLanguage = currentLanguage === 'de' ? 'deDE' : 'enUS';
	const {
		adjustedColumns,
		handleColumnResize,
		handleColumnOrderChange,
		handleColumnVisibilityModelChange,
	} = useTableColumnsSettings({
		columns,
		apiRef,
		localStorageName: 'documents-data-table',
	});

	const themeWithLocale = useMemo(() => {
		return createTheme(theme, locales[tableLanguage]);
	}, [tableLanguage]);

	const handleCloseFilters = () => setIsOpenFilters(false);
	const handleOpenFilters = () => setIsOpenFilters(true);

	return (
		<SidebarLayout>
			<StyledBackground>
				<ThemeProvider theme={themeWithLocale}>
					<TableFilterSidebar
						title="attachments.viewDocuments"
						onClose={handleCloseFilters}
						isOpen={isOpenFilters}
					>
						<Stack padding={2} direction="row" justifyContent="center">
							<TabsGroupV2 tabs={tabs} value={tabValue} onChange={setTabValue} />
						</Stack>
					</TableFilterSidebar>
					<Grid
						container
						wrap="nowrap"
						columns={{ xs: 12 }}
						sx={{ height: '100%', overflow: 'auto' }}
					>
						<Container
							maxWidth={false}
							sx={{
								paddingTop: '2rem',
								paddingBottom: '2rem',
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
							}}
						>
							{
								// <StyledGridSidebar item>
								// 	<Typography sx={{ fontWeight: 700 }}>Documents</Typography>
								// 	<Stack sx={{ mt: '4px' }}>
								// 		<FormGroup>
								// 			<StyledFormControlLabel
								// 				control={<Checkbox defaultChecked />}
								// 				label="All"
								// 			/>
								// 			<StyledFormControlLabel control={<Checkbox />} label="Images" />
								// 			<StyledFormControlLabel control={<Checkbox />} label="Videos" />
								// 			<StyledFormControlLabel control={<Checkbox />} label="Audio" />
								// 			<StyledFormControlLabel control={<Checkbox />} label="Documents" />
								// 		</FormGroup>
								// 	</Stack>
								// 	<Typography sx={{ mt: 4, fontWeight: 700 }}>Labels</Typography>
								// 	<Stack sx={{ mt: '4px' }}>
								// 		<FormGroup>
								// 			<StyledFormControlLabel
								// 				control={<Checkbox defaultChecked />}
								// 				label="Urgent"
								// 			/>
								// 			<StyledFormControlLabel control={<Checkbox />} label="Trash" />
								// 			<StyledFormControlLabel control={<Checkbox />} label="Spam" />
								// 		</FormGroup>
								// 	</Stack>
								// </StyledGridSidebar>
							}
							<DocumentsDataTable
								title="navigation.documents"
								viewType={tabValue}
								TableToolbar={
									isOpenFilters ? undefined : (
										<ButtonV2
											onClick={handleOpenFilters}
											$variant="light2"
											$padding="16px"
										>
											<Icon path={mdiTune} size={1} />
										</ButtonV2>
									)
								}
								apiRef={apiRef}
								columns={adjustedColumns}
								rows={rows}
								handleColumnOrderChange={handleColumnOrderChange}
								handleColumnResize={handleColumnResize}
								handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
							/>
						</Container>
					</Grid>
				</ThemeProvider>
			</StyledBackground>
		</SidebarLayout>
	);
};
