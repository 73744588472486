import { ButtonMenu } from '../ui';
import { ListItemText, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, H1, T3, T3_medium } from '../../styles';

export const StyledTabPanelWrapper = styled.div`
	height: 100%;
`;

export const StyledTabPanelContent = styled.div`
	height: 100%;
`;

export const StyledParagraph = styled.p`
	${T3};
	color: ${colors.grey[600]};
	margin: 0;
`;
export const StyledGoBackLink = styled(Link)`
	${T3_medium};
	color: ${colors.primary.main};
	display: flex;
	gap: 8px;
	margin-bottom: 8px;
	text-decoration: unset;
	max-width: fit-content;
`;
export const StyledTitle = styled.div`
	${H1};
	color: ${colors.grey[900]};
`;

export const StyledTextField = styled(TextField)({
	width: '240px',
	'& .MuiInputBase-root': {
		height: '48px',
		width: "240px"
	},
	'& .MuiInputLabel-root': {
		position: 'absolute',
		left: '5px',
		top: '-5px',
	},
});
export const StyledMenuButton = styled(ButtonMenu)`
	.MuiMenu-paper {
		min-width: 160px;
	}	
`;
export const StyledListItemText = styled(ListItemText)`
	color: ${colors.grey[900]};
	&:hover {
		background-color: rgb(209, 212, 242);
	}
`;