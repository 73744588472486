import { useEffect, useRef, memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Icon from '@mdi/react';
import { CircularProgress } from '@mui/material';
import { mdiShareVariantOutline } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import {
	Actions,
	ContactStatusEnum,
	MessagingTransportTypeEnum,
	UserRoleEnum,
} from '@heylog-app/shared/types';

import { FlashSnackbar } from '../snackbars/flash-snackbar';
import {
	markConversationsAsReadApi,
	useApiClientContext,
	useEnvContext,
	useSetConversationToRead,
	useSnackbar,
	usePlugins,
	useUser,
	useContact,
	useConversation,
	useRelevantEvents,
	useMessages,
	useMessagesV2,
} from '../../hooks';
import {
	StyledConversationMessages,
	StyledConversation,
	StyledOarHeader,
	StyledOarText,
	StyledOarButton,
} from './conversation.styles';
import { Message } from './message';
import { ConversationEvent } from '../ui/order-event/order-event';
import { useRealtimeConversationHook } from './hooks';
import { useOrderActiveInterval } from '../../hooks/use-order-active-interval';
import { getOrderAcceptanceRequestKey, sortByCreatedAtAsc } from '../../util';
import { MessageInputRenderer } from './message-input-renderer';
import { MessageInputBlocked } from '../ui';
import { Can } from '../can';
import {
	NUMBER_OF_ITEMS_TO_DISPLAY,
	NUMBER_OF_ITEMS_TO_FETCH,
	THRESHOLD_TO_FETCH_NEW_ITEMS,
} from './comversation-constants';

import type { ChatAppDialogPayloadInterface } from '../../providers';
import type {
	OrderResInterface,
	FullContactResInterface,
	Reaction,
	MessageResInterface,
	ContactStatusType,
	EntityEventResType,
} from '@heylog-app/shared/types';
import type { CSSProperties, FC } from 'react';

type ConversationProps = {
	// the order context is used when the conversation is viewed inside an order
	order?: OrderResInterface;
	// the contact order context is used when a conversation in the contacts view with an assigned order
	contactOrderContext?: { order: OrderResInterface; contact: FullContactResInterface };
	contactFilter?: ContactStatusType;

	// Optional prop for using data from parent component instead of useParams
	fetchData?: ChatAppDialogPayloadInterface;
	textareaWrapperStyles?: CSSProperties;
	shouldFocusOnRender?: boolean;
};

export const Conversation: FC<ConversationProps> = memo(
	({
		order,
		contactOrderContext,
		contactFilter,
		fetchData,
		textareaWrapperStyles,
		shouldFocusOnRender,
	}) => {
		const params = useParams();
		const conversationId = fetchData?.conversationId || params['conversationId'] || '';
		const conversationV2Id = fetchData?.conversationV2Id || '';
		const workspaceId = fetchData?.workspaceId || params['workspaceId'] || '';
		const orderId = fetchData ? fetchData?.orderId || '' : params['orderId'] || '';
		const contactId = fetchData?.contactId || params['contactId'] || '';
		const { production } = useEnvContext();

		const [numberOfItemsToDisplay, setNumberOfItemsToDisplay] = useState(
			NUMBER_OF_ITEMS_TO_DISPLAY,
		);

		const [realDate, setRealDate] = useState<Date | undefined>(undefined);
		const [latestMessageDate, setLatestMessageDate] = useState<Date | undefined>(
			undefined,
		);

		const { user } = useUser();

		const [isTabActive, setIsTabActive] = useState(true);

		const { t } = useTranslation();

		const { conversation } = useConversation(
			workspaceId.toString(),
			conversationId.toString(),
		);
		const { contact } = useContact(conversation?.contactId?.toString());
		const { apiClient } = useApiClientContext();

		const [isOarLoading, setisOarLoading] = useState(false);
		const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

		const { TimeMattersOSUPlugin } = usePlugins(workspaceId.toString());

		const [messagesDateFrom, setMessagesDateFrom] = useState<Date | undefined>(undefined);
		const [allMessages, setAllMessages] = useState<
			(EntityEventResType | MessageResInterface)[]
		>([]);
		const hasMoreItemsToDisplay = allMessages.length > numberOfItemsToDisplay;

		// this is used to fetch the messages when a realtime messages is received
		// we cannot use the one below since once we start paginating we keep fetching the same page over and over
		const {
			messages: messagesForRealtime,
			updateMessages: updateMessagesForRealtime,
			isLoadingMessages: isLoadingMessagesForRealtime,
			isValidatingMessages: isValidatingMessagesForRealtime,
		} = useMessages({
			conversationId: conversationId.toString(),
			workspaceId: workspaceId.toString(),
			messagesToFetch: NUMBER_OF_ITEMS_TO_FETCH,
		});

		const {
			messages: messagesV1,
			updateMessages: updateMessagesV1,
			isLoadingMessages: isLoadingMessagesV1,
			isValidatingMessages: isValidatingMessagesV1,
		} = useMessages({
			conversationId: conversationId.toString(),
			workspaceId: workspaceId.toString(),
			messagesToFetch: NUMBER_OF_ITEMS_TO_FETCH,
			messagesDateFrom,
		});

		const {
			messages: messagesV2,
			updateMessages: updateMessagesV2,
			isLoadingMessages: isLoadingMessagesV2,
			isValidatingMessages: isValidatingMessagesV2,
		} = useMessagesV2({
			conversationV2Id: conversationV2Id.toString(),
			workspaceId: workspaceId.toString(),
			messagesToFetch: NUMBER_OF_ITEMS_TO_FETCH,
			messagesDateFrom,
		});

		const {
			entityEvents,
			mutateEvents: updateEvents,
			isLoadingRelevantEvents,
			isValidatingRelevantEvents,
		} = useRelevantEvents(workspaceId, contactId.toString());

		const scrollToBottom = () => {
			if (messageContainerRef.current) {
				messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
			}
		};

		useEffect(() => {
			setLatestMessageDate(allMessages[allMessages.length - 1]?.createdAt);
		}, [allMessages]);

		useEffect(() => {
			if (latestMessageDate) {
				scrollToBottom();
			}
		}, [latestMessageDate]);

		useEffect(() => {
			// reset pagination when the conversation changes
			setAllMessages([]);
			setNumberOfItemsToDisplay(NUMBER_OF_ITEMS_TO_DISPLAY);
			setMessagesDateFrom(undefined);
			setRealDate(undefined);
		}, [params]);

		useEffect(() => {
			// reset pagination when the conversation changes
			setAllMessages([]);
			setNumberOfItemsToDisplay(NUMBER_OF_ITEMS_TO_DISPLAY);
			setMessagesDateFrom(undefined);
			setRealDate(undefined);
		}, [params]);

		useEffect(() => {
			// i need to extract the V1 messages from v2 messages
			const messagesV2toV1 = messagesV2?.map(
				(message) => message?.transportMessage,
			) as MessageResInterface[];
			const messagesFromV1orV2 = conversationV2Id ? messagesV2toV1 : messagesV1;
			if (
				isLoadingRelevantEvents ||
				isValidatingRelevantEvents ||
				isValidatingMessagesV1 ||
				isLoadingMessagesV1 ||
				isLoadingMessagesV2 ||
				isValidatingMessagesV2 ||
				isLoadingMessagesForRealtime ||
				isValidatingMessagesForRealtime
			) {
				return;
			}

			setAllMessages((oldMessages) => {
				// for now i do not merge this on conversationV2
				// otherwise i would get ALL the messages
				// this means, i will not update v2 messages on realtime updates
				const realtimeMessages = conversationV2Id ? [] : messagesForRealtime;

				// Merge the arrays

				const merged = [
					...(entityEvents || []),
					...(messagesFromV1orV2 || []), // pagination
					...(realtimeMessages || []), // realtime
					...oldMessages,
				];

				// for consistency with mergedWithEventsFilteredByOrder I filter notEmptyEvents here too
				const notEmptyEvents = merged?.filter((event) => {
					if ('eventType' in event) {
						return (
							event?.eventType !== 'TIMEMATTERS_ORDER_STATUS_CHANGED' &&
							event?.eventType !== 'POD_PENDING'
						);
					}
					// messages pass through
					return true;
				});

				// i need to do this here becauxe i might pass here before i receive the orderId
				const mergedWithEventsFilteredByOrder = orderId
					? merged.filter((event) => {
						if ('eventType' in event) {
							return (
								(event.contextType === 'ORDER_EVENT' &&
									event.eventType === 'ORDER_STATUS_CHANGED' &&
									event.externalID.toString() === orderId.toString()) ||
								(event.contextType === 'CONTACT_EVENT' &&
									event.eventType === 'CONTACT_ORDER_ASSIGNMENT_CHANGED' &&
									event?.eventPayload?.orderId === orderId)
							);
						}
						// messages pass through
						return true;
					})
					: notEmptyEvents;

				// Remove duplicates
				const uniqueMap = new Map(
					mergedWithEventsFilteredByOrder.map((item) => [item.id, item]),
				);
				const unique = Array.from(uniqueMap.values());

				// Sort by date (oldest first)
				const sorted = unique.sort(sortByCreatedAtAsc);
				return sorted;
			});
			// Update messagesDateFrom to the date of the oldest message
			const oldestMessageDate = messagesFromV1orV2 && messagesFromV1orV2[0]?.createdAt;
			// what if not? well, i implicity assume it'll never happen
			// the solution here would be to change and do not make createdAt optional

			// TODO: what if it's an event? a reaction?
			if (oldestMessageDate) {
				setRealDate(new Date(oldestMessageDate));
			}
		}, [
			conversationV2Id,
			messagesV1,
			messagesV2,
			messagesForRealtime,
			entityEvents,
			orderId,
			isLoadingRelevantEvents,
			isValidatingRelevantEvents,
			isValidatingMessagesV1,
			isLoadingMessagesV1,
			isLoadingMessagesV2,
			isValidatingMessagesV2,
			isLoadingMessagesForRealtime,
			isValidatingMessagesForRealtime,
		]);

		const displayMoreItems = () => {
			if (!hasMoreItemsToDisplay) {
				return;
			} else {
				setNumberOfItemsToDisplay((prev) => prev + NUMBER_OF_ITEMS_TO_DISPLAY);
			}
		};

		const loadMoreItems = () => {
			// if the last batch of messages that we fetched is smaller than a page, it means we reached the end
			const messagesForCheck = conversationV2Id ? messagesV2 : messagesV1;
			const moreToLoad =
				messagesForCheck && messagesForCheck.length === NUMBER_OF_ITEMS_TO_FETCH;

			// if we are THRESHOLD_TO_FETCH_NEW_ITEMS away from the end, we fetch again
			const lessThanThreshold =
				allMessages.length - numberOfItemsToDisplay < THRESHOLD_TO_FETCH_NEW_ITEMS;

			const needsToLoadMoreItems = lessThanThreshold && moreToLoad;

			if (needsToLoadMoreItems) {
				setMessagesDateFrom(realDate);
			}
		};

		useSetConversationToRead(conversationId.toString());
		useRealtimeConversationHook({
			updateMessages: updateMessagesForRealtime,
			updateEvents,
			updateMessagesV2,
		});

		useEffect(() => {
			const handleVisibilityChange = () => {
				setIsTabActive(!document.hidden);
			};

			document.addEventListener('visibilitychange', handleVisibilityChange);

			return () => {
				document.removeEventListener('visibilitychange', handleVisibilityChange);
			};
		}, []);

		useEffect(() => {
			if (
				contact &&
				contact.conversations[0] &&
				isTabActive &&
				user?.role !== UserRoleEnum.CONVERSATION_GUEST &&
				production
			) {
				markConversationsAsReadApi(
					apiClient,
					workspaceId.toString(),
					contact.conversations[0]?.id.toString(),
				);
			}
		}, [apiClient, contact, workspaceId, isTabActive, user?.role, production]);

		// we need to provide the order context as well
		const messageActiveInterval = useOrderActiveInterval(entityEvents, order);

		const handleOarClick = async () => {
			setisOarLoading(true);
			const oarKey = getOrderAcceptanceRequestKey(
				workspaceId.toString(),
				orderId.toString(),
				contactId.toString(),
			);

			await apiClient
				.post(oarKey)
				.then(() => {
					openSnackbar('success', t('orders.oarSnackbarSuccess'));
					setisOarLoading(false);
					if (conversationV2Id) {
						updateMessagesV2();
					}
					// updateMessagesV1 is probably redundant
					updateMessagesV1();
					updateEvents();
				})
				.catch((err) => {
					openSnackbar('error', t('orders.oarSnackbarError'));
					setisOarLoading(false);
					console.log(err);
				});
		};

		const messageContainerRef = useRef<HTMLDivElement>(null);

		const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
			const scrollTop = e.currentTarget.scrollTop;
			if (scrollTop === 0) {
				// Save the current scroll position and scrollable area height
				const prevScrollTop = messageContainerRef.current?.scrollTop || 0;
				const prevScrollHeight = messageContainerRef.current?.scrollHeight || 0;

				displayMoreItems();
				loadMoreItems();

				// After a delay (to allow for rendering), adjust the scroll position
				setTimeout(() => {
					const newScrollHeight = messageContainerRef.current?.scrollHeight || 0;
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					messageContainerRef.current!.scrollTop =
						prevScrollTop + (newScrollHeight - prevScrollHeight);
				}, 0);
			}
		};

		const getReactions = (msgOrEvent: MessageResInterface) => {
			if (conversation?.transportType !== MessagingTransportTypeEnum.FACEBOOK_WHATSAPP)
				return;
			const messagesForCheck = conversationV2Id
				? messagesV2?.map((message) => message.transportMessage as MessageResInterface)
				: messagesV1;

			return messagesForCheck?.filter((message: MessageResInterface) => {
				if ('reaction' in message) {
					return (
						((message as MessageResInterface).reaction as Reaction)?.message_id ===
						msgOrEvent.remoteId
					);
				}
				return false;
			});
		};

		if (!conversation) return null;
		console.log('contactFilter', contactFilter)
		// if (
		// 	!order &&
		// 	contact?.status !== contactFilter &&
		// 	!(
		// 		contactFilter === ContactStatusEnum.PENDING_ONBOARDING &&
		// 		contact?.status === ContactStatusEnum.PENDING_ONBOARDING_TO_ORDER
		// 	)
		// ) {
		// 	return null;
		// }

		return (
			<>
				<StyledConversation data-test="conversation-wrapper" key={conversation.id}>
					{order &&
						!TimeMattersOSUPlugin &&
						conversation.transportType ===
						MessagingTransportTypeEnum.FACEBOOK_WHATSAPP && (
							<StyledOarHeader data-test="oar-header">
								<StyledOarText>{t('orders.header.shareOrderText')}</StyledOarText>

								<StyledOarButton
									data-test="send-oar-button"
									variant="contained"
									color="primary"
									onClick={handleOarClick}
									disabled={isOarLoading}
								>
									{isOarLoading ? (
										<CircularProgress size={26} />
									) : (
										<>
											<Icon path={mdiShareVariantOutline} size={'1.2rem'} />
											<span>{`${t('orders.header.shareOrderButton')} ${order?.refNumber
												}`}</span>
										</>
									)}
								</StyledOarButton>
							</StyledOarHeader>
						)}
					<StyledConversationMessages
						data-test="conversation-messages"
						onScroll={handleScroll}
						ref={messageContainerRef}
					>
						{/* negative slive to start from end */}
						{allMessages.slice(-numberOfItemsToDisplay)?.map((msgOrEvent) => {
							if (!msgOrEvent.id) {
								console.warn(`msgOrEvent.id is undefined`);
								return null;
							}

							if ('contextType' in msgOrEvent) {
								return (
									<ConversationEvent key={`event-${msgOrEvent.id}`} event={msgOrEvent} />
								);
							} else {
								const reactions = getReactions(msgOrEvent);

								return (
									<Message
										key={`message-${msgOrEvent.id}`}
										reactions={reactions}
										message={msgOrEvent}
										activeInterval={messageActiveInterval}
									/>
								);
							}
						})}
					</StyledConversationMessages>
					<div style={textareaWrapperStyles}>
						{contact?.status === ContactStatusEnum.ARCHIVED ? (
							<>
								<Can I={Actions.MANAGE} a="Workspace">
									{() => (
										<MessageInputBlocked title={t('chats.conversationArchivedForUser')} />
									)}
								</Can>
								<Can not I={Actions.MANAGE} a="Workspace">
									{() => (
										<MessageInputBlocked
											title={t('chats.conversationArchivedForGuest')}
										/>
									)}
								</Can>
							</>
						) : allMessages?.length ? (
							<>
								<Can I={Actions.MANAGE} a="Workspace">
									{() => (
										<MessageInputRenderer
											contactOrderContext={contactOrderContext}
											lastMessage={allMessages[allMessages.length - 1]}
											contactId={contactId.toString()}
											conversationId={conversationId.toString()}
											shouldFocusOnRender={shouldFocusOnRender}
										/>
									)}
								</Can>
								<Can not I={Actions.MANAGE} a="Workspace">
									{() => <MessageInputBlocked title={t('chats.guestNotification')} />}
								</Can>
							</>
						) : null}
					</div>
				</StyledConversation>
				<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
			</>
		);
	},
);
