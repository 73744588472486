import styled, { css } from 'styled-components';
import { Button, MenuItem } from '@mui/material';

import { T3_medium, colors } from '../../styles';

export const StyledButton = styled(Button) <{ $isV2?: boolean; $height?: string; $variant?: string }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $isV2, $height, $variant }) => css`
    ${$variant === 'dark' &&
		css`
      background-color: ${colors.primary.main};
      color: ${colors.common.white};

      &:hover {
        background-color: ${colors.primary.dark};
      }
    `}

    ${$variant === 'light' &&
		css`
      background-color: ${colors.grey[200]};
      color: ${colors.primary.main};

      &:hover {
        background-color: ${colors.primary.lighter};
      }
    `}

    ${$variant === 'warning' &&
		css`
      background-color: ${colors.warning.light};
      color: ${colors.warning.dark};

      &:hover {
        background-color: ${colors.warning.light};
      }
    `}

    ${$variant === 'error' &&
		css`
      background-color: ${colors.error.light};
      color: ${colors.error.dark};

      &:hover {
        background-color: ${colors.error.light};
      }
    `}

    ${$isV2 &&
		css`
      padding: 8px 18px;
      border-radius: 4px;
      box-shadow: unset;
      ${T3_medium};
      min-width: fit-content;
      height: ${$height || 'fit-content'};
      background-color: ${$variant ? '' : colors.grey[200]};
      color: ${$variant ? '' : colors.primary.main};

      &:hover {
        background-color: ${$variant ? '' : colors.primary.lighter};
        box-shadow: unset;
      }
    `}
  `}
`;

export const StyledMenuItem = styled(MenuItem)`
white - space: nowrap;
overflow: hidden;
text - overflow: ellipsis;

	& .MuiListItemIcon - root {
	width: 16px;
	min - width: 24px;
}
`;
