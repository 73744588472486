import { Badge, Container, Dialog, DialogTitle, Grid, Stack } from '@mui/material';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

import {
	AddContactFormV2,
	archiveContactsAPI,
	archiveContactsAPI2,
	EditContactFormV2,
	EmailDialogActionEnum,
	FlashSnackbarV2,
	MessageContext,
	MessageDialogTypesEnum,
	ModalClose,
	ROUTES,
	TableFilterSidebar,
	useApiClientContext,
	useContactActions,
	useContactsData,
	useDebounce,
	useDialog,
	useDialogV2,
	useMessagesData,
	useMessagesDataTableFilters,
	useSnackbar,
	useTableColumnsSettings,
} from '@heylog-app/frontend-lib/app';

import { ContactsDataGrid } from './contacts-data-grid';
import { Header } from '@heylog-app/frontend-lib/app';
import { ContactRoleType, Nullable } from '@heylog-app/shared/types';
export interface ContactsDetailsRow {
	contactId?: number;
	fullName?: string;
	company?: string;
	email?: string;
	contactNumber?: string;
	role?: ContactRoleType;
	preferredLanguage?: Nullable<string>;
}
type StatusType = 'ARCHIVED' | 'ALL' | 'PENDING_ONBOARDING' | 'ACTIVE';

export const ContactsDataTable = () => {
	const [isOpenFilters, setIsOpenFilters] = useState(false);
	const apiRef = useGridApiRef();
	const [pagiRows, setPagiRows] = useState<any>([]);
	const [selectedContact, setSelectedContact] = useState<ContactsDetailsRow | undefined>();
	const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
	const [paginationModel, setPaginationModel] = useState<any>({
		pageNumber: 1,
		pageSize: 10,
	})
	const navParams = useParams();
	const { workspaceId } = navParams;
	const [status, setStatus] = useState<StatusType>('ACTIVE');
	const { onOpenMessageDialog } = useContext(MessageContext);
	const { apiClient } = useApiClientContext();
	const [searchParams, setSearchParams] = useSearchParams();
	const {
		showDialog: showNewContactDialog,
		openDialog: openNewContactDialog,
		closeDialog: closeNewContactDialog,
	} = useDialog();
	const {
		showDialog: showEditDialog,
		openDialog: openEditDialog,
		closeDialog: closeEditDialog,
	} = useDialogV2();
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	// const { contact, deleteContact } = useContactActions(selectedContact?.contactId);

	const { t } = useTranslation();

	const onEditSuccess = useCallback(() => {
		openSnackbar('success', 'Contact Saved');
		closeEditDialog();
	}, [closeEditDialog]);

	const [loader, setLoader] = useState<boolean>(false)
	const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
	const debouncedSearchTerm = useDebounce<string | undefined>(searchTerm, 250);


	const handleEditContact = useCallback(
		(contact: ContactsDetailsRow) => {
			setSelectedContact(contact);
			openEditDialog();
		},
		[openEditDialog]
	);

	const { columns, rows, isLoading, updateContacts, totalRowsContact } = useContactsData(handleEditContact, debouncedSearchTerm, paginationModel, status);

	useEffect(() => {
		if (debouncedSearchTerm) {
			setSearchParams({ q: debouncedSearchTerm }, { replace: true });
		} else {
			setSearchParams({}, { replace: true });
		}
	}, [debouncedSearchTerm, setSearchParams]);

	const onChangePage = (props: any) => {
		setPaginationModel({ pageNumber: props.page + 1, pageSize: props.pageSize })
		setSearchParams({ pageNumber: props.page + 1, pageSize: props.pageSize }, { replace: true })
	}

	const {
		adjustedColumns,
		handleColumnResize,
		handleColumnOrderChange,
		handleColumnVisibilityModelChange,
	} = useTableColumnsSettings({
		columns,
		apiRef,
		localStorageName: 'messages-data-table',
	});

	const handleRowSelectionChange = (records: string[]) => {
		setSelectedContacts(records);
	}

	const handleNewContactSuccess = useCallback(() => {
		// openSnackbar('success', t('contacts.form.smsSent'));
		closeNewContactDialog();
	}, [closeNewContactDialog, t]);
	//openSnackbar

	const handleCloseFilters = () => setIsOpenFilters(false);
	// const handleOpenFilters = () => setIsOpenFilters(true);

	const handleArchiveAll = async () => {
		if (!workspaceId && selectedContacts?.length <= 0) return;
		setLoader(true)
		await archiveContactsAPI2(apiClient, {
			data: { contactIds: selectedContacts },
			workspaceId: workspaceId || "",
		})
			.then(() => {
				setLoader(false)
				openSnackbar('success', t('settings.archiveInvitationDialog.success'));
				updateContacts()
			})
			.catch((e) => {
				setLoader(false)
			})
	}

	return (
		<>
			<FlashSnackbarV2 controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
			<Grid container wrap="nowrap" columns={{ xs: 12 }} sx={{ height: '100%', width: "100%", padding: "16px" }}>
				<Stack direction="column" sx={{ height: '100%', width: "100%" }} gap={1}
				>
					<Header
						handleArchiveAll={handleArchiveAll}
						setStatus={setStatus}
						status={status}
						setSearchParams={setSearchParams}
						setSearchTerm={setSearchTerm}
						handleNewContactClick={openNewContactDialog}
						headerTitle={'contacts.sidebar.title'}
						newContactLabel={'contacts.newContact'}
						searchPlaceholder={"contacts.searchPlaceHolder"}
						handleSearchCallback={() => { }}
					/>
					<Stack
						sx={{
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							overflow: 'auto',
						}}
					>
						<ContactsDataGrid
							onPageChange={onChangePage}
							isLoading={isLoading}
							rows={rows}
							pagination={true}
							rowCount={totalRowsContact}
							columns={adjustedColumns}
							handleRowSelectionChange={handleRowSelectionChange}
							checkboxSelection={true}
							apiRef={apiRef}
							handleColumnOrderChange={handleColumnOrderChange}
							handleColumnResize={handleColumnResize}
							handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
							withPaginationSettings={true}
						/>
					</Stack>
				</Stack>
				<Dialog
					open={showNewContactDialog}
					onClose={closeNewContactDialog}
					fullWidth
					maxWidth="md"
				>
					<ModalClose closeModalFn={closeNewContactDialog} />
					<DialogTitle>{t('contacts.inviteNewContact')}</DialogTitle>
					<AddContactFormV2
						onSuccess={handleNewContactSuccess}
						onCancel={closeNewContactDialog}
					/>
				</Dialog>
				<Dialog open={showEditDialog} onClose={closeEditDialog}>
					<ModalClose closeModalFn={closeEditDialog} />
					<DialogTitle>{t('contacts.form.editContactTitle')}</DialogTitle>
					<EditContactFormV2
						openSnackbar={openSnackbar}
						onSuccess={onEditSuccess}
						onCancel={closeEditDialog}
						contact={selectedContact}
					/>
				</Dialog>
			</Grid>
		</>
	);
};
