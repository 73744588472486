import styled, { css } from "styled-components";

import {
  colors,
  mediaQueries,
  SIZE_SM,
  SIZE_SM_PLUS,
  STYLE_1,
  STYLE_2,
} from "../../../styles";
import { ButtonV2 } from "../buttonV2/buttonV2";

export const StyledMessageInputWrapper = styled.div``;

export const StyledMessageFlexWrapper = styled.div<{
  $isSignatureOn?: boolean;
  $hasMedia?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.$isSignatureOn || props.$hasMedia ? "column" : "row"};
  padding: 0.5rem;
  background-color: #ffffff;
  min-height: 120px;
`;

export const StyledMessageInput = styled.div`
  border: 1px solid var(--White-300, #e1e4ea) !important;
  border-radius: 0.25rem;

  position: relative;
  overflow: hidden;
`;

export const StyledTextareaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &:nth-child(2) {
    margin-left: 9px;
    padding: 0 9px;
    border-left: 1px solid ${({ theme }) => theme.palette.border.main};
  }
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  border: 0;

  resize: none;

  ${STYLE_1};
  ${SIZE_SM_PLUS};
  color: ${colors.grey[900]};

  min-height: 8rem;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.grey[500]};
  }
`;

export const StyledSubmitButton = styled.button<{
  $active: boolean;
  $isSignatureOn?: boolean;
}>`
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  align-self: ${(props) => (props.$isSignatureOn ? "unset" : "flex-end")};

  svg {
    path {
      fill: ${({ theme, $active }) =>
        $active ? theme.palette.primary.main : theme.palette.greyblue[205]};
    }
  }
`;

export const StyledMessageInputToolbar = styled.div`
  background-color: ${({ theme }) => theme.palette.greyblue[245]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
  padding: 0.25rem;

  display: flex;
  gap: 0.25rem;
`;

export const StyledToolbarButton = styled.button<{ disabled?: boolean }>`
  border: 0;
  background: none;
  padding: 0.25rem;
  margin: 0;
  color: ${({ theme }) => theme.palette.grey[600]};

  ${STYLE_2};
  ${SIZE_SM};

  display: flex;
  align-items: center;
  gap: 0.15rem;

  svg {
    height: 1rem;
    width: auto;
  }

  &:hover {
    cursor: pointer;
  }

  ${({ disabled }) => disabled && `opacity: 0.5;`}
`;

export const StyledFileInput = styled.input`
  display: none;
`;

export const StyledSelectTemplateWrapper = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

export const StyledTextareas = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledToolbarInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  align-self: flex-end;
  border: 0;
  background: none;
  padding: 0.25rem;
  margin: 0;
  margin-left: auto;
  color: ${({ theme }) => theme.palette.common.blackTransparent};

  ${STYLE_2};
  ${SIZE_SM};

  gap: 0.15rem;

  span {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  svg {
    height: 1rem;
    width: auto;
    margin-bottom: -2px;
  }
`;

export const StyledInputBottom = styled.div<{
  $isSignatureOn?: boolean;
  $hasMedia?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => (props.$hasMedia ? "row-reverse" : "row")};
  justify-content: ${(props) =>
    props.$isSignatureOn && !props.$hasMedia ? "flex-end" : "space-between"};
  align-items: ${(props) => (props.$hasMedia ? "center" : "flex-end")};

  ${(props) =>
    props.$isSignatureOn &&
    css`
      @media ${mediaQueries.lg} {
        flex-direction: column-reverse;
      }
    `}
`;

export const StyledSubmitBlock = styled.div<{ $isSignatureOn?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-top: ${(props) => (props.$isSignatureOn ? "5px" : "0")};

  ${(props) =>
    props.$isSignatureOn &&
    css`
      @media ${mediaQueries.lg} {
        margin-top: 0.25rem;
        align-self: flex-end;
      }
    `}
`;

export const StyledSignatureShield = styled(StyledToolbarInfo)`
  align-self: unset;
  white-space: nowrap;
  margin-left: 0;
  padding: 8px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
`;
