import { ListItemText, MenuItem, Stack } from '@mui/material';
import {
	mdiDotsHorizontal,
	mdiPlusBoxMultipleOutline,
	mdiPrinterOutline,
	mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';

import { ButtonV2 } from '../ui';
import { StyledButtonMenu } from './email-display.styles';
import { useMenu } from '../../hooks';
import { colors } from '../../styles';
import { AttachmentButton } from '../attachment-button';
import { EmailActionTypeEnum } from './email-display';

import type { FileWithPreview } from '../attachment-button';
import type { Nullable } from '@heylog-app/shared/types';
import type { Dispatch, FC, SetStateAction } from 'react';

interface EmailDisplayFooterProps {
	onDelete: (onClose: () => void) => () => void;
	onPrint: (onClose: () => void) => () => void;
	onNewTask: (onClose: () => void) => () => void;
	onReply: () => void;
	onForward: () => void;
	setAttachments: Dispatch<SetStateAction<FileWithPreview[]>>;
	setAttachmentsError: Dispatch<SetStateAction<string[]>>;
	onSend: () => void;
	selectedAction: Nullable<EmailActionTypeEnum>;
	isLoadingSend: boolean;
}

export const EmailDisplayFooter: FC<EmailDisplayFooterProps> = ({
	onDelete,
	onNewTask,
	onPrint,
	onReply,
	onForward,
	setAttachments,
	setAttachmentsError,
	onSend,
	isLoadingSend,
	selectedAction,
}) => {
	const { t } = useTranslation();
	const menuControl = useMenu();

	const ReplyActionComponent = (
		<>
			<AttachmentButton
				onFileSelect={(files) => setAttachments((prev) => [...prev, ...files])}
				getOnValidationErrorText={setAttachmentsError}
				maxFileSizeMb={9}
			/>
			<ButtonV2 onClick={onSend} isLoading={isLoadingSend} $variant="dark" type="submit">
				{t('email.sendReply')}
			</ButtonV2>
		</>
	);

	const ForwardActionComponent = (
		<>
			<AttachmentButton
				onFileSelect={(files) => setAttachments((prev) => [...prev, ...files])}
				getOnValidationErrorText={setAttachmentsError}
				maxFileSizeMb={9}
			/>
			<ButtonV2 onClick={onSend} isLoading={isLoadingSend} $variant="dark" type="submit">
				{t('email.sendForward')}
			</ButtonV2>
		</>
	);

	const NullActionComponent = (
		<>
			<Stack direction="row" gap={2}>
				<ButtonV2 onClick={onReply} $padding="10px 16px" $variant="dark">
					{t('email.reply')}
				</ButtonV2>
				<ButtonV2 onClick={onForward} $padding="10px 16px" $variant="light2">
					{t('email.forward')}
				</ButtonV2>
			</Stack>
			<StyledButtonMenu
				renderChildren={(onClose) => (
					<div>
						<MenuItem onClick={onNewTask(onClose)}>
							<ListItemText>
								<Stack direction="row" alignItems="center" gap={1}>
									<Icon path={mdiPlusBoxMultipleOutline} size={1} />
									{t('email.newTask')}
								</Stack>
							</ListItemText>
						</MenuItem>
						<MenuItem onClick={onPrint(onClose)}>
							<ListItemText>
								<Stack direction="row" alignItems="center" gap={1}>
									<Icon path={mdiPrinterOutline} size={1} />
									{t('email.print')}
								</Stack>
							</ListItemText>
						</MenuItem>
						<MenuItem onClick={onDelete(onClose)}>
							<ListItemText>
								<Stack direction="row" alignItems="center" gap={1}>
									<Icon path={mdiTrashCanOutline} size={1} />
									{t('email.delete')}
								</Stack>
							</ListItemText>
						</MenuItem>
					</div>
				)}
				id="message-menu"
				control={menuControl}
				button={
					<div>
						<ButtonV2 $variant="light" $padding="8px">
							<Icon path={mdiDotsHorizontal} size={1} />
						</ButtonV2>
					</div>
				}
			/>
		</>
	);

	return (
		<Stack
			borderTop={`1px solid ${colors.border.main}`}
			padding="16px 8px"
			direction="row"
			justifyContent="space-between"
		>
			{selectedAction === EmailActionTypeEnum.REPLY && ReplyActionComponent}
			{selectedAction === EmailActionTypeEnum.FORWARD && ForwardActionComponent}
			{selectedAction === null && NullActionComponent}
		</Stack>
	);
};
