
import { ButtonV2, IconAttention, IconFire, IconHeylog, IconMessage, IconSMS2, IconWhatsApp2, ROUTES, SidebarSearch, useDebounce, useMenu } from '@heylog-app/frontend-lib/app';
import { mdiArchiveArrowDownOutline, mdiArrowLeft, mdiFilterOutline, mdiMenuDown, mdiMessagePlusOutline, mdiPaperclipPlus, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, InputAdornment, MenuItem, Stack } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState, type FC } from 'react';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { StyledGoBackLink, StyledListItemText, StyledMenuButton, StyledParagraph, StyledTextField, StyledTitle } from './header.styles';
import { useTranslation } from 'react-i18next';
import { ArchiveOutlined, MenuOutlined, PersonAddAltOutlined, SearchOutlined, DoneRounded, AccessTime, AccessTimeRounded } from '@mui/icons-material';

type StatusType = 'ARCHIVED' | 'ALL' | 'PENDING_ONBOARDING' | 'ACTIVE';
interface HeaderProps {
    setStatus: Dispatch<SetStateAction<StatusType>>;
    setSearchTerm: Dispatch<SetStateAction<string | undefined>>;
    headerTitle: string;
    newContactLabel: string;
    searchPlaceholder: string;
    handleNewContactClick: () => void;
    handleSearchCallback: (searchTerm: string) => void;
    setSearchParams: any;
    handleArchiveAll?: () => void;
    status?: string;
}

export const Header: FC<HeaderProps> = ({
    setStatus,
    setSearchTerm,
    headerTitle,
    newContactLabel,
    searchPlaceholder,
    handleNewContactClick,
    handleSearchCallback,
    setSearchParams,
    handleArchiveAll,
    status,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const handleSearch = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
        },
        [setSearchTerm],
    );


    return (
        <>	<Stack gap={1}>
            <Stack gap={2} direction="row" flexWrap="wrap" justifyContent="space-between">
                <Box sx={{ paddingBlock: '20px', display: 'flex', alignItems: 'center' }}>
                    <StyledTitle>{t(`${headerTitle}`)}</StyledTitle>
                </Box>
                <Stack gap={2} direction="row" alignItems="center" justifyContent="flex-end">
                    <StyledTextField
                        data-test="search-contacts-field"
                        type={'text'}
                        placeholder={t(`${searchPlaceholder}`)}
                        onChange={handleSearch}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <ButtonV2 onClick={() => { handleArchiveAll && handleArchiveAll() }} $variant="light2" $padding="11px">
                        <ArchiveOutlined style={{ fontSize: '23px' }} />
                    </ButtonV2>
                    <ButtonV2
                        $variant="dark"
                        onClick={handleNewContactClick}
                        style={{
                            display: 'flex',
                            alignItems: 'end',
                            gap: '8px',
                            borderRadius: '4px',
                        }}>
                        <PersonAddAltOutlined />
                        {t(`${newContactLabel}`)}
                    </ButtonV2>
                </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                <ButtonV2
                    onClick={() => { setStatus('ALL') }}
                    $variant="light2"
                    $padding="11px"
                    $height='45px'
                    $showActive={status === 'ALL'}
                    $width='45px'>
                    <MenuOutlined style={{ fontSize: '23px' }} />
                </ButtonV2>
                <ButtonV2 onClick={() => { setStatus('ACTIVE') }}
                    $showActive={status === 'ACTIVE'}
                    $variant="light2" $padding="11px" $height='45px' $width='45px'>
                    <DoneRounded style={{ fontSize: '23px' }} />
                </ButtonV2>
                <ButtonV2 onClick={() => { setStatus('PENDING_ONBOARDING') }}
                    $showActive={status === 'PENDING_ONBOARDING'}
                    $variant="light2" $padding="11px" $height='45px' $width='45px'>
                    <AccessTimeRounded style={{ fontSize: '23px' }} />
                </ButtonV2>
                <ButtonV2 onClick={() => { setStatus('ARCHIVED') }}
                    $showActive={status === 'ARCHIVED'}
                    $variant="light2" $padding="11px" $height='45px' $width='45px'>
                    <ArchiveOutlined style={{ fontSize: '23px' }} />
                </ButtonV2>
            </Stack>
            <StyledParagraph>
            </StyledParagraph>
        </Stack >
        </>
    );
};