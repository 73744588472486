import { Checkbox } from '@mui/material';
// import StarIcon from '@mui/icons-material/Star';
// import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { useEffect, useState } from 'react';

import {
	ConversationTypeEnum,
	type ConversationV2ResInterface,
} from '@heylog-app/shared/types';

import {
	useConversationV2,
	useConversationV2Actions,
	useConversationsV2,
	useSnackbar,
} from '../../hooks';
import { FlashSnackbar } from '../snackbars';
import { colors } from '../../styles';

import type { LabelResInterface } from '@heylog-app/shared/types';
import type { FC } from 'react';

interface TodoCheckboxPropsInterface {
	conversation: ConversationV2ResInterface;
	workspaceId: number;
	isShipmentPage?: boolean;
	uncheckedColor?: string;
}

const isTodoToggled = (labels: LabelResInterface[]) =>
	!!labels.find((label) => label.type === 'CONVERSATION_TODO' && label.value === 'Todo');

export const TodoCheckbox: FC<TodoCheckboxPropsInterface> = ({
	conversation,
	workspaceId,
	isShipmentPage,
	uncheckedColor,
}) => {
	const [isToggled, setIsToggled] = useState(isTodoToggled(conversation.labels));
	const [isLoading, setIsLoading] = useState(false);
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const { updateConversationsV2 } = useConversationsV2(
		isShipmentPage
			? [
					ConversationTypeEnum.EMAIL,
					ConversationTypeEnum.VIBER,
					ConversationTypeEnum.WHATSAPP,
			  ]
			: undefined,
	);
	const { updateConversationV2 } = useConversationV2(
		conversation.id,
		workspaceId.toString(),
	);

	const { toggleTodo } = useConversationV2Actions({
		id: conversation ? conversation.id : null,
		workspaceId: workspaceId.toString(),
	});
	const handleTodoChange = async () => {
		setIsLoading(true);
		const res = await toggleTodo();
		if (res?.error) openSnackbar('error', res?.error);
		setIsLoading(false);
		updateConversationV2();
		updateConversationsV2();
	};

	useEffect(() => {
		setIsToggled(isTodoToggled(conversation.labels));
	}, [conversation.labels]);

	return (
		<>
			<Checkbox
				disabled={isLoading}
				checked={isToggled}
				onChange={handleTodoChange}
				sx={{
					'&.Mui-checked': {
						color: colors.primary.main,
					},
				}}
			/>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
