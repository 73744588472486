import { useEffect, useMemo, useState } from 'react';
import {
	generatePath,
	matchPath,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
	ErrorBoundary,
	ROUTES,
	TodoCheckbox,
	useContact,
	useContacts,
	useConversationsV2,
	useDebounce,
	usePlugins,
	Can,
	useOrderActions,
	TabNavigationToggleButton,
	colors,
	useConversationGroup,
} from '@heylog-app/frontend-lib/app';
import { Actions, ConversationTypeEnum, OrderConnectionStatusEnum } from '@heylog-app/shared/types';

import { StyledToggleButtonGroup } from './contacts.styles';
import { useContactContext } from './contacts';

import type { FC, SyntheticEvent } from 'react';

export const ContactsDetailPageV2: FC = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const params = useParams();
	const { contactId, workspaceId = '', conversationId = '' } = params;
	const { FrontendV2Plugin } = usePlugins(workspaceId);
	const debouncedSearchTerm = useDebounce<string | undefined>('', 250);
	const { pathname } = location;

	const { converGroupData } = useConversationGroup();
	// Example of paths
	const paths = [
		ROUTES.CONTACTS.CHAT4,
	];

	const currentContact = useMemo(() => {
		if (!converGroupData) return null;
		return converGroupData?.find((contact: any) => Number(contact.id) === Number(conversationId));
	}, [converGroupData, conversationId]);
	const initialActiveTab =
		paths.find((path) => matchPath(path, pathname)) || ROUTES.CONTACTS.CHAT4;

	const [activeTab, setActiveTab] = useState(initialActiveTab);



	const handleTabClick = (event: SyntheticEvent, path: string) => {
		setActiveTab(path);
	};


	const navigate = useNavigate();
	const { contacts } = useContacts({ q: debouncedSearchTerm });

	useEffect(() => {
		if (matchPath(ROUTES.CONTACTS.DETAIL2, pathname) && converGroupData) {
			navigate(
				generatePath(ROUTES.CONTACTS.CHAT4, {
					...params,
					conversationId: converGroupData?.[0]?.id.toString() || '',
					contactId: converGroupData?.[0]?.id.toString() || '',
					activeTab: ConversationTypeEnum.HEYLOG_USER
				}),
			);
		}
	}, [params, converGroupData, navigate, pathname]);

	return (
		<>
			<StyledToggleButtonGroup
				color="primary"
				value={activeTab}
				exclusive
				onChange={handleTabClick}
				aria-label="Tab navigation"
			>
				<Stack
					sx={{
						fontWeight: 'bold',
						marginTop: '25px',
						marginLeft: '10px',
					}}
				>
					{currentContact && currentContact.firstName + ' ' + currentContact.lastName}
				</Stack>

				<Stack
					sx={{
						marginTop: '10px',
						flexDirection: 'row',
					}}
					marginLeft="auto"
				>
					<TabNavigationToggleButton
						dataTest="contact-information-menu"
						label="contacts.tabs.chat"
						url={generatePath(ROUTES.CONTACTS.CHAT4, {
							...params,
							conversationId: currentContact?.id.toString() || '',
							contactId: currentContact?.id.toString() || '',
							activeTab: ConversationTypeEnum.HEYLOG_USER,
						})}
					/>


				</Stack>

			</StyledToggleButtonGroup>
			<ErrorBoundary>
				<Outlet context={useContactContext()} />
			</ErrorBoundary>
		</>
	);
};
