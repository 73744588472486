import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

import { Conversation } from '@heylog-app/frontend-lib/app';
import { UserOrderStatusEnum } from '@heylog-app/shared/types';

import { useContactContext } from './contacts';

import type { FC } from 'react';
import { ConversationV3Heylog } from 'libs/frontend-lib/app/src/components/conversation/conversationV3Heylog';
import { useContactContextV2 } from './contactsv3';

export const ContactConversationPageHeylog: FC = () => {

	const { currentConvo } = useContactContextV2();

	return (
		<Box sx={{ height: '100%', overflow: 'hidden' }}>
			<ConversationV3Heylog
				shouldFocusOnRender={false}
				textareaWrapperStyles={{
					position: "sticky",
					bottom: "0",
					backgroundColor: "white",
				}}
				currentConvo={currentConvo}

			/>
		</Box>
	);
};
