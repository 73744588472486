import { useEffect, useRef, memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FlashSnackbar } from '../snackbars/flash-snackbar';
import {
	useSnackbar,
	useMessagesV2,
} from '../../hooks';
import {
	StyledConversationMessages,
	StyledConversation,

} from './conversation.styles';
import { useRealtimeConversationMessageHook } from './hooks';
import { sortByCreatedAtAsc } from '../../util';

import {
	NUMBER_OF_ITEMS_TO_DISPLAY,
	NUMBER_OF_ITEMS_TO_FETCH,
	THRESHOLD_TO_FETCH_NEW_ITEMS,
} from './comversation-constants';

import type { ChatAppDialogPayloadInterface } from '../../providers';
import type {
	OrderResInterface,
	ContactStatusType,
} from '@heylog-app/shared/types';
import type { CSSProperties, FC } from 'react';
import { MessageV2 } from './messageV2';
import { MessageInputRendererV2 } from './message-input-rendererV2';

type ConversationProps = {
	// the order context is used when the conversation is viewed inside an order
	order?: OrderResInterface;
	// the contact order context is used when a conversation in the contacts view with an assigned order
	contactFilter?: ContactStatusType;

	// Optional prop for using data from parent component instead of useParams
	fetchData?: ChatAppDialogPayloadInterface;
	textareaWrapperStyles?: CSSProperties;
	shouldFocusOnRender?: boolean;
};

export const ConversationV2: FC<ConversationProps> = memo(
	({
		order,
		contactFilter,
		fetchData,
		textareaWrapperStyles,
		shouldFocusOnRender,
	}) => {
		const params = useParams();
		const conversationV2Id = fetchData?.conversationV2Id || '';
		const workspaceId = fetchData?.workspaceId || params['workspaceId'] || '';
		const orderId = fetchData ? fetchData?.orderId || '' : params['orderId'] || '';

		const [numberOfItemsToDisplay, setNumberOfItemsToDisplay] = useState(
			NUMBER_OF_ITEMS_TO_DISPLAY,
		);

		const [realDate, setRealDate] = useState<Date | undefined>(undefined);
		const [latestMessageDate, setLatestMessageDate] = useState<Date | undefined>(
			undefined,
		);
		const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
		const [messagesDateFrom, setMessagesDateFrom] = useState<Date | undefined>(undefined);
		const [allMessages, setAllMessages] = useState<
			any
		>([]);
		const hasMoreItemsToDisplay = allMessages.length > numberOfItemsToDisplay;

		const {
			messages: messagesV2,
			updateMessages: updateMessagesV2,
			isLoadingMessages: isLoadingMessagesV2,
			isValidatingMessages: isValidatingMessagesV2,
		} = useMessagesV2({
			conversationV2Id: conversationV2Id.toString(),
			workspaceId: workspaceId.toString(),
			messagesToFetch: NUMBER_OF_ITEMS_TO_FETCH,
			messagesDateFrom,
		});
		useRealtimeConversationMessageHook({ updateMessagesV2 })

		const scrollToBottom = () => {
			if (messageContainerRef.current) {
				messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
			}
		};

		useEffect(() => {
			setLatestMessageDate(allMessages[allMessages.length - 1]?.createdAt);
		}, [allMessages]);

		useEffect(() => {
			if (latestMessageDate) {
				scrollToBottom();
			}
		}, [latestMessageDate]);

		useEffect(() => {
			setAllMessages([]);
			setNumberOfItemsToDisplay(NUMBER_OF_ITEMS_TO_DISPLAY);
			setMessagesDateFrom(undefined);
			setRealDate(undefined);
		}, [params]);

		useEffect(() => {
			// i need to extract the V1 messages from v2 messages
			const messagesV2toV1 = messagesV2?.map(
				(message: any) => {
					return { ...message, transportMessage: { ...message?.transportMessage, }, contact: message?.transportMessage?.sender, text: message?.transportMessage?.text }
				});
			const messagesFromV1orV2 = messagesV2toV1;
			if (
				isLoadingMessagesV2 ||
				isValidatingMessagesV2
			) {
				return;
			}

			setAllMessages((oldMessages: any) => {
				const merged = [
					...(messagesFromV1orV2 || []), // pagination
					...oldMessages,
				];
				const notEmptyEvents = merged?.filter((event) => {
					if ('eventType' in event) {
						return (
							event?.eventType !== 'TIMEMATTERS_ORDER_STATUS_CHANGED' &&
							event?.eventType !== 'POD_PENDING'
						);
					}
					return true;
				});

				const mergedWithEventsFilteredByOrder = orderId
					? merged.filter((event) => {
						if ('eventType' in event) {
							return (
								(event.contextType === 'ORDER_EVENT' &&
									event.eventType === 'ORDER_STATUS_CHANGED' &&
									event.externalID.toString() === orderId.toString()) ||
								(event.contextType === 'CONTACT_EVENT' &&
									event.eventType === 'CONTACT_ORDER_ASSIGNMENT_CHANGED' &&
									event?.eventPayload?.orderId === orderId)
							);
						}
						return true;
					})
					: notEmptyEvents;

				const uniqueMap = new Map(
					mergedWithEventsFilteredByOrder.map((item) => [item.id, item]),
				);
				const unique = Array.from(uniqueMap.values());

				// Sort by date (oldest first)
				const sorted = unique.sort(sortByCreatedAtAsc);
				return sorted;
			});
			const oldestMessageDate = messagesFromV1orV2 && messagesFromV1orV2[0]?.createdAt;
			if (oldestMessageDate) {
				setRealDate(new Date(oldestMessageDate));
			}
		}, [
			conversationV2Id,
			messagesV2,
			orderId,
			isLoadingMessagesV2,
			isValidatingMessagesV2,
		]);

		const displayMoreItems = () => {
			if (!hasMoreItemsToDisplay) {
				return;
			} else {
				setNumberOfItemsToDisplay((prev) => prev + NUMBER_OF_ITEMS_TO_DISPLAY);
			}
		};

		const loadMoreItems = () => {
			// if the last batch of messages that we fetched is smaller than a page, it means we reached the end
			const messagesForCheck = messagesV2;
			const moreToLoad =
				messagesForCheck && messagesForCheck.length === NUMBER_OF_ITEMS_TO_FETCH;

			// if we are THRESHOLD_TO_FETCH_NEW_ITEMS away from the end, we fetch again
			const lessThanThreshold =
				allMessages.length - numberOfItemsToDisplay < THRESHOLD_TO_FETCH_NEW_ITEMS;

			const needsToLoadMoreItems = lessThanThreshold && moreToLoad;

			if (needsToLoadMoreItems) {
				setMessagesDateFrom(realDate);
			}
		};

		const messageContainerRef = useRef<HTMLDivElement>(null);

		const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
			const scrollTop = e.currentTarget.scrollTop;
			if (scrollTop === 0) {
				const prevScrollTop = messageContainerRef.current?.scrollTop || 0;
				const prevScrollHeight = messageContainerRef.current?.scrollHeight || 0;

				displayMoreItems();
				loadMoreItems();

				setTimeout(() => {
					const newScrollHeight = messageContainerRef.current?.scrollHeight || 0;
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					messageContainerRef.current!.scrollTop =
						prevScrollTop + (newScrollHeight - prevScrollHeight);
				}, 0);
			}
		};





		return (
			<>
				<StyledConversation data-test="conversation-wrapper">
					<StyledConversationMessages
						data-test="conversation-messages"
						onScroll={handleScroll}
						ref={messageContainerRef}
					>
						{allMessages.slice(-numberOfItemsToDisplay)?.map((msgOrEvent: any) => {
							if (!msgOrEvent.id) {
								console.warn(`msgOrEvent.id is undefined`);
								return null;
							}

							return (
								<MessageV2
									key={`message-${msgOrEvent.id}`}
									message={msgOrEvent}
								/>
							);
						})}
					</StyledConversationMessages>
					<div style={textareaWrapperStyles}>
						<>
							{allMessages?.length ? (
								<>
									<MessageInputRendererV2
										conversationId={conversationV2Id.toString()}
										shouldFocusOnRender={shouldFocusOnRender}
										contactId={"0"}
									/>
								</>
							) : null}
						</>
					</div>
				</StyledConversation>
				<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
			</>
		);
	},
);
