import React, { useRef } from 'react';
import Icon from '@mdi/react';
import { mdiPaperclipPlus } from '@mdi/js';

import { getFileTypeString } from '@heylog-app/shared/util';
import { FileTypeEnum } from '@heylog-app/shared/types';

import { ButtonV2 } from '../ui';

import type { FileType } from '@heylog-app/shared/types';

export interface FileWithPreview extends File {
	src: string;
}

interface AttachmentButtonProps {
	onFileSelect: (files: FileWithPreview[]) => void;
	getOnValidationErrorText?: (message: string[]) => void;
	maxFileSizeMb: number;
	allowedFormats?: FileType[];
}

const allowedFormatsFallback = [
	FileTypeEnum.BMP,
	FileTypeEnum.CSV,
	FileTypeEnum.DOCX,
	FileTypeEnum.GIF,
	FileTypeEnum.HTML,
	FileTypeEnum.JPEG,
	FileTypeEnum.JPG,
	FileTypeEnum.PDF,
	FileTypeEnum.PNG,
	FileTypeEnum.PPTX,
	FileTypeEnum.RTF,
	FileTypeEnum.SVG,
	FileTypeEnum.TIFF,
	FileTypeEnum.TXT,
	FileTypeEnum.WEBP,
	FileTypeEnum.XLSX,
	FileTypeEnum.XML,
];

export const AttachmentButton: React.FC<AttachmentButtonProps> = ({
	onFileSelect,
	getOnValidationErrorText,
	maxFileSizeMb,
	allowedFormats = allowedFormatsFallback,
}) => {
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const validateFormat = (file: File): boolean => {
		const allowedFormatsSet = new Set(allowedFormats);
		return allowedFormatsSet.has(file.type as FileType);
	};

	const validateSize = (file: File): boolean => {
		const maxSizeInBytes = maxFileSizeMb * 1024 * 1024; // Example maximum size (10 MB)
		return file.size <= maxSizeInBytes;
	};

	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const filesArr = Array.from(event.target.files) as FileWithPreview[];

			const errorMessageArr: string[] = [];

			filesArr.forEach((file) => {
				if (!validateFormat(file))
					errorMessageArr.push(
						`${file.name}: Invalid file format. Allowed: ${allowedFormats.map(
							(format) => ` ${getFileTypeString(format)}`,
						)}`,
					);
				if (!validateSize(file))
					errorMessageArr.push(
						`${file.name}: File size exceeds the maximum allowed size. Please upload a file smaller than ${maxFileSizeMb} MB.`,
					);
			});

			const validFiles = filesArr.filter(
				(file) => validateFormat(file) && validateSize(file),
			);

			validFiles.forEach((file, idx) => {
				const fileObj = filesArr[idx];
				if (fileObj) fileObj.src = URL.createObjectURL(file);
			});

			if (getOnValidationErrorText) getOnValidationErrorText(errorMessageArr);
			onFileSelect(validFiles);
		}
	};

	return (
		<div>
			<input
				type="file"
				ref={fileInputRef}
				style={{ display: 'none' }}
				onChange={handleChange}
			/>
			<ButtonV2 onClick={handleClick} $variant="light2" $padding="8px">
				<Icon path={mdiPaperclipPlus} size={1} />
			</ButtonV2>
		</div>
	);
};
