import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Grid, Stack, Tabs } from '@mui/material';
import { useState, type FC, useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { archiveConversationsAPI, ButtonV2, EmailDialogActionEnum, FlashSnackbar, MessageContext, MessageDialogTypesEnum, ROUTES, TableFilterSidebar, TabPanel, useApiClientContext, useConversationV2Actions, useDebounce, useElementHeight, useMessagesData, useMessagesDataTableFilters, useOrderActions, useSnackbar, useTableColumnsSettings, useWsChannelEvent } from '@heylog-app/frontend-lib/app';


import { GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { StyledTab } from './message-data-header.styled';
import { MessageHeader } from './messages-header/messages-header';
import Icon from '@mdi/react';
import { mdiCheck, mdiClockTimeFourOutline, mdiViewHeadline } from '@mdi/js';
import { MessagesDataGrid } from './messages-data-grid';

export const MessagesListTable: FC = () => {
    const [selectedConversations, setSelectedConversations] = useState<number[]>([])
    const [rowClick, setRowClick] = useState<GridRowParams>()
    const [paginationModel, setPaginationModel] = useState<any>({
        page: 1,
        pageSize: 10,
    })
    const [value, setValue] = useState("All");
    const [searchType, setSearchType] = useState<string | undefined>("ACTIVE");
    const topSectionRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const { apiClient } = useApiClientContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const navParams = useParams();
    const { workspaceId } = navParams;
    const apiRef = useGridApiRef();
    const { onOpenMessageDialog } = useContext(MessageContext);
    const [loader, setLoader] = useState<boolean>(false)
    const { toggleTodo } = useConversationV2Actions({
        id: null,
        workspaceId: workspaceId?.toString() || "",
    });
    const handleTodoChange = async (params: GridRowParams, value: boolean) => {
        setLoader(true)
        const res = await toggleTodo(Number(params?.id));
        if (res?.error) {
            setLoader(false)
            openSnackbar('error', res?.error)
        };
        updateConversationsV2()
        setLoader(false)

    };
    const [searchTerm, setSearchTerm] = useState<string>()
    const debouncedSearchTerm = useDebounce<string | undefined>(searchTerm, 250);

    const { columns, rows, updateConversationsV2, pagination } = useMessagesData([], handleTodoChange, paginationModel, debouncedSearchTerm, value, searchType);
    // const { FiltersComponent, activeFiltersCount, filteredRows } =
    //     useMessagesDataTableFilters(rows);
    useEffect(() => {
        updateConversationsV2()
    }, [paginationModel?.pageNumber, paginationModel?.pageSize])
    const {
        adjustedColumns,
        handleColumnResize,
        handleColumnOrderChange,
        handleColumnVisibilityModelChange,
    } = useTableColumnsSettings({
        columns,
        apiRef,
        localStorageName: 'messages-data-table',
    });
    const handleRowClick = (params: GridRowParams) => {
        setRowClick(params)
    }
    const onChangePage = (props: any) => {
        setPaginationModel({ pageNumber: props.page + 1, pageSize: props.pageSize })
        setSearchParams({ pageNumber: props.page + 1, pageSize: props.pageSize }, { replace: true })
    }

    useEffect(() => {
        if (debouncedSearchTerm) {
            setSearchParams({ q: debouncedSearchTerm }, { replace: true });
        } else {
            setSearchParams({}, { replace: true });
        }
    }, [debouncedSearchTerm, setSearchParams]);
    const handleNewEmailClick = () => {
        if (onOpenMessageDialog)
            onOpenMessageDialog({
                type: MessageDialogTypesEnum.EMAIL,
                action: EmailDialogActionEnum.NEW,
                payload: {},
            });
    };
    const handleHeylogClick = () => {
        navigate(generatePath(ROUTES.CONVERSATIONS.NEW, navParams));
    }


    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleRowSelectionChange = (records: string[]) => {
        setSelectedConversations((records?.map((record) => Number(record))))
    }
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
    const handleArchiveAll = async () => {
        if (!workspaceId && selectedConversations?.length <= 0) return;
        setLoader(true)
        await archiveConversationsAPI(apiClient, {
            data: {
                conversationIds: selectedConversations
            },
            workspaceId: workspaceId || "",
        })
            .then(() => {
                setLoader(false)
                openSnackbar('success', t('chat.archive'));
                updateConversationsV2()
            })
            .catch((e) => {
                setLoader(false)

            })
    }
    return (

        <Grid wrap="nowrap" columns={{ xs: 12 }} sx={{ height: '100%', width: "100%", padding: "16px" }}>
            <Stack direction="column" sx={{ height: '100%', width: "100%" }} gap={1}>
                <div ref={topSectionRef}>
                    <Stack direction="column">
                        <MessageHeader
                            handleHeylogClick={handleHeylogClick}
                            handleNewEmailClick={handleNewEmailClick}
                            setIsSearching={setIsSearching}
                            handleArchiveAll={handleArchiveAll}
                            setSearchTerm={setSearchTerm}
                            setSearchParams={setSearchParams}
                        />
                        <Stack direction={"row"} justifyContent={"space-between"}>

                            <Tabs
                                value={value}
                                onChange={handleChange}
                                sx={{ width: '100%' }}
                            >
                                <StyledTab label={t('messages.all')} value={"All"} />
                                <StyledTab label={t('messages.heylog')} value={"Heylog"} />
                                <StyledTab label={t('messages.whatsapp')} value={"Whatsapp"} />
                                <StyledTab label={t('messages.email')} value={"Email"} />
                            </Tabs>

                            <Stack direction={"row"} alignItems="center" justifyContent="flex-end">
                                <ButtonV2 onClick={() => {
                                    setSearchType("")
                                }} $variant="light2"
                                    $showActive={searchType === ""}
                                    $padding="11px" $height='45px' $width='45px'>
                                    <Icon path={mdiViewHeadline} size={1} />
                                </ButtonV2>
                                <ButtonV2 onClick={() => {
                                    setSearchType("ACTIVE")
                                }}
                                    $showActive={searchType === "ACTIVE"}
                                    $variant="light2" $padding="11px" $height='45px' $width='45px'>
                                    <Icon path={mdiCheck} size={1} />
                                </ButtonV2>
                                <ButtonV2 onClick={() => {
                                    setSearchType("ARCHIVED")
                                }}
                                    $showActive={searchType === "ARCHIVED"}
                                    $variant="light2" $padding="11px" $height='45px' $width='45px'>
                                    <Icon path={mdiClockTimeFourOutline} size={1} />
                                </ButtonV2>
                            </Stack>
                        </Stack>
                    </Stack>
                </div>
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        height: '100%',
                    }}
                >
                    <MessagesDataGrid
                        rows={rows}
                        columns={adjustedColumns}
                        handleRowClicks={handleRowClick}
                        apiRef={apiRef}
                        rowCount={pagination?.totalRows}
                        loader={loader}
                        handleRowSelectionChange={handleRowSelectionChange}
                        checkboxSelection={true}
                        onPageChange={onChangePage} // Callback to fetch new data for the given page
                        handleColumnOrderChange={handleColumnOrderChange}
                        handleColumnResize={handleColumnResize}
                        handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                        activeTab={value}
                    />
                </Stack>
            </Stack>
            <FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

        </Grid>
    );
};
