import useSWR, { useSWRConfig } from 'swr';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

import { getFetcher, toggleTodoConversationV2API } from '../util';
import { useApiClientContext } from './use-api-client-context.hook';
// import { AbilityContext } from '../providers';
import {
	getConversationEmailsKey,
	getConversationsV2Key,
	getConversationV2Key,
} from '../util/get-conversationv2-key';

import type {
	ConversationType,
	ConversationV2ResInterface,
	ConversationV2ResInterfaceNew,
	MessageEmailResInterface,
	Nullable,
} from '@heylog-app/shared/types';
import type { UseConversationHookPayload } from '../types/use-conversationv2.type';

export const useConversationsV2 = (
	types?: ConversationType[],
	page?: number,
	pageSize?: number,
	searchTerm?: string,
	tabName?: string,
	searchType?: string,
): {
	conversations?: ConversationV2ResInterfaceNew;
	conversationsError?: Error;
	updateConversationsV2: () => Promise<ConversationV2ResInterfaceNew | undefined>;
} => {
	const { apiClient } = useApiClientContext();
	const { workspaceId = '', shipmentId } = useParams();

	const conversationsV2Key = getConversationsV2Key(workspaceId, shipmentId, types, page, pageSize, searchTerm, tabName,searchType);

	const {
		data: conversations,
		error: conversationsError,
		mutate,
	} = useSWR<ConversationV2ResInterfaceNew>(conversationsV2Key, getFetcher(apiClient));

	const updateConversationsV2 = useCallback(() => mutate(), [mutate]);

	return {
		conversations,
		conversationsError,
		updateConversationsV2,
	};
};

export const useConversationV2 = (id: Nullable<number>, workspaceId: string) => {
	const { apiClient } = useApiClientContext();

	const conversationV2Key = id ? getConversationV2Key(workspaceId, id.toString()) : null;

	const {
		data: conversation,
		error: conversationError,
		isLoading,
		mutate,
	} = useSWR<ConversationV2ResInterface>(conversationV2Key, getFetcher(apiClient));

	const updateConversationV2 = useCallback(() => mutate(), [mutate]);

	return {
		conversation,
		conversationError,
		conversationKey: conversationV2Key,
		mutateConversation: mutate,
		updateConversationV2,
		isLoading,
	};
};

export const useConversationV2Email = (
	conversationPayload: UseConversationHookPayload,
): {
	emails?: MessageEmailResInterface[];
	emailsError?: Error;
} => {
	const { apiClient } = useApiClientContext();
	const { workspaceId = '', conversationId = '' } = conversationPayload;

	const conversationEmailsV2Key = getConversationEmailsKey(workspaceId, conversationId);

	const { data: emails, error: emailsError } = useSWR<MessageEmailResInterface[]>(
		conversationEmailsV2Key,
		getFetcher(apiClient),
	);

	return {
		emails,
		emailsError,
	};
};

interface UseConversationV2ActionsHookArgs {
	id: Nullable<number>;
	workspaceId: string;
	shipmentId?: string;
	types?: ConversationType[];
}

export const useConversationV2Actions = ({
	id,
	workspaceId,
	shipmentId,
	types,
}: UseConversationV2ActionsHookArgs) => {
	const { apiClient } = useApiClientContext();
	const { mutate: globalMutate } = useSWRConfig();

	const { mutateConversation } = useConversationV2(id, workspaceId);

	const linkOrderToEmailConversation = useCallback(
		async ({ orderId }: { orderId: string | null }) => {
			if (!id) return;
			if (!workspaceId) return;
			if (!orderId) return;

			const responseInfo = {
				success: true,
				error: '',
			};

			try {
				await apiClient.post(
					`workspaces/${workspaceId}/conversations-v2/${id}/link-order`,
					{
						orderId,
					},
				);
				const conversationRes = mutateConversation();
				const conversationsRes = globalMutate(
					getConversationsV2Key(workspaceId, shipmentId, types),
				);

				await Promise.all([conversationRes, conversationsRes]);

				return responseInfo;
			} catch (e) {
				responseInfo.success = false;
				responseInfo.error = 'Order link was not successfull';

				return responseInfo;
			}
		},
		[apiClient, globalMutate, id, mutateConversation, shipmentId, types, workspaceId],
	);

	const unlinkOrderFromEmailConversation = useCallback(async () => {
		if (!id) return;
		if (!workspaceId) return;

		const responseInfo = {
			success: true,
			error: '',
		};

		try {
			await apiClient.post(
				`workspaces/${workspaceId}/conversations-v2/${id}/unlink-order`,
			);
			const conversationRes = mutateConversation();
			const conversationsRes = globalMutate(
				getConversationsV2Key(workspaceId, shipmentId, types),
			);

			await Promise.all([conversationRes, conversationsRes]);

			return responseInfo;
		} catch (e) {
			responseInfo.success = false;
			responseInfo.error = 'Order unlink was not successfull';

			return responseInfo;
		}
	}, [apiClient, globalMutate, id, mutateConversation, workspaceId, types, shipmentId]);

	// const { conversation, mutateConversation } = useConversationV2(id, workspaceId);
	// I should take care of mutating the conversation in the cache here, but
	// 1. the endpoint doesn't return me an entity
	// 2. I do not have time to do it now
	// instead i simply handle the todo boolean with useState, and know that the data tables wikl refetch when displayed
	const toggleTodo = useCallback(async (conId?: number) => {
		if (!id && !conId) return;
		if (!workspaceId) return;
		const responseInfo = {
			success: true,
			error: '',
		};

		try {
			await toggleTodoConversationV2API(apiClient, { id: id?.toString() || conId?.toString() || "", workspaceId });
			return responseInfo;
		} catch (e) {
			responseInfo.success = false;
			responseInfo.error = 'Todo toggle was not successfull';

			return responseInfo;
		}
	}, [apiClient, id, workspaceId]);
	return {
		linkOrderToEmailConversation,
		toggleTodo,
		unlinkOrderFromEmailConversation,
	};
};
