import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	ListItemIcon,
	Menu,
	TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Controller, useForm } from 'react-hook-form';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {
	ContactPageLayout,
	ETA_DATE_TIME_FORMAT,
	FlashSnackbar,
	getOrderStatus,
	ModalClose,
	StyledDialogActions,
	updateOrderStatus,
	useApiClientContext,
	useAuthContext,
	useDialog,
	useMenu,
	useSnackbar,
} from '@heylog-app/frontend-lib/app';
import { TimeMattersOrderStatusEnum } from '@heylog-app/shared/types';

import {
	StyledButton,
	StyledButtonContainer,
	StyledFieldContainer,
	StyledMenuItem,
	StyledPageContent,
	StyledTextWrapper,
	StyledTitleWrapper,
} from '../../styles/order-status.styles';
import { OrderStatusIcon } from '../../components/ui/order-status-icon';

import type { UpdateOrderParams } from '@heylog-app/frontend-lib/app';
import type {
	OrderStatusUpdateReqInterface,
	ContactOrderStatus,
	TimeMattersOrderStatus,
} from '@heylog-app/shared/types';
import type { FC } from 'react';

interface FormData {
	updatedAtDate: string;
	parcelQuantity?: string;
	pickupName?: string;
	consigneeName?: string;
}

export const OrderStatusUpdateByReferencePage: FC = () => {
	const { apiClient } = useApiClientContext();
	const {
		workspaceId = '',
		orderId = '',
		whatsappTransportNumber = '',
		orderReference = '',
	} = useParams();
	const [searchParams] = useSearchParams();

	const { setAppToken } = useAuthContext();

	const bearerParam = searchParams.get('bearer');

	useEffect(() => {
		if (bearerParam) {
			setAppToken(bearerParam);
		}
	}, [bearerParam, setAppToken]);

	const [orderStatus, setOrderStatus] = useState<TimeMattersOrderStatus>();
	const [orderRef, setOrderRef] = useState<string>();
	const { isMenuOpen, toggleButtonRef, toggleMenu, closeMenu } = useMenu();
	const { t } = useTranslation();
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const [hasError, setHasError] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		getOrderStatus(apiClient, {
			workspaceId,
			orderId,
		})
			.then((res) => {
				setOrderStatus(res.data.currentStatus as TimeMattersOrderStatus);
				setOrderRef(res.data.refNumber);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [apiClient, orderId, workspaceId]);

	const { handleSubmit, control, register } = useForm<FormData>({
		defaultValues: {
			updatedAtDate: new Date().toISOString(),
			parcelQuantity: '',
			pickupName: '',
			consigneeName: '',
		},
	});

	const {
		showDialog: showConfirmCompleteDialog,
		openDialog: openConfirmCompleteDialog,
		closeDialog: closeConfirmCompleteDialog,
	} = useDialog();

	const handleSubmitClick = (formData: FormData) => {
		if (hasError) {
			setHasError(false);
		}

		if (orderStatus === TimeMattersOrderStatusEnum.DELIVERED) {
			openConfirmCompleteDialog();

			return;
		}

		if (!formData) return;

		handleSubmitComplete(formData);
	};

	const handleBackClick = () => {
		window.location.href = `https://wa.me/${whatsappTransportNumber}`;
	};

	const handleSubmitComplete = (formData: FormData) => {
		setIsSubmitting(true);

		const params: UpdateOrderParams<OrderStatusUpdateReqInterface> = {
			id: orderId,
			data: {
				status: orderStatus as ContactOrderStatus,
				customData: {
					timestamp: formData.updatedAtDate as string,
					parcelQuantity: formData.parcelQuantity || undefined,
					pickupName: formData.pickupName || undefined,
					consigneeName: formData.consigneeName || undefined,
				},
			},
			workspaceId,
		};

		updateOrderStatus(apiClient, params)
			.then(() => {
				openSnackbar('success', t('orders.osuSuccess', { orderRef }));
				setTimeout(() => {
					window.location.href = `https://wa.me/${whatsappTransportNumber}`;
				}, 2000);
			})
			.catch((err) => {
				openSnackbar('error', t('orders.osuError', { orderRef }));
				console.error(err);
			})
			.finally(() => {
				setIsSubmitting(false);
			});

		if (showConfirmCompleteDialog) {
			closeConfirmCompleteDialog();
		}
	};

	const handleStatusChange = (status: TimeMattersOrderStatus) => {
		setOrderStatus(status);
		closeMenu();
	};

	const statusLabel = useMemo(() => {
		return t(`orders.details.status.${orderStatus?.toLowerCase()}`);
	}, [orderStatus, t]);

	return (
		<ContactPageLayout>
			<StyledPageContent>
				<StyledTitleWrapper>
					<span role="img" aria-label="order-emoji">
						📍
					</span>
					{t('orderStatusUpdate.order')}: {orderReference}
				</StyledTitleWrapper>
				<StyledTextWrapper>
					<StyledButton
						data-test="order-status-menu-button"
						aria-controls={'order-status-menu'}
						aria-haspopup="true"
						aria-expanded={isMenuOpen ? 'true' : undefined}
						ref={toggleButtonRef}
						onClick={toggleMenu}
						variant="outlined"
						startIcon={orderStatus && <OrderStatusIcon status={orderStatus} />}
						endIcon={<KeyboardArrowDownIcon />}
						fullWidth
						$hasError={hasError}
					>
						{statusLabel || t('orders.details.status.none')}
					</StyledButton>

					<Menu
						id="order-status-menu"
						open={isMenuOpen}
						anchorEl={toggleButtonRef?.current}
						onClose={closeMenu}
						sx={{
							'& .MuiPaper-root': {
								width: (toggleButtonRef.current?.clientWidth || 0) + 2,
							},
						}}
					>
						{Object.values(TimeMattersOrderStatusEnum).map((status) => {
							return (
								<StyledMenuItem
									key={status}
									onClick={() => handleStatusChange(status)}
									data-test={`order-status-${status}`}
									sx={{ fontSize: '0.875rem' }}
								>
									<ListItemIcon>
										<OrderStatusIcon status={status} />
									</ListItemIcon>
									{t(`orders.details.status.${status.toLowerCase()}`)}
								</StyledMenuItem>
							);
						})}
					</Menu>
				</StyledTextWrapper>
				<form onSubmit={handleSubmit(handleSubmitClick)} noValidate>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<Controller
							control={control}
							name="updatedAtDate"
							render={({ field: { ...props } }) => (
								<FormControl margin="dense" fullWidth>
									<MobileDateTimePicker
										{...props}
										value={props.value || null}
										renderInput={(params) => <TextField {...params} />}
										inputFormat={ETA_DATE_TIME_FORMAT}
										label={t('orderStatusUpdate.Date')}
										ampm={false}
									/>
								</FormControl>
							)}
						/>
					</LocalizationProvider>

					{orderStatus === TimeMattersOrderStatusEnum.APPROACHING && (
						<StyledFieldContainer>
							<Controller
								control={control}
								name="parcelQuantity"
								render={({ field }) => (
									<TextField
										label={t('orderStatusUpdate.numberOfParcels')}
										fullWidth
										margin="dense"
										type="number"
										{...register('parcelQuantity', { required: false })}
										{...field}
									/>
								)}
							/>
							<Controller
								control={control}
								name="pickupName"
								render={({ field }) => (
									<TextField
										label={t('orderStatusUpdate.nameOfPickupPerson')}
										fullWidth
										margin="dense"
										{...register('pickupName', { required: false })}
										{...field}
									/>
								)}
							/>
						</StyledFieldContainer>
					)}

					{orderStatus === TimeMattersOrderStatusEnum.DELIVERED && (
						<StyledFieldContainer>
							<Controller
								control={control}
								name="consigneeName"
								render={({ field }) => (
									<TextField
										label={t('orderStatusUpdate.nameOfConsignee')}
										fullWidth
										margin="dense"
										{...register('consigneeName', { required: false })}
										{...field}
									/>
								)}
							/>
						</StyledFieldContainer>
					)}

					<StyledButtonContainer>
						<Button variant="outlined" onClick={handleBackClick} fullWidth size="large">
							{t('actionLabels.back')}
						</Button>
						<Button
							variant="contained"
							type="submit"
							fullWidth
							size="large"
							disabled={isSubmitting}
						>
							{t('actionLabels.confirm')}
						</Button>
					</StyledButtonContainer>

					<Dialog open={showConfirmCompleteDialog} onClose={closeConfirmCompleteDialog}>
						<ModalClose closeModalFn={closeConfirmCompleteDialog} />
						<DialogTitle id="complete-dialog-title">
							{t('orders.details.confirmComplete.title')}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="complete-dialog-description">
								{t('orders.details.confirmComplete.text')}
							</DialogContentText>
						</DialogContent>
						<StyledDialogActions>
							<Button variant="outlined" onClick={closeConfirmCompleteDialog}>
								{t('actionLabels.cancel')}
							</Button>
							<Button
								variant="contained"
								onClick={handleSubmit(handleSubmitComplete)}
								autoFocus
								data-test="confirm-complete-order-button"
							>
								{t('actionLabels.complete')}
							</Button>
						</StyledDialogActions>
					</Dialog>
				</form>
			</StyledPageContent>

			<FlashSnackbar
				controls={[stateSnackbar, openSnackbar, closeSnackbar]}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			/>
		</ContactPageLayout>
	);
};
