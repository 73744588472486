import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const ContactStatusEnum = mkenum({
	ACTIVE: 'ACTIVE',
	ARCHIVED: 'ARCHIVED',
	PENDING_ONBOARDING: 'PENDING_ONBOARDING',
	PENDING_ONBOARDING_TO_ORDER: 'PENDING_ONBOARDING_TO_ORDER',
});

export type ContactStatusType = TypeFromPEnum<typeof ContactStatusEnum>;

export const ContactStatusEnumV2 = mkenum({
	ACTIVE: 'ACTIVE',
	ARCHIVED: 'ARCHIVED',
	ALL: 'ALL',
	PENDING: 'PENDING_ONBOARDING',
});

export type ContactStatusTypeV2 = TypeFromPEnum<typeof ContactStatusEnumV2>;
