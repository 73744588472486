import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, Stack } from '@mui/material';
import Icon from '@mdi/react';
import { mdiAccount, mdiMenuDown, mdiTruckDeliveryOutline } from '@mdi/js';
import { useParams } from 'react-router-dom';

import {
	type ContactOrderTaskStatus,
	OrderTaskStatusEnum,
	type OrderTaskStatusUpdateReqInterface,
	OrderTaskTypeEnum,
	ConversationTypeEnum,
} from '@heylog-app/shared/types';

import { getFormattedEta, getNameInitials, updateOrderTaskStatus } from '../../util';
import {
	StyledLabel,
	StyledHeader,
	StyledAvatar,
	StyledSectionSomeElements,
	StyledSection,
	StatusLabel,
	StyledContentWrapper,
	StyledSectionBody,
	StyledContactInfo,
	StyledContactInfoData,
	Dropdown,
	DropdownContainer,
	DropdownOption,
	StyledCompanyName,
	StyledContent,
	StyledContent2,
	StyledSection2,
	StyledReferenceText,
	StyledSection3,
} from './task-details-dialog.styles';
import { IconUntrack } from '../icons/icon-untrack';
import { ButtonV2, ModalClose } from '../ui';
import { StyledDialogActions } from '../dialog';
import {
	useApiClientContext,
	useDialog,
	useMessagesData,
	useSnackbar,
} from '../../hooks';
import { FlashSnackbar } from '../snackbars';
import { MessageContext } from '../../providers/message-provider';
import { MessageDialogTypesEnum } from '../../providers';
import { ConfirmArchiveDialog } from '../confirm-archive-dialog';

import type { FC, Dispatch, SetStateAction } from 'react';
import type {
	OrderTaskResInterface,
	ContactResInterface,
} from '@heylog-app/shared/types';
import type { UpdateOrderTaskParams } from '../../types';
import { StyledStatusButton, StyledStatusMenu, StyledStatusMenu2, StyledStatusOption } from '../vertical-stepper/vertical-stepper.styles';

type TaskDetailsDialogProps = {
	onClose: () => void;
	isOpen: boolean;
	dialogTitle: string;
	dataTest?: string;
	deleteTask: (id: number) => void;
	orderTask: OrderTaskResInterface;
	customer: string;
	contacts?: ContactResInterface[];
	setStatusChanged: any;
};

export const TaskDetailsDialog: FC<TaskDetailsDialogProps> = ({
	contacts,
	customer,
	dataTest,
	deleteTask,
	dialogTitle,
	isOpen,
	onClose,
	orderTask,
	setStatusChanged,
}) => {
	const { t } = useTranslation();
	const { apiClient } = useApiClientContext();
	const { workspaceId = '', shipmentId = '' } = useParams();
	const [status, setStatus] = useState(orderTask.status.toUpperCase());
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const { rows } = useMessagesData([ConversationTypeEnum.WHATSAPP]);

	const { onOpenMessageDialog } = useContext(MessageContext);

	const data = rows.length > 0 ? rows[0] : null;
	const handleNavigateToNewMessage = () => {
		if (
			onOpenMessageDialog &&
			data &&
			data.conversationV1Id &&
			data.contactId &&
			shipmentId
		)
			onOpenMessageDialog({
				type: MessageDialogTypesEnum.CHAT_APP,
				payload: {
					workspaceId: Number(workspaceId),
					conversationV2Id: data.id,
					conversationId: Number(data.conversationV1Id),
					contactId: data.contactId,
					orderId: Number(shipmentId),
				},
			});

		// when we open the conversation dialog we close the task one since it doesn't belong to the same stack
		// it could be possible to keep both open, by sharing stacks, but it's a lot of work, overkill for now
		isOpen && onClose();
	};

	const handleLabelClick = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleOptionClick = (newStatus: ContactOrderTaskStatus) => {
		setStatus(newStatus);
		setIsDropdownOpen(false);

		if (newStatus !== status) {
			const params: UpdateOrderTaskParams<OrderTaskStatusUpdateReqInterface> = {
				data: { status: newStatus },
				orderId: shipmentId,
				taskId: orderTask.id.toString(),
				workspaceId,
			};

			updateOrderTaskStatus(apiClient, params)
				.then(() => {
					openSnackbar(
						'success',
						t('orders.tasks.tsuSuccess', {
							orderTaskRef: orderTask.customerReference,
						}),
					);
					if (setStatusChanged) {
						setStatusChanged({ status: newStatus, taskId: orderTask.id.toString() });
					}
				})
				.catch((err) => {
					openSnackbar(
						'error',
						t('orders.tasks.tsuError', {
							orderTaskRef: orderTask.customerReference,
						}),
					);
					console.error(err);
				});
		}
	};

	const handleOnClose = () => {
		setIsDropdownOpen(false);
		onClose();
	};

	useEffect(() => {
		setStatus(orderTask.status);
	}, [orderTask]);

	const onArchive = () => {
		deleteTask(orderTask.id);
		closeConfirmArchiveDialog();
		onClose();
	};

	const {
		showDialog: showConfirmArchiveDialog,
		openDialog: openConfirmArchiveDialog,
		closeDialog: closeConfirmArchiveDialog,
	} = useDialog();
	const statusOptions = [
		OrderTaskStatusEnum.COMPLETED,
		OrderTaskStatusEnum.PENDING,
		OrderTaskStatusEnum.CANCELLED,
		OrderTaskStatusEnum.PROBLEM,
		OrderTaskStatusEnum.IN_PROGRESS
	];

	return (
		<Dialog data-test={dataTest} open={isOpen} onClose={handleOnClose} sx={{
			'& .MuiDialog-paper': {
				width: '560px',
				height: '520px',
				maxWidth: '560px', // Prevent stretching beyond specified width
				maxHeight: '520px', // Prevent stretching beyond specified height
			},
		}}>
			<ModalClose closeModalFn={handleOnClose} />
			<DialogTitle id="alert-dialog-title">
				<span style={{ color: "#030B5E" }}>
					{dialogTitle} {orderTask.type === OrderTaskTypeEnum.LOADING
						? <>
							({t('orders.tasks.type.loading')})
						</>
						: <>
							({t('orders.tasks.type.unloading')})
						</>
					}
				</span>
			</DialogTitle>

			<StyledDialogActions>
				<StyledContentWrapper>
					<Stack direction="row" gap={"45%"} alignItems={"center"} marginBottom={"10px"}>
						{orderTask.customerReference && (
							<StyledSection>
								<Stack direction="column">
									<StyledLabel>{t('orders.taskDetailsDialog.reference')}</StyledLabel>
									<StyledReferenceText>{orderTask.customerReference}</StyledReferenceText>
								</Stack>
							</StyledSection>
						)}
						<Stack>

							<StyledStatusButton status={status} height='36px' width='136px' onClick={() => {
								handleLabelClick()
							}}>
								<Stack direction={'row'} alignItems={"center"} alignContent={"center"} gap={"22px"}>
									<span >{t(`orders.tasks.status.${status.toLowerCase()}`)} </span>
									<Icon path={mdiMenuDown} size={1} />
								</Stack>
							</StyledStatusButton>
							<DropdownContainer>
								{isDropdownOpen && (
									<StyledStatusMenu2>
										{statusOptions
											.filter((opt) => opt !== status) // Filter out the currently selected status
											.map((opt) => (
												<StyledStatusOption
													key={opt}
													status={opt}
													onClick={() => handleOptionClick(opt)}
												>
													{t(`orders.tasks.status.${opt.toLowerCase()}`)}
												</StyledStatusOption>
											))
										}
									</StyledStatusMenu2>
								)}
							</DropdownContainer>
						</Stack>
					</Stack>
					<Stack direction={"row"} gap={"40%"} marginBottom={"10px"}>
						{orderTask.etaFrom && (
							<Stack>
								<StyledLabel>{t('orders.taskDetailsDialog.dateFrom')}</StyledLabel>
								<StyledContent>{getFormattedEta(new Date(orderTask.etaFrom as Date))}</StyledContent>
							</Stack>
						)}
						{orderTask.etaTo && (
							<Stack>
								<StyledLabel>{t('orders.taskDetailsDialog.dateTo')}</StyledLabel>
								<StyledContent> {getFormattedEta(new Date(orderTask.etaTo as Date))}</StyledContent>
							</Stack>
						)}
					</Stack>
					<Stack marginBottom={"10px"}>

						{orderTask.location && (
							<div>
								<StyledLabel>{t('orders.taskDetailsDialog.address')}</StyledLabel>
								<StyledContent>{orderTask.location}</StyledContent>
							</div>
						)}
					</Stack>

					<div style={{
						borderTop: "1px solid gray",
						marginTop: "1rem",
						marginBottom: "1rem",
						width: "100%"
					}}></div>
					<StyledContent>Client Information</StyledContent>

					<Stack direction={"row"} gap={"50%"} marginTop={"10px"}>

						{orderTask.company && (
							<StyledSection2>
								<Stack direction="column">
									<StyledLabel>{t('orders.taskDetailsDialog.company')}</StyledLabel>
									<StyledContent2>{orderTask.company}</StyledContent2>
								</Stack>
							</StyledSection2>
						)}

						{true && (
							<StyledSection>
								<Stack>
									<StyledLabel>{'Email'}</StyledLabel>
									<StyledContent2>{"--"}</StyledContent2>
								</Stack>
							</StyledSection>
						)}
					</Stack>
					<Stack marginTop={"10px"} marginBottom={"10px"}>

						{contacts && contacts[0] && (
							<div >
								<Stack marginBottom={"10px"}>

									<StyledLabel>{t('orders.taskDetailsDialog.contactPerson')}</StyledLabel>
								</Stack>
								{contacts.map((contact, key) => (
									<StyledSectionBody key={key}>
										<StyledContactInfo>
											<StyledAvatar src="" sx={{ fontSize: '1rem' }}>
												{getNameInitials(contact.firstName, contact.lastName) || (
													<Icon path={mdiAccount} size={'0.5rem'} />
												)}
											</StyledAvatar>
											<StyledContactInfoData>
												{contact.firstName + '' + contact.lastName}
												<StyledLabel>{contact.phone}, whatsApp</StyledLabel>
											</StyledContactInfoData>
										</StyledContactInfo>

										<ButtonV2
											$variant={"outlined"}
											data-test="new-message-button"
											onClick={handleNavigateToNewMessage}
											$height={'32px'}
										>
											{t('orders.taskDetailsDialog.sendMessage')}
										</ButtonV2>
									</StyledSectionBody>
								))}
							</div>
						)}
					</Stack>
					<Stack direction={"row"} gap={"10px"} justifyContent={"end"} justifyItems={"end"} alignItems={"end"}>
						<StyledSection>
							<ButtonV2
								data-test="delete-task-button"
								$variant="outlined2"
								onClick={onClose}
								$height={'32px'}
							>
								{t('orders.taskDetailsDialog.cancel')}
							</ButtonV2>
						</StyledSection>
						<StyledSection>
							<ButtonV2
								data-test="delete-task-button"
								$variant="dark"
								onClick={openConfirmArchiveDialog}
								$height={'32px'}
							>
								{t('orders.taskDetailsDialog.delete')}
							</ButtonV2>
						</StyledSection>
					</Stack>
				</StyledContentWrapper>
			</StyledDialogActions>

			<ConfirmArchiveDialog
				dialogContent={t('orders.taskDetailsDialog.confirm_content')}
				dialogConfirmButtonText={t('orders.taskDetailsDialog.confirm_button')}
				dialogTitle={t('orders.taskDetailsDialog.confirm_title')}
				isOpen={showConfirmArchiveDialog}
				onArchive={onArchive}
				onClose={closeConfirmArchiveDialog}
			/>

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</Dialog>
	);
};
