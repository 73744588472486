import { forwardRef } from 'react';

import { StyledTextField } from './inputV2.styles';

import type { OutlinedInputProps, TextFieldProps } from '@mui/material';

export type InputV2Props = any & {
	$padding?: string;
};

export const InputV2 = forwardRef<HTMLDivElement, any>(
	({ $padding, ...props }, ref) => {
		return <StyledTextField {...props} $padding={$padding} ref={ref} />;
	},
);
