import styled from 'styled-components';
import { Avatar } from '@mui/material';

import { colors } from '../../styles';
import Image from './images/avatar-bg@2x.jpg';

import type { Theme } from '@mui/material/styles';

export const StyledContentWrapper = styled.div`
	width: 100%;
`;

export const StyledLabel = styled.div`
	font-size: 12px;
	color: ${colors.grey[600]};
`;

export const StyledCompanyName = styled.div`
	font-weight: bold; /* Make the text bold */
`;

export const StyledSectionSomeElements = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin-top: 15px;
`;

export const StyledSection = styled.div`
	max-width: 400px;
`;
export const StyledSection3 = styled.div`
	max-width: 400px;
	min-width: 200px;
`;
export const StyledSection2 = styled.div`
	max-width: 200px;
`;
export const StyledReferenceText = styled.div`
	color: ${colors.primary.dark};
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	letter-spacing: -0.2px;
	width: 100px;
`
export const StyledSectionTitle = styled.div`
	margin-top: 15px;
	font-size: 16px;
`;
export const StyledContent = styled.div`
	color: ${colors.primary.dark};
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
	letter-spacing: -0.3px;
`;
export const StyledContent2 = styled.div`
color: ${colors.primary.dark};
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
letter-spacing: -0.2px;
`
export const StyledSectionBody = styled.div`
	width: 100%;
	background-color: ${colors.grey[200]};
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	height: 60px;
	align-items: center;
`;

export const StyledHeader = styled.div`
	display: flex;
	flex-direction: row;
	background-color: ${colors.primary.lighter};
	color: ${({ theme }) => theme.palette.primary.darkV2};
	min-width: 145px;
	min-height: 48px;
	border-radius: 4px;
	margin-top: 5px;
	justify-content: center;
	align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
	background-image: url(${Image});
	background-size: cover;
`;

export const StyledContactInfo = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

export const StyledContactInfoData = styled.div`
	margin-top: 4px;
	margin-left: 4px;
	font-size: 14px;
`;

export const DropdownContainer = styled.div`
	position: relative;
`;

export const Dropdown = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	background-color: white;
	border: 1px solid #ccc;
	z-index: 1000;
`;

export const DropdownOption = styled.div`
	width: 100%;
	height: 100%;
	padding: 0.5px;
	cursor: pointer;
	&:hover {
		background-color: #f0f0f0;
	}
`;

export const StatusLabel = styled('div')<{
	theme: Theme;
	completed: boolean;
	active: boolean;
	cancelled?: boolean;
}>(({ theme, completed, active, cancelled }) => ({
	borderRadius: '4px',
	marginTop: '5px',
	backgroundColor: cancelled
		? theme.palette.warning.light
		: completed
			? theme.palette.success.lighter
			: active
				? theme.palette.primary.lighter
				: theme.palette.grey[300],
	color: cancelled
		? theme.palette.warning.main
		: completed
			? theme.palette.success.main
			: active
				? theme.palette.primary.main
				: theme.palette.grey[700],
	fontWeight: 500,
	minWidth: '145px',
	minHeight: '48px',
	width: '100%',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
}));
