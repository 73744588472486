import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    MenuItem,
    Tabs,
    TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Icon } from '@mdi/react';
import { mdiAccount, mdiContentCopy, mdiDownload, mdiFileDownload } from '@mdi/js';
import { QRCode } from 'react-qrcode-logo';

import { phoneRegex } from '@heylog-app/shared/util';
import {
    MessageDirectionEnum,
    deeplSupportedLanguages,
    TranslationServiceEnum,
    googleSupportedLanguages,
} from '@heylog-app/shared/types';

import logo from '../../../assets/Heylog-Icon-Transparent-M.png';
import {
    ButtonsGridContainer,
    StyledAvatar,
    StyledDecoration,
    StyledDialogContainer,
    StyledDialogFooter,
    StyledDownloadButton2,
    StyledDownloadButton,
    StyledInputDescription,
    StyledMessageGroupWrapper,
    StyledTab,
    StyledTabs,
    StyledTabsContainer,
    StyledDialogFooter2,
} from './add-contact-form.styles';
import {
    useApiClientContext,
    useDialog,
    useOrder,
    useSnackbar,
    useUser,
    useWorkspace,
} from '../../../hooks';
import { ButtonV2, ControlledSelect, ErrorMessage, MessageBubble, ModalClose } from '../../ui';
import {
    buildInviteMessage,
    cleanInviteMessage,
    getNameInitials,
    inviteContactAPI,
    parseAxiosErrorMessage,
    useErrorMessage,
} from '../../../util';
import { StyledMessageGroup } from '../../ui/message-group/message-group.styles';
import { StyledMessageAuthorText } from '../../ui/message-author/message-author.styles';
import { staticMockedMessage } from './static-mocked-message';
import { TabPanel } from './components/tab-panel';
import { FlashSnackbarV2 } from '../../snackbars/flash-snackbar';
import { StyledDialogActions } from '../edit-contact-form/edit-contact-form.styles';

import type { SelectChangeEvent } from '@mui/material';
import type { Control, FieldValues } from 'react-hook-form';
import type { FC } from 'react';
import type { FullContactResInterface } from '@heylog-app/shared/types';
import { FileDownload, FileDownloadOutlined } from '@mui/icons-material';

type FormData = {
    name: string;
    phone: string;
    chatLanguage: string;
};

type CreateContactFormProps = {
    contact?: FullContactResInterface; // provide ID if editing a contact
    onSuccess: () => void;
    onCancel: () => void;
};

export const AddContactFormV2: FC<CreateContactFormProps> = ({ contact, onSuccess, onCancel }) => {
    const { t } = useTranslation();
    const params = useParams();
    const { workspaceId = '', orderId, shipmentId } = params;
    const { order } = useOrder(orderId || shipmentId);
    const { user } = useUser();
    const { apiClient } = useApiClientContext();
    const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

    const { workspace } = useWorkspace(workspaceId, user);
    const defaultLanguageSelected = 'de';
    const [chatLanguage, setChatLanguage] = useState(defaultLanguageSelected);
    const [mockedMessageText, setMockedMessageText] = useState('');

    const supportedLanguages =
        workspace?.translationService === TranslationServiceEnum.DEEPL
            ? deeplSupportedLanguages
            : googleSupportedLanguages;
    const { errorMessage, setError } = useErrorMessage();


    const [tabIndex, setTabIndex] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };
    const [isLoading, setIsLoading] = useState(false);

    // TODO OSU add join link from order entity if there is an orderId
    const inviteLink = order?.orderJoinLink ? order?.orderJoinLink : workspace?.joinLink;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm<FormData>({
        defaultValues: {
            phone: '',
        },
    });

    useEffect(() => {
        setMockedMessageText(buildInviteMessage(user, workspace, chatLanguage));
    }, [chatLanguage, user, workspace]);

    useEffect(() => {
        if (contact) {
            reset({
                phone: contact?.phone || '',
            });
        }
    }, [contact, reset]);

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const onSubmitSendSMSForm = (data: FormData) => {
        submitSendSMSForm(data);
    };

    const submitSendSMSForm = (data: FormData) => {
        setIsLoading(true);
        inviteContactAPI(apiClient, {
            data: {
                phone: data.phone,
                body: cleanInviteMessage(mockedMessageText as string),
                language: chatLanguage,
                ...(orderId && { orderId: parseInt(orderId) }),
            },
            workspaceId,
        })
            .then(() => {
                openSnackbar('success', 'New Contact Successfully Invited');
                onSuccess();
            })
            .catch((err) => {
                openSnackbar('error', parseAxiosErrorMessage(err));
                setError(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCopyClick = () => {
        copyTextToClipboard(inviteLink || '')
            .then(() => {
                openSnackbar('success', t('contacts.form.linkIsCopied'));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const copyTextToClipboard = async (text: string) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    };

    const sortedLanguages = useMemo(() => {
        const supportedLanguagesMap = supportedLanguages.map((languageCode) => {
            return { code: languageCode, name: t(`languages.${languageCode}`) };
        });
        supportedLanguagesMap.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        return supportedLanguagesMap;
    }, [supportedLanguages, t]);

    const handleLanguageChange = useCallback(
        (event: SelectChangeEvent<unknown>) => setChatLanguage(event.target.value as string),
        [setChatLanguage],
    );

    const downloadQRCode = () => {
        const canvas = document.createElement('canvas');
        const qrCode = document.getElementById('qr-code') as HTMLCanvasElement;
        const context = canvas.getContext('2d');

        canvas.height = 650;
        canvas.width = 600;

        if (context) {
            context.imageSmoothingEnabled = false;
            context.drawImage(qrCode, 0, 0, 600, 600);
            context.fillStyle = 'black';
            context.font = 'bold 32px Arial';
            context.fillText(`${workspace?.name}`, 0, canvas.height - 10);
        }

        const pngUrl = canvas
            ?.toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');

        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `heylog-${workspace?.name}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    const onFocusPhoneField = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            if (event.target.value === '') {
                reset({ phone: '+' });
            }
        },
        [reset],
    );

    return (
        <>
        <FlashSnackbarV2 controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
        <StyledDialogContainer>
            <StyledTabsContainer>
                <StyledInputDescription>
                    {t('contacts.form.chooseMethodToInvite')}
                </StyledInputDescription>
                <Grid container spacing={1} style={{ marginTop: '10px' }}>
                    {/* <Grid item xs={4}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field }) => (
                                <TextField
                                    data-test="invite-with-name-field"
                                    label={t('contacts.form.labels.name')}
                                    fullWidth
                                    type="tel"
                                    error={!!errors.name}
                                    helperText={
                                        errors.name
                                            ? t(`forms.errors.${errors.name?.type ?? 'invalid'}`)
                                            : null
                                    }
                                    placeholder={'Full Name'}
                                    {...register('name', {
                                        required: true,
                                    })}
                                    {...field}
                                />
                            )}
                        />
                    </Grid> */}
                    <Grid item xs={4}>
                        <ControlledSelect
                            margin="none"
                            control={control as unknown as Control<FieldValues>}
                            label={t('contacts.form.labels.chatLanguage')}
                            id="chatLanguage"
                            rules={{ required: true }}
                            selectOptions={{
                                error: !!errors.chatLanguage,
                                onChange: handleLanguageChange,
                                value: chatLanguage,
                            }}
                            errorMessage={errors.chatLanguage && t('forms.errors.required')}
                            defaultValue={defaultLanguageSelected}
                        >
                            {sortedLanguages?.map((lang) => (
                                <MenuItem key={lang.code} value={lang.code}>
                                    {lang.name}
                                </MenuItem>
                            ))}
                        </ControlledSelect>
                    </Grid>
                </Grid>
                <StyledTabs value={tabIndex} onChange={handleChange} aria-label="basic tabs example">
                    <StyledTab label={t('actionLabels.sendSms')} {...a11yProps(0)} />
                    <StyledTab
                        label={t('actionLabels.linkandQR')}
                        data-test="invitelink-tab"
                        {...a11yProps(1)}
                    />
                </StyledTabs>
            </StyledTabsContainer>
            <StyledDecoration />

            <TabPanel value={tabIndex} index={0}>
                <form onSubmit={handleSubmit(onSubmitSendSMSForm)} noValidate>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    name="phone"
                                    render={({ field }) => (
                                        <TextField
                                            data-test="invite-with-phone-field"
                                            label={t('contacts.form.labels.phoneNumber')}
                                            fullWidth
                                            type="tel"
                                            error={!!errors.phone}
                                            helperText={
                                                errors.phone
                                                    ? t(`forms.errors.${errors.phone?.type ?? 'invalid'}`)
                                                    : null
                                            }
                                            placeholder={'+43660...'}
                                            onFocus={onFocusPhoneField}
                                            {...register('phone', {
                                                required: true,
                                                pattern: phoneRegex,
                                            })}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <StyledDialogFooter>
                        <StyledMessageGroupWrapper>
                            <Grid container spacing={2}>
                                <Grid item xs={1.5}>
                                    <StyledAvatar sx={{ width: '80', height: '80', fontSize: '1rem' }} src="">
                                        {getNameInitials('jahanzaib', 'ashraf')}
                                    </StyledAvatar>
                                </Grid>
                                <Grid item xs={10.5}>
                                    <StyledMessageGroup $direction={MessageDirectionEnum.FROM_CONTACT}>
                                        <StyledMessageAuthorText>Heylog</StyledMessageAuthorText>
                                    </StyledMessageGroup>
                                    <StyledMessageGroup $direction={staticMockedMessage.direction}>
                                        <MessageBubble
                                            message={{ ...staticMockedMessage, text: mockedMessageText, direction: 'FROM_CONTACT' }}
                                        />
                                    </StyledMessageGroup>
                                </Grid>
                            </Grid>
                        </StyledMessageGroupWrapper>
                    </StyledDialogFooter>
                    <ButtonsGridContainer>
                        <ButtonV2 $variant="outlined2" onClick={onCancel} disabled={isLoading}>
                            {t('actionLabels.cancel')}
                        </ButtonV2>
                        <ButtonV2
                            startIcon={isLoading && <CircularProgress size={20} style={{ color: 'white' }} />}
                            $variant="dark" onClick={handleSubmit(onSubmitSendSMSForm)} style={{
                                borderRadius: '4px',
                            }}>
                            {t('actionLabels.sendSms')}
                        </ButtonV2>
                    </ButtonsGridContainer>
                </form>
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>
                <DialogContent>
                    <StyledInputDescription>
                        {t('contacts.form.copyAndShareLink')}
                    </StyledInputDescription>

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField onClick={handleCopyClick} InputProps={{
                                readOnly: true,
                                style: { cursor: 'default' },
                            }} fullWidth type="text" value={inviteLink} />
                        </Grid>

                        {/* <FlashSnackbarV2 controls={[stateSnackbar, openSnackbar, closeSnackbar]} /> */}
                    </Grid>
                </DialogContent>
                <StyledDialogFooter2>
                    <QRCode
                        id="qr-code"
                        value={inviteLink}
                        size={150}
                        removeQrCodeBehindLogo
                        quietZone={0}
                        logoWidth={25}
                        logoImage={logo}
                    />
                    <StyledDownloadButton2 onClick={downloadQRCode} type="button">
                        <FileDownloadOutlined />
                    </StyledDownloadButton2>
                </StyledDialogFooter2>
                <ButtonsGridContainer>
                    <ButtonV2 $variant="outlined2" onClick={onCancel} >
                        {t('actionLabels.cancel')}
                    </ButtonV2>
                    <ButtonV2
                        $variant="dark" onClick={handleCopyClick} style={{
                            borderRadius: '4px',
                        }}>
                        {t('actionLabels.copyLink')}
                    </ButtonV2>
                </ButtonsGridContainer>
            </TabPanel>
        </StyledDialogContainer>
        </>
    );
};
