import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EventPayloadEnum, MessagingTransportTypeEnum } from '@heylog-app/shared/types';

import { MessageInput, MessageInputBlocked } from '../ui';
import { OrderNotice } from '../ui/message-input/order-notice';
import { useContact, useConversation } from '../../hooks';

import type {
	FullContactResInterface,
	OrderResInterface,
	EntityEventResType,
	MessageResInterface,
} from '@heylog-app/shared/types';
import type { FC } from 'react';
import { MessageInputV3 } from '../ui/message-input/message-inputV3';

type MessageInputRendererProps = {
	conversationId?: string;
	shouldFocusOnRender?: boolean;
};

export const MessageInputRendererV2: FC<any> = ({
	conversationId: conversationIdProp,
	shouldFocusOnRender,
}) => {
	const { t } = useTranslation();
	const { workspaceId = '', ...params } = useParams();



	return (
		<>

			<MessageInputV3
				shouldFocusOnRender={shouldFocusOnRender}
				conversationId={conversationIdProp}
			/>
		</>
	);
};
