import styled from 'styled-components';

import { colors } from '../../styles';

import type { Theme } from '@mui/material/styles';
import { OrderTaskStatusEnum } from '@heylog-app/shared/types';

export const StyledContainer = styled.div`
	flex-grow: 1;
	max-width: 400px;
	height: 100%;
	padding-left: 10px;
	padding-right: 10px;
	overflow-y: auto;
	border: 1px solid ${colors.grey[300]};
`;

export const StyledStepWrapper = styled.div`
	border-radius: 4px;
	background-color: ${colors.grey[200]};
	padding: 8px 16px;
	width: 316px;
`;

export const StyledTypeLabel = styled.span`
	margin-left: 5px;
	margin-top: 2px;
`;

export const StyledHeader = styled.div`
	display: flex;
	flex-direction: row;
	color: ${({ theme }) => theme.palette.primary.darkV2};
`;

export const StyledLabel = styled.div`
	color: ${colors.grey[600]};
`;

export const StyledWithoutTasks = styled.div`
	width: 300px;
	height: 100%;
	color: ${colors.grey[600]};
	font-size: 18px;
	text-align: center;
	padding-top: 50%;
`;

export const CustomBlock = styled.div``;

export const CustomLabelsBlock = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const CustomCompanyInfoBlock = styled.div`
	display: flex;
	flex-direction: column;
`;

export const CustomInfoBlock = styled.div`
	display: flex;
`;

export const CustomReferenceBlock = styled.div`
	display: flex;
	flex-direction: row;
`;

export const CustomReferenceText = styled.span`
	margin-left: 5px;
`;

export const StyledCompanyName = styled.div`
	margin-left: 5px;
	font-weight: bold;
`;

export const CustomStepCircle = styled('div')<{
	theme: Theme;
	completed: boolean;
	active: boolean;
	warning: boolean;
	problem: boolean;
}>(({ theme, completed, active, warning, problem }) => ({
	width: 24,
	height: 24,
	borderRadius: '50%',
	backgroundColor: completed
		? theme.palette.success.main
		: active
			? theme.palette.primary.main
			: warning
				? theme.palette.warning.light
				: problem ? colors?.red.main : theme.palette.grey[400],
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const StatusLabel = styled('div')<{
	active: boolean;
	theme: Theme;
	completed: boolean;
	cancelled?: boolean;
}>(({ theme, completed, active, cancelled }) => ({
	borderRadius: '4px',
	backgroundColor: cancelled
		? theme.palette.warning.light
		: completed
			? theme.palette.success.lighter
			: active
				? theme.palette.primary.lighter
				: theme.palette.grey[300],
	color: cancelled
		? theme.palette.warning.main
		: completed
			? theme.palette.success.main
			: active
				? theme.palette.primary.main
				: theme.palette.grey[700],
	fontWeight: 500,
	padding: completed ? '2px 22px' : active ? '2px 4px' : '2px 16px',
}));


export const StatusDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const StyledStatusButton = styled.button<{ status: string, width?: string, height?: string }>`
  background-color:  ${({ status }) =>
		status === OrderTaskStatusEnum.COMPLETED ? '#D9F9DA' : status === OrderTaskStatusEnum.PENDING ? "#E1E4EA" : status === OrderTaskStatusEnum.IN_PROGRESS ? "#CCE8F8" : status === OrderTaskStatusEnum.CANCELLED ? "#FBD4B4" : status === OrderTaskStatusEnum.PROBLEM ? "#FFCFCF" : ""};
  color: ${({ status }) =>
		status === OrderTaskStatusEnum.COMPLETED ? '#198A1F' : status === OrderTaskStatusEnum.PENDING ? "#656786" : status === OrderTaskStatusEnum.IN_PROGRESS ? "#1588C7" : status === OrderTaskStatusEnum.CANCELLED ? "#BA5D11" : status === OrderTaskStatusEnum.PROBLEM ? "#B20F0F" : ""};
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: bold;
  width:${({ width }) => width || "fit-content"};
  height:${({ height }) => height || "fit-content"};
`;
export const StyledStatusButton2 = styled.button<{ status: string }>`
  background-color:  ${({ status }) =>
		status === OrderTaskStatusEnum.COMPLETED ? '#D9F9DA' : status === OrderTaskStatusEnum.PENDING ? "#E1E4EA" : status === OrderTaskStatusEnum.IN_PROGRESS ? "#CCE8F8" : status === OrderTaskStatusEnum.CANCELLED ? "#FBD4B4" : status === OrderTaskStatusEnum.PROBLEM ? "#FFCFCF" : ""};
  color: ${({ status }) =>
		status === OrderTaskStatusEnum.COMPLETED ? '#198A1F' : status === OrderTaskStatusEnum.PENDING ? "#656786" : status === OrderTaskStatusEnum.IN_PROGRESS ? "#1588C7" : status === OrderTaskStatusEnum.CANCELLED ? "#BA5D11" : status === OrderTaskStatusEnum.PROBLEM ? "#B20F0F" : ""};
  padding: 8px 16px;
  border: none;
  width: 135px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: bold;
`;

export const StyledStatusMenu = styled.div`
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px 0;
  z-index: 1;
  min-width: 110px;

  ${StatusDropdown}:hover & {
    display: block;

  }
`;
export const StyledStatusMenu2 = styled.div`
  display: block;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px 0;
  z-index: 1;
  min-width: 136px;
`;
export const StyledLocation = styled.div`
	color: #030B5E;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
	letter-spacing: -0.2px;
`
export const StyledStatusOption = styled.div<{ status: string }>`
	padding: 8px 16px;
	cursor: pointer;
	color: ${({ status }) =>
		status === OrderTaskStatusEnum.COMPLETED ? '#198A1F' : status === OrderTaskStatusEnum.PENDING ? "#656786" : status === OrderTaskStatusEnum.IN_PROGRESS ? "#1588C7" : status === OrderTaskStatusEnum.CANCELLED ? "#BA5D11" : status === OrderTaskStatusEnum.PROBLEM ? "#B20F0F" : ''};
	text-align: center;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
	letter-spacing: -0.1px;
	&:hover {
		background-color: rgb(209, 212, 242);
	}
`;