import type { ConversationType } from '@heylog-app/shared/types';

export const getConversationEmailsKey = (workspaceId: string, conversationId: string) => {
	return `/workspaces/${workspaceId}/conversations-v2/${conversationId}/emails`;
};

export const getConversationsV2Key = (
	workspaceId: string,
	shipmentId?: string,
	types?: ConversationType[],
	page?: number,
	pageSize?: number,
	searchTerm?: string,
	tabName?: string,
	statusType?: string,
) => {
	const url = `/workspaces/${workspaceId}/conversations-v2`;

	const params = new URLSearchParams();

	if (shipmentId) {
		params.append('orderId', shipmentId);
	}

	if (types && types.length > 0) {
		params.append('types', types?.join(','));
	}
	if (page) {
		params.append('page', page.toString());

	}
	if (pageSize) {
		params.append('rowsPerPage', pageSize?.toString());

	}
	if (searchTerm) {
		params.append('query', searchTerm)
	}
	if (tabName) {
		if (tabName === "All") {

		} else {

			params.append('conversationType', tabName === "All" ? "" : tabName === "Heylog" ? "HEYLOG_USER_TO_USER" : tabName === "Whatsapp" ? "WHATSAPP" : tabName === "Email" ? "EMAIL" : "")
		}
	}
	if (statusType) {
		params.append("conversationStatus", statusType)
	}
	const paramString = params.toString();
	return paramString ? `${url}?${paramString}` : url;
};

export const getConversationV2Key = (workspaceId: string, conversationId: string) => {
	return `/workspaces/${workspaceId}/conversations-v2/${conversationId}`;
};
// export const getMediaMessagesKey = (workspaceId: string, conversationId: string) => {
// 	return `/workspaces/${workspaceId}/conversations/${conversationId}/media`;
// };

// export const getReadMessagesKey = (workspaceId: string, conversationId: string) => {
// 	return `/workspaces/${workspaceId}/conversations/${conversationId}/read`;
// };
