export enum MessageDialogTypesEnum {
	EMAIL,
	EMAIL_REPLY,
	CHAT_APP,
	HEYLOG_APP,
}

export enum EmailDialogActionEnum {
	NEW,
	OPEN,
}

export interface EmailDialogPayloadInterface {
	conversationId?: number;
	workspaceId?: number;
	orderId?: number;
}

export interface ChatAppDialogPayloadInterface {
	conversationV2Id: number;
	conversationId: number;
	contactId: number;
	userId?: number;
	workspaceId?: number;
	orderId?: number;
}

export type MessageDialogInterface =
	| {
		type: MessageDialogTypesEnum.EMAIL;
		action: EmailDialogActionEnum;
		id?: string;
		payload: EmailDialogPayloadInterface;
	}
	| {
		type: MessageDialogTypesEnum.CHAT_APP;
		id?: string;
		payload: ChatAppDialogPayloadInterface;
	} | {
		type: MessageDialogTypesEnum.HEYLOG_APP;
		id?: string;
		payload: ChatAppDialogPayloadInterface;
	};
