import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Dialog, DialogTitle, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	AddContactForm,
	ContactSkeleton,
	Conversation,
	ROUTES,
	Sidebar,
	SidebarHeader,
	SidebarList,
	useContacts,
	useDialog,
	useUnread,
	ContactListItem,
	ContactSelect,
	createOrderConnectionApi,
	SidebarLayoutContent,
	ModalClose,
	useOrder,
	useApiClientContext,
	FlashSnackbar,
	useSnackbar,
	useUser,
} from '@heylog-app/frontend-lib/app';
import { OrderConnectionStatusEnum } from '@heylog-app/shared/types';

import { SelectOrderWelcome } from './welcome/select-order-welcome';

import type { FullContactResInterface } from '@heylog-app/shared/types';

export const OrderConversationsPage = () => {
	const { t } = useTranslation();
	const params = useParams();
	const { workspaceId = '', orderId = '', conversationId = '' } = params;
	const { user } = useUser();
	const [addUserInProgress, setAddUserInProgress] = useState(false);

	const navigate = useNavigate();
	const { apiClient } = useApiClientContext();
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const {
		showDialog: showAddContactDialog,
		closeDialog: closeAddContactDialog,
		openDialog: openAddContactDialog,
	} = useDialog();

	const { unreadMap, isLoading: unreadLoading, updateUnread } = useUnread();

	useEffect(() => {
		updateUnread();
	}, [conversationId, updateUnread]);

	const {
		contacts,
		isLoading: contactsLoading,
		updateContacts,
	} = useContacts({ orderId });

	const { order } = useOrder(orderId);

	const onSelectContact = useCallback(
		(contact: FullContactResInterface) => {
			if (contact.conversations[0]?.id.toString() === conversationId) {
				// in case we are already on the same conversation no need to navigate
				return;
			}

			navigate(
				generatePath(ROUTES.ORDERS.CHAT, {
					workspaceId,
					orderId,
					contactId: contact.id.toString(),
					conversationId: contact.conversations[0]?.id.toString(),
				}),
			);
		},
		[conversationId, navigate, workspaceId, orderId],
	);

	const onAssignContact = useCallback(
		async (contact: FullContactResInterface) => {
			try {
				setAddUserInProgress(true);
				const assignment = await createOrderConnectionApi(apiClient, {
					data: {
						contactId: contact.id,
						orderId: parseInt(orderId),
					},
					workspaceId,
				});
				const updatedContacts = await updateContacts();
				const updatedContact = updatedContacts?.data?.find((c) => c.id === contact.id);
				if (updatedContact) {
					setAddUserInProgress(false);
					navigate(
						generatePath(ROUTES.ORDERS.CHAT, {
							workspaceId,
							orderId,
							contactId: assignment.data.contactId.toString(),
							conversationId: updatedContact.conversations[0]?.id.toString(),
						}),
					);
				}
			} catch (error) {
				console.error(error);
			}
		},
		[apiClient, navigate, orderId, updateContacts, workspaceId],
	);

	const handleAddContactSuccess = useCallback(() => {
		openSnackbar('success', t('contacts.form.smsSent'));
		updateContacts();
		closeAddContactDialog();
	}, [closeAddContactDialog, updateContacts, openSnackbar, t]);

	return (
		<>
			<Grid container sx={{ height: '100%', overflow: 'hidden' }}>
				<Sidebar variant="primary">
					<SidebarHeader title={'orders.conversations.sidebar.title'}>
						<ContactSelect
							allowCreate={true}
							shouldConfirm={(contact) => {
								return !!contact.orderConnections.find(
									(c) => c.status === OrderConnectionStatusEnum.CREATED,
								);
							}}
							confirmationMessage={t('contacts.toolSidebar.assignOrderConfirmation')}
							onSelect={onAssignContact}
							onCreate={openAddContactDialog}
							addUserInProgress={addUserInProgress}
						/>
					</SidebarHeader>
					<SidebarList>
						{contacts?.length && !unreadLoading ? (
							contacts?.map((contact) => (
								<ContactListItem
									onSelectContact={onSelectContact}
									key={contact.id}
									contact={contact}
									unreadCount={unreadMap?.[contact.id] ?? 0}
								/>
							))
						) : (
							<ContactSkeleton animated={contactsLoading} />
						)}
					</SidebarList>
				</Sidebar>

				<SidebarLayoutContent>
					{conversationId && contacts?.length ? (
						<Conversation order={order} contactFilter={'ACTIVE'} />
					) : (
						<SelectOrderWelcome user={user} />
					)}
				</SidebarLayoutContent>

				<Dialog
					open={showAddContactDialog}
					onClose={closeAddContactDialog}
					fullWidth
					maxWidth="md"
				>
					<ModalClose closeModalFn={closeAddContactDialog} />
					<DialogTitle>{t('contacts.form.createContactTitle')}</DialogTitle>

					<AddContactForm
						onSuccess={handleAddContactSuccess}
						onCancel={closeAddContactDialog}
					/>
				</Dialog>
			</Grid>

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
