export const colors = {
	primary: {
		main: '#122CC5',
		lighter: '#d1d4f2',
		light: '#a3aae6',
		medium: '#6B79CA',
		dark: '#343B64', // dark-blue
		darkV2: '#091c8f', // dark-blue
		darker: '#030B5E',
	},
	common: {
		black: 'rgba(0, 0, 0, 1)',
		white: 'rgba(255, 255, 255, 1)',
		blackTransparent: 'rgba(0, 0, 0, 0.5)',
	},
	grey: {
		200: '#F5F8FE',
		250: '#edf0f6',
		300: '#e1e4ea',
		400: '#cdcfd9',
		500: '#9BA0B3',
		600: '#7C839E',
		700: '#656786',
		900: '#141B47',
		transparent245: 'rgba(245, 248, 254, 0.5)',
	},
	
	greyblue: {
		112: 'rgba(112, 114, 117, 1)',
		125: 'rgba(125, 128, 124, 1)',
		175: 'rgba(175, 178, 184, 1)',
		205: 'rgba(205, 208, 214, 1)',
		225: 'rgba(225, 228, 234, 1)',
		245: 'rgba(245, 248, 254, 1)',
		transparent245: 'rgba(245, 248, 254, 0.5)',
	},
	border: {
		main: 'rgba(0, 0, 0, 0.1)',
		hover: 'rgba(0, 0, 0, 0.2)',
	},
	green: {
		main: 'rgba(37, 211, 102, 0.1)',
		main50: 'rgba(37, 211, 102, 0.5)',
		main100: 'rgba(37, 211, 102, 1)',
	},
	purple: {
		main: '#c7e2fd',
		main50: '#6e4bfe',
	},
	red: {
		main: '#F45656',
	},
	success: {
		lighter: '#c8e6c9',
		main: '#35c23d',
		dark: '#198a1f',
	},
	warning: {
		light: '#fbd4b4',
		main: '#fa8525',
		dark: '#ba5d11',
	},
	error: {
		light: '#FFCFCF',
		main: '#DF2626',
		dark: '#B20F0F',
	},
	status: {
		created: '#FFAE34',
		accepted: '#00DC82',
		in_delivery: '#00DC82',
		unloaded: '#006968',
		completed: '#122CC5',
		archived: '#122CC5',
		archive: '#122CC5',
		problem: '#DF2647',
		approaching: '#00DC82',
		arrived_at_loading: '#00DC82',
		loaded: '#00DC82',
		departed_from_loading: '#00DC82',
		in_transit: '#00DC82',
		arrived_at_unloading: '#00DC82',
		delivered: '#00DC82',
	},
	orderStatus: {
		created: '#FFAE34',
		accepted: '#00DC82',
		in_delivery: '#00DC82',
		unloaded: '#006968',
		completed: '#122CC5',
		archived: '#122CC5',
		problem: '#DF2647',
		approaching: '#00DC82',
		arrived_at_loading: '#00DC82',
		loaded: '#00DC82',
		departed_from_loading: '#00DC82',
		in_transit: '#00DC82',
		arrived_at_unloading: '#00DC82',
		delivered: '#00DC82',
	},
};
