import { createGlobalStyle } from 'styled-components';

// NOTE: currently this file is not included -> use globals.css until fixed
export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Eudoxus Sans';
        font-weight: 400;
        src: url('/assets/fonts/EudoxusSans-Medium.woff2') format('woff2'),
            url('/assets/fonts/EudoxusSans-Medium.woff') format('woff'),
            url('/assets/fonts/EudoxusSans-Medium.ttf')  format('truetype'),  
    }

    @font-face {
        font-family: 'Eudoxus Sans';
        font-weight: 500;
        src: url('/assets/fonts/EudoxusSans-Bold.woff2') format('woff2'),
            url('/assets/fonts/EudoxusSans-Bold.woff') format('woff'),
            url('/assets/fonts/EudoxusSans-Bold.ttf')  format('truetype'),  
    }

    @font-face {
        font-family: 'Eudoxus Sans';
        font-weight: 700;
        src: url('/assets/fonts/EudoxusSans-ExtraBold.woff2') format('woff2'),
            url('/assets/fonts/EudoxusSans-ExtraBold.woff') format('woff'),
            url('/assets/fonts/EudoxusSans-ExtraBold.ttf')  format('truetype'),  
    }

    * {
        box-sizing: border-box;
    }
    .bold-header{
        font-weight: bold;
    }
    html, body {
        padding: 0;
        margin: 0;

        font-size: 18px;
        font-family: sans-serif;
    }
`;
