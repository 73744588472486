import { Autocomplete, Button, Chip, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrders } from '../../../hooks';
import { ButtonV2 } from '../../ui';

import type { TextFieldVariants } from '@mui/material';
import type { FC } from 'react';
import { StyledAutocomplete, StyledChip } from './order.styles';

interface OrderOption {
	id: number;
	label: string;
}

interface ChooseOrderProps {
	handleAssign: ({ orderId }: { orderId: string | null }) => void;
	width?: number | string;
	hasSubmit?: boolean;
	initialOrderId?: number;
	flexWrap?: 'wrap' | 'nowrap';
	className?: string;
	inputProps?: {
		variant?: TextFieldVariants;
		hasLabel?: boolean;
		startAdornment?: JSX.Element;
	};
}

export const LinkedOrder: FC<ChooseOrderProps> = ({
	handleAssign,
	className,
	width = 300,
	flexWrap = 'wrap',
	initialOrderId,
	hasSubmit = true,
	inputProps: { hasLabel = true, variant, startAdornment } = {},
}) => {
	const { t } = useTranslation();
	const [selectedOrder, setSelectedOrder] = useState<OrderOption | null>(null);
	const [linkedOrder, setLinkedOrder] = useState<OrderOption | null>(null);

	const { orders } = useOrders();

	const options: OrderOption[] =
		orders?.map((order) => {
			return {
				id: order.id,
				label:
					order.labels.find((label) => label.type === 'ORDER_EXTERNAL_REFERENCE')
						?.value || order.refNumber,
			};
		}).filter((item: any) => { return item.status !== "ARCHIVED" && item.status !== "COMPLETED" }) || [];

	const matchedOrder = options.find((opt) => opt.id === initialOrderId);

	useEffect(() => {
		if (matchedOrder) setSelectedOrder(matchedOrder);
	}, [matchedOrder?.id]);

	const handleLink = () => {
		if (selectedOrder) {
			setLinkedOrder(selectedOrder);
			handleAssign({ orderId: selectedOrder.id.toString() });
		}
	};

	const handleRemoveLink = () => {
		setLinkedOrder(null);
		handleAssign({ orderId: null });
	};

	return (
		<Stack direction="column" gap={2} className={className}>
			{linkedOrder && (
				<Stack direction="row" alignItems="center" spacing={1}>
					<StyledChip
						label={linkedOrder.label}
						onDelete={handleRemoveLink}
					/>
				</Stack>
			)}

			<Stack direction="row" flexWrap={flexWrap} gap={2}>
				<StyledAutocomplete
					id="select-demo"
					sx={{ width: '93%' }}
					options={options}
					value={selectedOrder}
					onChange={(event, newValue: any) => setSelectedOrder(newValue)}
					inputValue={selectedOrder?.label || ''}
					onInputChange={(event, newValue) => setSelectedOrder(null)}
					autoHighlight
					getOptionLabel={(option: any) => option.label}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder="Link to orders"
						/>
					)}
				/>
				<Button
					onClick={handleLink} disabled={!selectedOrder}
				>
					Link
				</Button>
			</Stack>
		</Stack>
	);
};
