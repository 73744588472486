
import { Box, createTheme, Popover, ThemeProvider, Typography } from '@mui/material';
import { deDE, enUS } from '@mui/x-data-grid-pro';
import { useContext, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';
import * as locales from '@mui/x-data-grid/locales';

import {
	CustomNoResultsOverlay,
	EmailDialogActionEnum,
	MessageContext,
	MessageDialogTypesEnum,
	ROUTES,
	TableV2,
	theme,
} from '@heylog-app/frontend-lib/app';
import { ConversationTypeEnum } from '@heylog-app/shared/types';

import type { ContactsDetailsRow } from '@heylog-app/frontend-lib/app';
import type { MutableRefObject } from 'react';
import type {
	GridApiPro,
	GridColDef,
	GridColumnResizeParams,
	GridColumnVisibilityModel,
	GridRowParams,
	MuiEvent,
} from '@mui/x-data-grid-pro';
import { OverLayText, StyledGridOverlay } from './contacts-data-table.styles';

interface ContactsDataGridProps {
	rows: ContactsDetailsRow[];
	columns: GridColDef[];
	apiRef: MutableRefObject<GridApiPro>;
	title?: string;
	ToolbarComponent?: JSX.Element;
	hideFooter?: boolean;
	disableColumnMenu?: boolean;
	columnVisibilityModel?: GridColumnVisibilityModel;
	handleColumnResize?: (params: GridColumnResizeParams) => void;
	handleColumnOrderChange?: () => void;
	handleColumnVisibilityModelChange?: (model: GridColumnVisibilityModel) => void;
	withPaginationSettings?: boolean;
	checkboxSelection?: boolean;
	handleRowSelectionChange?: (records: any) => void;
	activeTab?: number;
	isLoading?: boolean;
	rowCount?: number;
	pagination?: boolean;
	onPageChange?: (val: number) => void;
}

export const ContactsDataGrid: React.FC<ContactsDataGridProps> = ({
	isLoading,
	rows,
	columns,
	apiRef,
	ToolbarComponent,
	title,
	hideFooter,
	disableColumnMenu,
	columnVisibilityModel,
	handleColumnOrderChange,
	pagination,
	withPaginationSettings,
	handleColumnResize,
	handleColumnVisibilityModelChange,
	checkboxSelection,
	handleRowSelectionChange,
	activeTab,
	onPageChange,
	rowCount,
	...props
}) => {
	const { onOpenMessageDialog } = useContext(MessageContext);
	const navParams = useParams();
	const currentLanguage = i18next.language;
	const tableLanguage = currentLanguage === 'de' ? 'deDE' : 'enUS';
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [messagePreview, setMessagePreview] = useState<string | undefined>('');
	const { workspaceId } = useParams();

	const navigate = useNavigate();

	const themeWithLocale = useMemo(
		() => createTheme(theme, locales[tableLanguage]),
		[tableLanguage],
	);

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const handleRowLeave = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<ThemeProvider theme={themeWithLocale}>
			<TableV2
				ToolbarContent={ToolbarComponent}
				title={title}
				withPaginationSettings={withPaginationSettings}
				withColumnSettings
				hideFooter={hideFooter}
				disableColumnFilter
				rowCount={rowCount}
				pagination={pagination}
				disableColumnMenu={disableColumnMenu}
				columnVisibilityModel={columnVisibilityModel}
				data-test="fleet-checks-table"
				onColumnResize={handleColumnResize}
				onColumnOrderChange={handleColumnOrderChange}
				onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
				checkboxSelection={checkboxSelection}
				localeText={
					tableLanguage === 'deDE'
						? deDE.components.MuiDataGrid.defaultProps.localeText
						: enUS.components.MuiDataGrid.defaultProps.localeText
				}
				density="compact"
				apiRef={apiRef}
				getRowId={(row) => row.contactId}
				rows={rows || []}
				columns={columns}
				disableDensitySelector
				initialState={{
					filter: {
						filterModel: {
							items: [],
							quickFilterValues: [''],
						},
					},
					pagination: { paginationModel: { pageSize: 5 } },
				}}
				slots={{
					noRowsOverlay: () => (
						<CustomNoResultsOverlay
							message='It looks empty here! Why not add a new contact?'
						/>
					),
				}}
				pageSizeOptions={[5, 10, 15]}
				sx={{
					backgroundColor: 'white',
					borderRadius: '5px',
					fontSize: '10px',
				}}
				onPageChange={onPageChange}
				loading={isLoading}
				handleRowSelectionChange={handleRowSelectionChange}
				{...props}
			/>
			<Popover
				sx={{
					pointerEvents: 'none',
					maxWidth: '800px',
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography sx={{ p: 1 }}>{messagePreview}</Typography>
			</Popover>
		</ThemeProvider>
	);
};