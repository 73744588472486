import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import {
	NoMatch,
	ProtectedRoute,
	MobileRestrictedRoute,
	ROUTES,
} from '@heylog-app/frontend-lib/app';
import {
	DemoPage,
	OrderConversationsPage,
	OrderDetailPage,
	OrderDocumentsPage,
	OrderNotesPage,
	OrdersPage,
	OrderStatusUpdateByReferencePage,
	OrderStatusUpdatePage,
	TaskDelayedPage,
	TaskStatusUpdatePage,
} from '@heylog-app/frontend-lib/modules/orders';
import {
	ContactConversationPage,
	ContactsDetailPage,
	ContactsPage,
	MembersSettingsPage,
	ProfileSettingsPage,
	SettingsPage,
	WorkspaceSettingsPage,
	AppsSettingsPage,
	OrderStatusSettingsPage,
	VehicleCheckEmailListPage,
	ContactInformationPage,
	ContactManagementPage,
	ContactsPageV2,
	ContactConversationPageWhatsapp,
	ContactConversationPageHeylog,
	ContactsDetailPageV2,
	ContactsPageV3,
	ContactsPageV1,
	ContactsDetailPageV1,
	ContactConversationPageV1,
} from '@heylog-app/frontend-lib/modules/contacts';
import {
	DashboardRedirectPage,
	DisabledWorkspace,
	JoinWorkspacePage,
	JoinConversationPage,
	LoginForm,
	LoginPage,
	LogoutPage,
	NoWorkspace,
	NoConversationGuest,
	ExpiredInvitation,
	SuccessfulRegistration,
	ForgotPasswordPage,
	ResetPasswordPage,
	ExpiredResetLink,
} from '@heylog-app/frontend-lib/modules/auth';
import { PersonalDataPage, SignupPage } from '@heylog-app/frontend-lib/modules/signup';
import {
	FlatTyreCheckPage,
	VehicleCheckPage,
	FleetPage,
	FlatTyreCheckStartPage,
	VehicleCheckStartPage,
} from '@heylog-app/frontend-lib/modules/fleet';
import { DocumentsPage } from '@heylog-app/frontend-lib/modules/documents';
import {
	JoinPage,
	ViberJoinPage,
	WhatsappJoinPage,
} from '@heylog-app/frontend-lib/modules/join';
import {
	DashboardMainPage,
	DashboardPage,
} from '@heylog-app/frontend-lib/modules/dashboard';
import { MessagesPage, NewConversation } from '@heylog-app/frontend-lib/modules/messages';
import { ShipmentPage } from '@heylog-app/frontend-lib/modules/shipments';
import { WorkspaceinvitationUserRoleEnum } from '@heylog-app/shared/types';

import '../assets/globals.css';
import { DpaPage, DpaPagePublic } from 'libs/frontend-lib/modules/contacts/src/pages/settings/components/dpa-page';

export const router = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			{/* NOT SUITABLE FOR SMALL SCREENS */}
			<Route element={<MobileRestrictedRoute />}>
				<Route path={ROUTES.LOGIN.USER} element={<LoginPage />}>
					<Route path={ROUTES.LOGIN.USER} element={<LoginForm />} />
				</Route>

				<Route path={ROUTES.LOGOUT} element={<LogoutPage />} />

				<Route path={ROUTES.SIGNUP.PERSONAL_DATA} element={<SignupPage />}>
					<Route path={ROUTES.SIGNUP.PERSONAL_DATA} element={<PersonalDataPage />} />
				</Route>

				{/* PROTECTED ROUTES */}
				<Route path="/" element={<ProtectedRoute />}>
					{/* TODO add dashboard page component here */}
					<Route path={ROUTES.DEMO} element={<DemoPage />} />
					<Route path={ROUTES.DASHBOARD.OVERVIEW} element={<DashboardPage />} />
					<Route path={ROUTES.DASHBOARD_MAIN.OVERVIEW} element={<DashboardMainPage />} />
					<Route path={ROUTES.SHIPMENTS.DETAIL} element={<ShipmentPage />} />

					<Route path={ROUTES.DOCUMENTS.OVERVIEW} element={<DocumentsPage />} />

					<Route path={ROUTES.MESSAGES.OVERVIEW} element={<MessagesPage />} />

					<Route path={ROUTES.CONVERSATIONS.NEW} element={<NewConversation />} />

					<Route path={ROUTES.ORDERS.OVERVIEW} element={<OrdersPage />}>
						<Route path={ROUTES.ORDERS.DETAIL} element={<OrderDetailPage />}>
							<Route path={ROUTES.ORDERS.CHATS} element={<OrderConversationsPage />} />
							<Route path={ROUTES.ORDERS.CHAT} element={<OrderConversationsPage />} />
							<Route path={ROUTES.ORDERS.NOTES} element={<OrderNotesPage />} />
							<Route path={ROUTES.ORDERS.DOCUMENTS} element={<OrderDocumentsPage />} />
						</Route>
					</Route>
					<Route path={ROUTES.CONTACTS.LIST} element={<ContactsPageV2 />} />
					<Route path={ROUTES.CONTACTS.OVERVIEW2} element={<ContactsPageV3 />}>
						<Route path={ROUTES.CONTACTS.DETAIL2} element={<ContactsDetailPageV2 />}>
							<Route path={ROUTES.CONTACTS.CHAT4} element={<ContactConversationPageHeylog />} />
						</Route>
					</Route>
					<Route path={ROUTES.CONTACTS.OVERVIEW} element={<ContactsPageV1 />}>
						<Route path={ROUTES.CONTACTS.DETAIL} element={<ContactsDetailPageV1 />}>
							<Route path={ROUTES.CONTACTS.CHAT2} element={<ContactConversationPageV1 />} />
							<Route
								path={ROUTES.CONTACTS.MANAGEMENT2}
								element={<ContactManagementPage />}
							/>
							<Route
								path={ROUTES.CONTACTS.INFORMATION2}
								element={<ContactInformationPage />}
							/>
						</Route>
					</Route>
					<Route path={ROUTES.CONTACTS.OVERVIEW1} element={<ContactsPage />}>
						<Route path={ROUTES.CONTACTS.DETAIL1} element={<ContactsDetailPage />}>
							{/* <Route path={ROUTES.CONTACTS.CHAT} element={<ContactConversationPage />} /> */}
							<Route path={ROUTES.CONTACTS.CHAT3} element={<ContactConversationPageWhatsapp />} />
							{/* <Route path={ROUTES.CONTACTS.CHAT5} element={<ContactConversationPage />} /> */}
							<Route
								path={ROUTES.CONTACTS.MANAGEMENT}
								element={<ContactManagementPage />}
							/>
							<Route
								path={ROUTES.CONTACTS.INFORMATION}
								element={<ContactInformationPage />}
							/>
						</Route>
					</Route>

					<Route path={ROUTES.FLEET.OVERVIEW} element={<FleetPage />} />

					<Route path={ROUTES.SETTINGS.OVERVIEW} element={<SettingsPage />}>
						<Route path={ROUTES.SETTINGS.PROFILE} element={<ProfileSettingsPage />} />
						<Route path={ROUTES.SETTINGS.WORKSPACE} element={<WorkspaceSettingsPage />} />
						<Route path={ROUTES.SETTINGS.DPA} element={<DpaPage />} />
						<Route
							path={ROUTES.SETTINGS.ORDERSTATUS}
							element={<OrderStatusSettingsPage />}
						/>
						<Route
							path={ROUTES.SETTINGS.VCHKEMAILLIST}
							element={<VehicleCheckEmailListPage />}
						/>
						<Route
							path={ROUTES.SETTINGS.MEMBERS}
							element={
								<MembersSettingsPage
									role={WorkspaceinvitationUserRoleEnum.WORKSPACE_ADMIN}
								/>
							}
						/>
						<Route
							path={ROUTES.SETTINGS.CUSTOMERS}
							element={
								<MembersSettingsPage role={WorkspaceinvitationUserRoleEnum.CUSTOMER} />
							}
						/>
						<Route path={ROUTES.SETTINGS.APPS} element={<AppsSettingsPage />} />
					</Route>
				</Route>
			</Route>

			{/* PASSWORD RESET ROUTES*/}
			<Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
			<Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
			<Route path={ROUTES.EXPIRED_RESET_LINK} element={<ExpiredResetLink />} />

			{/* PUBLIC JOIN ROUTES*/}
			<Route path={ROUTES.JOIN.WORKSPACE} element={<JoinPage />} />
			<Route path={ROUTES.JOIN.ORDER} element={<JoinPage />} />
			<Route path={ROUTES.JOIN.WHATSAPP} element={<WhatsappJoinPage />} />
			<Route path={ROUTES.JOIN.VIBER} element={<ViberJoinPage />} />

			{/* PUBLIC ORDER STATUS UPDATE ROUTE */}
			<Route path={ROUTES.ORDER_STATUS_UPDATE} element={<OrderStatusUpdatePage />} />
			{/* PUBLIC ORDER STATUS UPDATE BY REFERENCE ROUTE */}
			<Route
				path={ROUTES.ORDER_STATUS_UPDATE_BY_REFERENCE}
				element={<OrderStatusUpdateByReferencePage />}
			/>

			{/* PUBLIC TASK DELAYED ROUTE */}
			<Route path={ROUTES.TASK_DELAYED} element={<TaskDelayedPage />} />
			{/* PUBLIC TASK STATUS UPDATE ROUTE */}
			<Route path={ROUTES.TASK_STATUS_UPDATE} element={<TaskStatusUpdatePage />} />

			{/* VCHK routes */}
			<Route path={ROUTES.FLEET.FTRSTART} element={<FlatTyreCheckStartPage />} />
			<Route path={ROUTES.FLEET.FTR} element={<FlatTyreCheckPage />} />
			<Route path={ROUTES.FLEET.VCHKSTART} element={<VehicleCheckStartPage />} />
			<Route path={ROUTES.FLEET.VCHK} element={<VehicleCheckPage />} />

			{/* PUBLIC REGISTRATION ROUTE */}
			<Route path={ROUTES.SUCCESSFUL_REGISTRATION} element={<SuccessfulRegistration />} />

			{/* PUBLIC WORKSPACE ROUTES */}
			<Route path={ROUTES.WORKSPACES.NO_WORKSPACE} element={<NoWorkspace />} />
			<Route path={ROUTES.WORKSPACES.NO_CONVERSATION} element={<NoConversationGuest />} />
			<Route
				path={ROUTES.WORKSPACES.EXPIRED_INVITATION}
				element={<ExpiredInvitation />}
			/>
			<Route
				path={ROUTES.WORKSPACES.DISABLED_WORKSPACE}
				element={<DisabledWorkspace />}
			/>
			<Route path={ROUTES.WORKSPACES.INVITE} element={<JoinWorkspacePage />} />
			<Route path={ROUTES.CONVERSATIONS.INVITE} element={<JoinConversationPage />} />

			<Route path={ROUTES.DASHBOARD.REDIRECT} element={<DashboardRedirectPage />} />
			<Route path={ROUTES.SETTINGS.DPA_PUBLIC} element={<DpaPagePublic />} />

			<Route path="*" element={<NoMatch />} />
		</Route>,
	),
);
