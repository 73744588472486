import styled from 'styled-components';
import {
	DataGridPro,
	GridPagination,
	GridToolbarColumnsButton,
} from '@mui/x-data-grid-pro';

import { H1, T3, T3_medium, T4, T4_medium } from '../../styles/theme/typography-v2';
import { colors } from '../../styles';

export const StyledDataGridPro = styled(DataGridPro)`
	${T4};
	color: ${colors.grey[900]};
	border: 0;

	.MuiDataGrid-main {
		border: 1px ${colors.grey[300]} solid;
		border-radius: 4px 4px 0 0;
	}

	.MuiDataGrid-toolbarContainer {
		border: 1px ${colors.grey[300]} solid;
		border-radius: 0 0 4px 4px;
	}

	.MuiDataGrid-columnHeader {
		overflow: hidden;
		color: ${colors.primary.darker}
		font-feature-settings: 'liga' off, 'clig' off;
		text-overflow: ellipsis;

		/* Regular/T4 */
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px; /* 133.333% */
		letter-spacing: -0.1px;
	}
		.MuiCheckbox-root {
        color: #122CC5;
    }
`;

export const StyledGridPagination = styled(GridPagination)`
	${T3};
	color: ${colors.grey[900]};

	.MuiTablePagination-selectLabel {
		color: ${colors.grey[600]};
	}
`;

export const StyledGridToolbarColumnsButton = styled(GridToolbarColumnsButton)`
	${T3_medium}
`;

export const StyledTitle = styled.div`
	${H1};
	color: ${colors.grey[900]};
`;
