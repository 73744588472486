import React, { Dispatch, SetStateAction } from 'react';
import { Box, Grid, InputAdornment } from '@mui/material';
import { LinkedOrderDiv, StyledAvatar, StyledChooseOrder, StyledDecoration, StyledDialog, StyledDiv, StyledMessageAuthorText, StyledMessageGroup } from './conversations.styles';
import { useTranslation } from 'react-i18next';
import { getNameInitials, MessageBubble, MessageInputV2 } from '@heylog-app/frontend-lib/app';
import { staticMockedMessage } from 'libs/frontend-lib/app/src/components/forms/add-contact-form/static-mocked-message';
import { AddContacts } from './add-contacts';

interface Contact {
    id: string;
    name: string;
    email: string;
    avatarUrl?: string;
}
interface NewConversationFormProps {
    setSelectedOrderId: (id: string | null) => void;
    message: string;
    setMessage: Dispatch<SetStateAction<string>>;
    selectedContacts: Contact[];
    setSelectedContacts: (contacts: Contact[]) => void;
}

export const NewConversationForm: React.FC<NewConversationFormProps> = ({
    setSelectedOrderId,
    message,
    setMessage,
    selectedContacts,
    setSelectedContacts,
}) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ bgcolor: '#f4f6f8', mt: 2 }}>
            <Box sx={{ mb: 2, borderRadius: 1, mt: 4.5 }}>
                <Grid container rowSpacing={2}>
                    <AddContacts selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts} />
                    <StyledDecoration />
                    <Grid item xs={12} sx={{ p: 2 }}>
                        <LinkedOrderDiv>Linked order</LinkedOrderDiv>
                        <StyledChooseOrder
                            inputProps={{
                                variant: 'outlined',
                                hasLabel: false,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {t('email.form.linkToOrder')}:
                                    </InputAdornment>
                                ),
                            }}
                            width="100%"
                            handleAssign={({ orderId }) => setSelectedOrderId(orderId)}
                        />
                    </Grid>
                </Grid>
            </Box>

            <StyledDialog>
                <StyledDiv>Heylog Chat Preview</StyledDiv>
                <Grid container spacing={1}>
                    <Grid item>
                        <StyledAvatar sx={{ width: '80', height: '80', fontSize: '1rem' }} src="">
                            {getNameInitials('jahanzaib', 'ashraf')}
                        </StyledAvatar>
                    </Grid>
                    <Grid item xs={10}>
                        <StyledMessageGroup>
                            <StyledMessageAuthorText>Heylog</StyledMessageAuthorText>
                        </StyledMessageGroup>
                        <StyledMessageGroup>
                            <MessageBubble
                                message={{ ...staticMockedMessage, text: message, direction: 'FROM_CONTACT' }}
                            />
                        </StyledMessageGroup>
                    </Grid>
                </Grid>
            </StyledDialog>

            <MessageInputV2
                message={message}
                setMessage={setMessage}
            />
        </Box>
    );
};
