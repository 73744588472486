import { SidebarLayout } from '@heylog-app/frontend-lib/app';

import { StyledBackground } from './messages-page.styles';

import type { FC } from 'react';
import { MessagesListTable } from './components/messages-data';

export const MessagesPage: FC = () => {
	return (
		<SidebarLayout>
			<StyledBackground>
				<MessagesListTable />
			</StyledBackground>
		</SidebarLayout>
	);
};
