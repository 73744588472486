import { Alert, Snackbar } from "@mui/material";
import styled from "styled-components";

export const StyledSnackbar = styled(Snackbar)`
  top: 64px;
`;

export const StyledAlert = styled(Alert)`
  border: 2px solid rgb(76, 175, 80);

  &.MuiAlert-standardSuccess {
    border-color: ${({ theme }) => theme.palette.success.light};
  }

  &.MuiAlert-standardInfo {
    border-color: ${({ theme }) => theme.palette.info.light};
  }

  &.MuiAlert-standardWarning {
    border-color: ${({ theme }) => theme.palette.warning.light};
  }

  &.MuiAlert-standardError {
    border-color: ${({ theme }) => theme.palette.error.light};
  }
`;

export const StyledSnackbarV2 = styled(Snackbar)`
  top: 64px;
  display: flex;
  padding: 3px 3px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledAlertV2 = styled(Alert)`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  border-radius: 10px;

  &.MuiAlert-standardSuccess {
    background: #d9f9da;
    border-color: ${({ theme }) => theme.palette.success.light};
    color: var(--Success-Dark, #198a1f);
  }

  &.MuiAlert-standardInfo {
    border-color: ${({ theme }) => theme.palette.info.light};
  }

  &.MuiAlert-standardWarning {
    border-color: ${({ theme }) => theme.palette.warning.light};
  }

  &.MuiAlert-standardError {
    border-color: ${({ theme }) => theme.palette.error.light};
  }
`;
