import type { AcceptUserInviteEntryParams, CreateEntryParams, CreateEntryParamsArchiveAll } from '../types';
import type {
	ArchiveWorkspaceConcatInterface,
	ArchiveWorkspaceInvitationsReqInterface,
	ArchiveWorkspaceInvitationsResInterface,
	CreateWorkspaceInvitationReqInterface,
	WorkspaceInvitationResInterface,
} from '@heylog-app/shared/types';
import type { AxiosInstance, AxiosResponse } from 'axios';

export const inviteUserAPI = async (
	apiClient: AxiosInstance,
	{ data, workspaceId }: CreateEntryParams<CreateWorkspaceInvitationReqInterface>,
): Promise<AxiosResponse<WorkspaceInvitationResInterface>> => {
	return apiClient.post(`workspaces/${workspaceId}/invitations`, data);
};

export const acceptUserInvitationAPI = async (
	apiClient: AxiosInstance,
	{ workspaceId, invitationId }: AcceptUserInviteEntryParams,
): Promise<AxiosResponse<WorkspaceInvitationResInterface>> => {
	return apiClient.post(`workspaces/${workspaceId}/invitations/${invitationId}/accept`);
};

export const archiveUserInvitationAPI = async (
	apiClient: AxiosInstance,
	{ data, workspaceId }: CreateEntryParams<ArchiveWorkspaceInvitationsReqInterface>,
): Promise<AxiosResponse<ArchiveWorkspaceInvitationsResInterface>> => {
	return apiClient.post(`workspaces/${workspaceId}/invitations/archive`, data);
};

export const archiveConversationsAPI = async (
	apiClient: AxiosInstance,
	{ data, workspaceId }: CreateEntryParams<CreateEntryParamsArchiveAll>,
): Promise<AxiosResponse<ArchiveWorkspaceInvitationsResInterface>> => {
	return apiClient.post(`workspaces/${workspaceId}/conversations-v2/archive`, data);
};

export const archiveContactsAPI = async (
	apiClient: AxiosInstance,
	{ data, workspaceId }: CreateEntryParams<string[]>,
): Promise<AxiosResponse<ArchiveWorkspaceInvitationsResInterface>> => {
	return apiClient.post(`workspaces/${workspaceId}/contacts-v2/archive`, data);
};
export const archiveContactsAPI2 = async (
	apiClient: AxiosInstance,
	{ data, workspaceId }: CreateEntryParams<ArchiveWorkspaceConcatInterface>,
): Promise<AxiosResponse<ArchiveWorkspaceInvitationsResInterface>> => {
	return apiClient.post(`workspaces/${workspaceId}/contacts/archive`, data);
};