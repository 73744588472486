
// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import type { Environment } from '@heylog-app/frontend-lib/app';
export const environment: Environment = {
	production: true,
	API_URL: 'https://api-dev.heylog.com/api/v1',
	GPLACES_KEY: 'AIzaSyAL3_TN466rtgfM19YgU_0x3NmM1DokRew',
	GOOGLE_MAPS_STATIC_API_KEY: 'AIzaSyBjJk4GAejzBYe58f1hTJlfmAHCvxmNHaw',
	PUSHER_KEY: '8a174a03069ee98de1c8',
	PUSHER_CLUSTER: 'eu',
	PUSHER_AUTH_ENDPOINT: 'auth/pusher',
	PUSHER_BEAMS_AUTH_ENDPOINT: 'auth/pusher/beams',
	PUSHER_BEAMS_ID: 'ffa10390-d2ef-491f-9b32-17787aada9fd',
	APP_URL: 'https://app-dev.heylog.com',
	MUI_PRO:
		'eed24985bcaaa4c929fb165d0c89b337Tz0xMDY0MDYsRT0xNzY5MTI2Mzk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=',
};