import useSWR from 'swr';

import { useApiClientContext } from './use-api-client-context.hook';
import { getFetcher, getUserKey } from '../util';
import { useMatchMutate } from './use-match-mutate';

import type {
	UpdateSelfUserReqInterface,
	UserResInterface,
} from '@heylog-app/shared/types';

export const useUser = () => {
	const { apiClient } = useApiClientContext();

	const baseUserKey = getUserKey();
	const userKey = baseUserKey + '/me';

	const matchMutate = useMatchMutate();

	const { data, error, isLoading } = useSWR<UserResInterface>(
		userKey,
		getFetcher(apiClient),
		{
			revalidateIfStale: true,
		},
	);

	// TODO this should be handled somehow in protected route
	// if (error?.response.status === 401) {
	// 	navigate(ROUTES.LOGOUT);
	// }

	// if (data?.workspaces?.length === 0) {
	// 	console.log('useUser, no ws found');

	// 	navigate(ROUTES.WORKSPACES.NO_WORKSPACE);
	// }

	// if (data?.workspaces && data?.workspaces[0]?.status !== WorkspaceStatusEnum.ACTIVE) {
	// 	const workspaceId = data.workspaces[0]?.id;

	// 	navigate(generatePath(ROUTES.WORKSPACES.DISABLED_WORKSPACE, { workspaceId }));
	// }

	const updateUser = async (params?: UpdateSelfUserReqInterface) => {
		const result = await apiClient.patch(userKey, params);
		await matchMutate(new RegExp(`^${baseUserKey}.*$`));
		return result.data;
	};
	return {
		user: data,
		updateUser,
		isLoading,
		isError: error,
	};
};
