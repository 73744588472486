import { Box } from '@mui/material';
import styled from 'styled-components';

import { T2_medium, T3_medium, colors } from '../../../styles';

export const StyledBoxWrapper = styled(Box)`
	display: flex;
	width: 100%;
`;

export const StyledBoxTab = styled(Box) <{ $isActive: boolean; $padding?: string }>`
	transition: 0.3s all ease;
	background-color: ${({ $isActive }) =>
		$isActive ? colors.primary.lighter : colors.grey[200]};
	color: ${({ $isActive }) => ($isActive ? colors.primary.main : colors.primary.main)};
	padding: ${({ $padding }) => ($padding ? $padding : '9px 24px')};
	${T2_medium};
	cursor: pointer;
	border: ${({ $isActive }) => ($isActive ? '1px solid #122CC5' : 'none')};
	border-radius: 4px;
	align-items: center;
	display: flex;
	flex: 1;
	justify-content: center;
	z-index: ${({ $isActive }) => ($isActive ? 2 : 1)}; /* Active tab appears on top */

	&:not(:first-of-type) {
		margin-left: -4px;
	}
`;
