import { getLiveLocationDataKey, getOrderKey } from './get-order-key';
import { getTasksKey } from './get-tasks-key';
import { getTaskKey } from './get-task-key';

import type { AxiosInstance, AxiosResponse } from 'axios';
import type {
	CreateOrderReqInterface,
	CreateOrderTaskReqInterface,
	I18nExceptionResponseInterface,
	LiveLocationDataPointResInterface,
	OrderResInterface,
	OrderStatusAndNotesResInterface,
	OrderTaskResInterface,
	OrderStatusUpdateReqInterface,
	OrderTaskStatusResInterface,
	OrderTaskStatusUpdateReqInterface,
	UpdateOrderReqInterface,
} from '@heylog-app/shared/types';
import type {
	CreateEntryParams,
	CreateTaskParams,
	DeleteTaskParams,
	SetOrderOnTimeParams,
	TogglePriorityOrderParams,
	UpdateEntryParams,
	UpdateOrderParams,
	UpdateOrderTaskParams,
} from '../types';

export const createOrderAPI = async (
	apiClient: AxiosInstance,
	{ data, workspaceId }: CreateEntryParams<CreateOrderReqInterface>,
) => {
	const key = getOrderKey(workspaceId);
	return apiClient.post(key, data);
};

export const getOrderTask = async (
	apiClient: AxiosInstance,
	{
		orderId,
		taskId,
		workspaceId,
	}: { orderId: string; workspaceId: string; contactToken?: string; taskId: string },
): Promise<AxiosResponse<OrderTaskResInterface, I18nExceptionResponseInterface>> => {
	return apiClient.get(`workspaces/${workspaceId}/orders/${orderId}/tasks/${taskId}`);
};
	

export const updateOrderAPI = async (
	apiClient: AxiosInstance,
	{ id, data, workspaceId }: UpdateEntryParams<UpdateOrderReqInterface>,
): Promise<AxiosResponse<OrderResInterface, I18nExceptionResponseInterface>> => {
	const key = getOrderKey(workspaceId);
	return apiClient.patch(`${key}/${id}`, data);
};

export const getOrderStatus = async (
	apiClient: AxiosInstance,
	{
		orderId,
		workspaceId,
	}: { orderId: string; workspaceId: string; contactToken?: string },
): Promise<
	AxiosResponse<OrderStatusAndNotesResInterface, I18nExceptionResponseInterface>
> => {
	return apiClient.get(`workspaces/${workspaceId}/orders/${orderId}/status-notes`);
};

export const getOrder = async (
	apiClient: AxiosInstance,
	{
		orderId,
		workspaceId,
	}: { orderId: string; workspaceId: string; contactToken?: string },
): Promise<AxiosResponse<OrderResInterface, I18nExceptionResponseInterface>> => {
	return apiClient.get(`workspaces/${workspaceId}/orders/${orderId}`);
};

export const createOrderTask = async (
	apiClient: AxiosInstance,
	{ data, workspaceId, orderId }: CreateTaskParams<CreateOrderTaskReqInterface>,
): Promise<
	AxiosResponse<OrderTaskStatusResInterface, I18nExceptionResponseInterface>
> => {
	const key = getTasksKey({
		workspaceId,
		orderId,
	});
	return apiClient.post(key, data);
};

export const deleteOrderTask = async (
	apiClient: AxiosInstance,
	{ workspaceId, orderId, taskId }: DeleteTaskParams,
): Promise<void> => {
	const key = getTaskKey({
		orderId,
		taskId,
		workspaceId,
	});
	return apiClient.delete(key);
};

export const getOrderTaskStatus = async (
	apiClient: AxiosInstance,
	{
		orderId,
		taskId,
		workspaceId,
	}: { orderId: string; workspaceId: string; contactToken?: string; taskId: string },
): Promise<
	AxiosResponse<OrderTaskStatusResInterface, I18nExceptionResponseInterface>
> => {
	return apiClient.get(
		`workspaces/${workspaceId}/orders/${orderId}/tasks/${taskId}/status`,
	);
};

export const updateOrderStatus = async (
	apiClient: AxiosInstance,
	{ id, data, workspaceId }: UpdateOrderParams<OrderStatusUpdateReqInterface>,
): Promise<
	AxiosResponse<OrderStatusAndNotesResInterface, I18nExceptionResponseInterface>
> => {
	const url = `workspaces/${workspaceId}/orders/${id}/status-update`;

	return apiClient.patch(url, data);
};

export const updateOrderTaskStatus = async (
	apiClient: AxiosInstance,
	{
		data,
		orderId,
		taskId,
		workspaceId,
	}: UpdateOrderTaskParams<OrderTaskStatusUpdateReqInterface>,
): Promise<
	AxiosResponse<OrderTaskStatusResInterface, I18nExceptionResponseInterface>
> => {
	const url = `workspaces/${workspaceId}/orders/${orderId}/tasks/${taskId}/status-update`;

	return apiClient.patch(url, data);
};

export const toggleUrgentOrderAPI = async (
	apiClient: AxiosInstance,
	{ id, workspaceId }: TogglePriorityOrderParams,
): Promise<AxiosResponse<OrderResInterface, I18nExceptionResponseInterface>> => {
	const url = `workspaces/${workspaceId}/orders/${id}/toggle-priority`;

	return apiClient.patch(url);
};

export const toggleAttentionOrderAPI = async (
	apiClient: AxiosInstance,
	{ id, workspaceId }: TogglePriorityOrderParams,
): Promise<AxiosResponse<OrderResInterface, I18nExceptionResponseInterface>> => {
	const url = `workspaces/${workspaceId}/orders/${id}/toggle-attention`;

	return apiClient.patch(url);
};

export const setOrderOnTime = async (
	apiClient: AxiosInstance,
	{ onTime, orderId, taskId, workspaceId }: SetOrderOnTimeParams,
): Promise<AxiosResponse<OrderResInterface, I18nExceptionResponseInterface>> => {
	const stringOnTime = onTime ? 'true' : 'false';
	const url = `/workspaces/${workspaceId}/orders/${orderId}/tasks/${taskId}/set-on-time/${stringOnTime}`;

	return apiClient.post(url);
};

export const getLiveLocationDataAPI = async (
	apiClient: AxiosInstance,
	{ orderId, workspaceId }: { orderId: string; workspaceId: string },
): Promise<
	AxiosResponse<LiveLocationDataPointResInterface[], I18nExceptionResponseInterface>
> => {
	const url = getLiveLocationDataKey(workspaceId, orderId);
	return apiClient.get(url);
};
