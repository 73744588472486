import React, { useEffect, useRef, useState } from "react";
import { StyledBoxDiv, StyledBoxDivInside, StyledButtonBoxInside, StyledFormControlLabel2, StyledHeadLabel, StyledHeadTitle, StyledLeftBox, StyledMain, StyledMainInner, StyledRightBox, StyledRightText, StyledTextBox, StyledTextFooter } from "./dpa-page.styles";
import { Radio, RadioGroup, Stack } from "@mui/material";

import { ButtonV2, createPrivacyPolicy, FlashSnackbar, IconSendFile, useApiClientContext, useSnackbar, useUser, useWorkspace, useWorkspaceById } from "@heylog-app/frontend-lib/app";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
type DPAPageProps = {
    filee?: File | undefined;
    setFile?: React.Dispatch<React.SetStateAction<File | undefined>>;
    selectedValue?: string;
    setSelectedValue?: React.Dispatch<React.SetStateAction<string>>;
}
export const DpaPage = ({ filee, setFile, selectedValue, setSelectedValue }: DPAPageProps) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { workspaceId } = useParams();
    const { user } = useUser();
    const { t } = useTranslation()
    const { workspace: dataSettings } = useWorkspaceById(workspaceId || '-1');

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

    useEffect(() => {
        setSelectedValue && setSelectedValue(dataSettings?.heylogPolicy ? "rightValue" : "leftValue")
    }, [dataSettings])
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault(); // Prevent default browser behavior
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault(); // Prevent default browser behavior
        const files = event.dataTransfer.files as any;
        if (files && files.length > 0) {
            setFile && setFile(files?.[0]); // Use the first file for simplicity
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files as any;
        if (files && files.length > 0) {
            setFile && setFile(files?.[0]); // Use the first file for simplicity
        }
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue && setSelectedValue(event.target.value); // Update the selected value
    };

    return (
        <>

            <StyledMain>
                <Stack marginBottom={"10px"}>
                    <Stack gap={'10px'} >
                        <StyledHeadTitle>{t("settings.dpa-page.title")}</StyledHeadTitle>
                        <StyledHeadLabel>{t("settings.dpa-page.subHeading")}</StyledHeadLabel>
                    </Stack>

                </Stack>
                <StyledMainInner>
                    <StyledLeftBox>
                        <Stack gap={"15px"}>

                            <RadioGroup
                                name="dpa-options"
                                value={selectedValue}
                                onChange={handleRadioChange}
                            >
                                <StyledFormControlLabel2
                                    value={'leftValue'}
                                    control={<Radio />}
                                    label={t("settings.dpa-page.uploadDpa")}

                                />
                            </RadioGroup>
                            <StyledBoxDiv
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                            >
                                <StyledBoxDivInside>
                                    {/* <Icon path={mdiFileDocumentPlusOutline} size={2} /> */}
                                    <IconSendFile />
                                    <StyledTextBox>{t("settings.dpa-page.dropFile")}</StyledTextBox>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={(e) => {
                                            const file = e.target.files?.[0];
                                            if (!file) return;

                                            const allowedExtensions = ['pdf', 'doc', 'docx'];
                                            const fileExtension = file.name.split('.').pop()?.toLowerCase();
                                            const maxFileSize = 50 * 1024 * 1024; // 50MB in bytes

                                            // Validate extension
                                            if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
                                                openSnackbar("warning", t("settings.dpa-page.errorType"));
                                                e.target.value = ""; // reset input
                                                return;
                                            }

                                            // Validate size
                                            if (file.size > maxFileSize) {
                                                openSnackbar("warning", t("settings.dpa-page.errorSize"));
                                                e.target.value = ""; // reset input
                                                return;
                                            }

                                            handleFileChange(e);
                                        }}
                                    />
                                    <StyledButtonBoxInside onClick={handleButtonClick}>
                                        {t("settings.dpa-page.uploadFile")}
                                    </StyledButtonBoxInside>
                                    {filee && (
                                        <StyledTextFooter>
                                            {t("settings.dpa-page.selectedFile")} <strong>{filee?.name}</strong>
                                        </StyledTextFooter>
                                    )}

                                    <StyledTextFooter>{t("settings.dpa-page.supportType")}</StyledTextFooter>
                                </StyledBoxDivInside>
                            </StyledBoxDiv>

                        </Stack>
                    </StyledLeftBox>

                    <StyledRightBox>
                        <Stack gap={"15px"}>

                            <RadioGroup
                                name="dpa-options"
                                value={selectedValue}
                                onChange={handleRadioChange}
                            >
                                <StyledFormControlLabel2
                                    value={'rightValue'}
                                    control={<Radio />}
                                    label={t("settings.dpa-page.uploadHeylog")}

                                />
                            </RadioGroup>
                            <StyledRightText>
                                {t("settings.dpa-page.viewHeylog")} <a target="_blank" href={"https://www.heylog.com/privacy-policy"}
                                    style={{ color: "#122CC5" }}>
                                    here.
                                </a>
                            </StyledRightText>

                        </Stack>
                    </StyledRightBox>
                    <FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
                </StyledMainInner>
            </StyledMain>
        </>)
}