import { generatePath, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	acceptGuestInvitationAPI,
	acceptUserInvitationAPI,
	HeylogLogoBlack,
	NoWorkspaceSvg,
	ROUTES,
	useApiClientContext,
	useAuthContext,
	useEnvContext,
	useLocalStorage,
	useRelevantWorkspace,
	useUser,
} from '@heylog-app/frontend-lib/app';
import { UserRoleEnum } from '@heylog-app/shared/types';

import {
	StyledButton,
	StyledPageContainer,
	StyledPageContent,
} from '../inactive-workspaces/no-workspace.styles';
import { StyledSubtitle } from '../../pages/join-workspace/join-workspace.styles';

import type { AxiosResponse } from 'axios';
import type { WorkspaceInvitationResInterface } from '@heylog-app/shared/types';
import type { FC } from 'react';

export const DashboardRedirectPage: FC = () => {
	const { t } = useTranslation();
	const { apiClient } = useApiClientContext();
	const { user, updateUser } = useUser();
	const { relevantWorkspace } = useRelevantWorkspace(user);

	const [guestInvitationAccepted, setGuestInvitationAccepted] = useState(false);
	const [userInvitationAccepted, setUserInvitationAccepted] = useState(false);

	const [canJoinWorkspace, setCanJoinWorkspace] = useState(true);

	const navigate = useNavigate();
	const { decoded, token } = useAuthContext();
	const { production } = useEnvContext();

	const dashboardLogger = useCallback(
		(...args: unknown[]) => !production && console.log('[Dashboard]:', ...args),
		[production],
	);

	if (!decoded || !token) {
		dashboardLogger(`Rerouting User to LOGOUT, ${token} ${decoded}`);

		navigate(generatePath(ROUTES.LOGOUT));
	}

	const [workspaceUserInvite, , removeWorkspaceUserInvite] = useLocalStorage(
		'workspaceUserInvite',
		{
			workspaceId: '',
			invitationId: '',
		},
	);

	const [conversationGuestInvite, , removeConversationGuestInvite] = useLocalStorage(
		'conversationGuestInvite',
		{
			workspaceId: '',
			conversationId: '',
			invitationId: '',
		},
	);

	const handleAcceptInvitation = useCallback(
		async (workspaceId: string, invitationId: string) => {
			try {
				await acceptUserInvitationAPI(apiClient, { workspaceId, invitationId })
					.then((res: AxiosResponse<WorkspaceInvitationResInterface>) => {
						updateUser().then(() => {
							dashboardLogger('User invitation accepted successfully, data: ', res);
							dashboardLogger('Navigating to workspace: ', res.data.workspaceId);

							navigate(generatePath(ROUTES.HOME, { workspaceId: res.data.workspaceId }));
						});
					})
					.finally(() => {
						removeWorkspaceUserInvite();
						setUserInvitationAccepted(true);
					});
			} catch (e) {
				console.log(e);
				navigate(ROUTES.WORKSPACES.EXPIRED_INVITATION);
			}
		},
		[apiClient, dashboardLogger, navigate, updateUser, removeWorkspaceUserInvite],
	);

	const handleAcceptGuestInvitation = useCallback(
		async (workspaceId: string, conversationId: string, invitationId: string) => {
			await acceptGuestInvitationAPI(apiClient, {
				workspaceId,
				conversationId,
				invitationId,
			})
				.then(async () => {
					setGuestInvitationAccepted(true);
					removeConversationGuestInvite();
					await updateUser().then(() => {
						navigate(generatePath(ROUTES.HOME_V1, { workspaceId }));
					});
				})
				.catch((e) => {
					console.log(e);
					navigate(ROUTES.WORKSPACES.EXPIRED_INVITATION);
				});
		},
		[apiClient, updateUser, navigate, removeConversationGuestInvite],
	);

	// if there is no invite, no need to handle it, navigate to Home
	useEffect(() => {
		if (
			relevantWorkspace &&
			!conversationGuestInvite.invitationId &&
			!workspaceUserInvite.invitationId
		) {
			dashboardLogger('navigating to HOME with workspace id:', relevantWorkspace);
			navigate(generatePath(ROUTES.HOME, { workspaceId: relevantWorkspace }));
		} else if (
			!relevantWorkspace &&
			!conversationGuestInvite.invitationId &&
			!workspaceUserInvite.invitationId
		) {
			dashboardLogger('navigating to base path');
			navigate('/');
		}
	}, [
		conversationGuestInvite.invitationId,
		dashboardLogger,
		navigate,
		relevantWorkspace,
		workspaceUserInvite.invitationId,
	]);

	// We catch the invitations with the wrong role here
	useEffect(() => {
		// see if workspace user tries to accept an invitation
		if (user && user.role !== UserRoleEnum.CONVERSATION_GUEST) {
			if (
				conversationGuestInvite.workspaceId &&
				conversationGuestInvite.conversationId &&
				conversationGuestInvite.invitationId &&
				!guestInvitationAccepted
			) {
				dashboardLogger('Calling accepting guest invitation');

				handleAcceptGuestInvitation(
					conversationGuestInvite.workspaceId,
					conversationGuestInvite.conversationId,
					conversationGuestInvite.invitationId,
				);
			}

			if (
				workspaceUserInvite.invitationId &&
				workspaceUserInvite.workspaceId &&
				!userInvitationAccepted
			) {
				dashboardLogger('Calling accepting user invitation');

				handleAcceptInvitation(
					workspaceUserInvite.workspaceId,
					workspaceUserInvite.invitationId,
				);
			}
		}
		// See if a guest tries to join a workspace
		else if (user && user.role === UserRoleEnum.CONVERSATION_GUEST) {
			if (workspaceUserInvite.invitationId) {
				setCanJoinWorkspace(false);
				removeWorkspaceUserInvite();
				return;
			}

			if (
				conversationGuestInvite.workspaceId &&
				conversationGuestInvite.conversationId &&
				conversationGuestInvite.invitationId &&
				!guestInvitationAccepted
			) {
				dashboardLogger('Calling accepting guest invitation');

				handleAcceptGuestInvitation(
					conversationGuestInvite.workspaceId,
					conversationGuestInvite.conversationId,
					conversationGuestInvite.invitationId,
				);
			}
		}
	}, [
		conversationGuestInvite,
		dashboardLogger,
		guestInvitationAccepted,
		handleAcceptGuestInvitation,
		handleAcceptInvitation,
		navigate,
		user,
		userInvitationAccepted,
		workspaceUserInvite,
		removeWorkspaceUserInvite,
	]);

	const handleHomeClick = () => {
		dashboardLogger('Home clicked, navigating to: ', relevantWorkspace);
		navigate(generatePath(ROUTES.HOME, { workspaceId: relevantWorkspace }));
	};

	return (
		<StyledPageContainer data-test="dashboard-page">
			<HeylogLogoBlack />

			<StyledPageContent>
				<div>
					<NoWorkspaceSvg />
				</div>

				{!canJoinWorkspace && (
					<StyledSubtitle
						data-test="guest-invalid-role-text"
						dangerouslySetInnerHTML={{
							__html: t('signup.guest-invitation.invalid-role'),
						}}
					/>
				)}

				<StyledButton
					variant="outlined"
					color="secondary"
					onClick={handleHomeClick}
					data-test="logout-button"
				>
					{t('actionLabels.home')}
				</StyledButton>
			</StyledPageContent>
		</StyledPageContainer>
	);
};
