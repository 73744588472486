import { createContext, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EmailDialog } from '../components/email-dialog/email-dialog';
import { MessageDialogTypesEnum } from './message-provider.types';
import { FlashSnackbar } from '../components';
import { useSnackbar } from '../hooks';
import { ConversationDialog } from '../components/conversation-dialog/conversation-dialog';

import type { MessageDialogInterface } from './message-provider.types';
import type { FC, PropsWithChildren } from 'react';
import { ConversationDialogHeylog } from '../components/conversation-dialog-heylog/conversation-dialog-heylog';

export const MessageContext = createContext<{
	onOpenMessageDialog?: (data: MessageDialogInterface) => void;
}>({});

export const MessagesProvider: FC<PropsWithChildren> = ({ children }) => {
	const [messageDialogs, setMessageDialogs] = useState<MessageDialogInterface[]>([]);
	const [clickedDialog, setClickedDialog] = useState('');
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const { shipmentId } = useParams();

	const isShipmentPage = !!shipmentId;

	const onOpenMessageDialog = useCallback(
		(data: MessageDialogInterface) => {
			if (messageDialogs.length === 1)
				return openSnackbar('error', 'Maximum 1 dialog at one time allowed');

			const id = crypto.randomUUID();
			const newDialog = {
				...data,
				id,
			};

			setMessageDialogs((prev) => [...prev, newDialog]);
			setClickedDialog(id);
		},
		[messageDialogs, openSnackbar],
	);

	const onClose = useCallback((id: string) => {
		setMessageDialogs((prev) => prev.filter(({ id: dialogId }) => dialogId !== id));
	}, []);

	return (
		<MessageContext.Provider value={{ onOpenMessageDialog }}>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			{messageDialogs.map((dialog, idx) => {
				const isFocusedDialog = clickedDialog === dialog.id;
				const focusedDialogZIndex = 99;
				const notFocusedDialogZIndex = 98;
				const zIndex = isFocusedDialog ? focusedDialogZIndex : notFocusedDialogZIndex;

				switch (dialog.type) {
					case MessageDialogTypesEnum.EMAIL:
						return (
							<EmailDialog
								key={dialog.id}
								id={dialog.id || ''}
								emailData={dialog.payload}
								emailAction={dialog.action}
								isShipmentPage={isShipmentPage}
								onClose={onClose}
								onClick={setClickedDialog}
								zIndex={zIndex}
								isFocused={isFocusedDialog}
								idx={idx}
							/>
						);
					case MessageDialogTypesEnum.CHAT_APP:
						return (
							<ConversationDialog
								key={dialog.id}
								id={dialog.id || ''}
								payload={dialog.payload}
								isShipmentPage={isShipmentPage}
								onClose={onClose}
								onClick={setClickedDialog}
								zIndex={zIndex}
								isFocused={isFocusedDialog}
								idx={idx}
							/>
						);
					case MessageDialogTypesEnum.HEYLOG_APP:
						return (
							<ConversationDialogHeylog
								key={dialog.id}
								id={dialog.id || ''}
								payload={dialog.payload}
								isShipmentPage={isShipmentPage}
								onClose={onClose}
								onClick={setClickedDialog}
								zIndex={zIndex}
								isFocused={isFocusedDialog}
								idx={idx}
							/>
						);
					default:
						return null;
				}
			})}

			{children}
		</MessageContext.Provider>
	);
};
