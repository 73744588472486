import { useCallback, useState } from 'react';

import type { UseDialogReturnType } from '../types';

export const useDialog = (): UseDialogReturnType => {
	const [showDialog, setShowDialog] = useState(false);

	const openDialog = useCallback(() => {
		setShowDialog(true);
	}, [setShowDialog]);

	const closeDialog = useCallback(() => {
		setShowDialog(false);
	}, [setShowDialog]);

	return { showDialog, openDialog, closeDialog };
};

export const useDialogV2 = (): UseDialogReturnType => {
	const [showDialog, setShowDialog] = useState(false);

	const openDialog = useCallback(() => {
		setShowDialog(true);
	}, [setShowDialog]);

	const closeDialog = useCallback(() => {
		setShowDialog(false);
	}, [setShowDialog]);

	return { showDialog, openDialog, closeDialog };
};
