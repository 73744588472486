import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StyledSidebarSearchWrapper, StyledTextField } from './sidebar-search.styles';

import type { SetStateAction, ChangeEventHandler, FC } from 'react';

type SidebarSearchProps = {
	onSearch: ChangeEventHandler<HTMLInputElement>;
	setIsSearching: React.Dispatch<SetStateAction<boolean>>;
	isStyled?: boolean;
	label?: string;
};

export const SidebarSearch: FC<SidebarSearchProps> = ({
	onSearch,
	setIsSearching,
	isStyled = false,
	label = "",
}) => {
	const { t } = useTranslation();

	return (
		<StyledSidebarSearchWrapper>
			{isStyled ? (
				<StyledTextField
					data-test="search-contacts-field"
					type={'text'}
					label={label || t('actionLabels.search')}
					onChange={onSearch}
					onBlur={() => setIsSearching(false)}
					fullWidth
				/>
			) : (
				<TextField
					data-test="search-contacts-field"
					type={'text'}
					label={label || t('actionLabels.search')}
					onChange={onSearch}
					onBlur={() => setIsSearching(false)}
					fullWidth
				/>
			)}
		</StyledSidebarSearchWrapper>
	);
};
